import React, { useEffect, useState } from "react";
import { IoCut } from "react-icons/io5";
import { FiMoreVertical } from "react-icons/fi";
import { Button, Card } from "react-bootstrap";
import thumbnail from "../../../multimedia/thumbnail1200.png";
import ReactTimeAgo from "react-time-ago";
import { MdOutlineEdit } from "react-icons/md";
import { GoDownload } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga";
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

export default function VoiceTransferHome({
  searchText,
  setSelectedVoiceTransferComponent,
  projectId,
  setProjectId,
  setLanguageId,
  setVideoUrl,
  setProjectName,
  setVoicesArray,
  setVoice1,
  setMenuLock,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [myTransferProjects, setMyTransferProjects] = useState([]);
  const [tempTransferProjects, setTempTransferProjects] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const projectsApiUrl = `${apiURL}/web/get_project_list`;
  const [reName, setReName] = useState("");
  const [pid, setPid] = useState("");
  const [projectUserId, setProjectUserId] = useState("");
  const [projectIdFromUrl, setProjectIdFromUrl] = useState("");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [disableDownload, setDisableDownload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  const openModal = (project_id) => {
    setPid(project_id);
    setShowModal(true);
    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "projects/delete_project",
    });
  };

  useEffect(() => {
    setMenuLock(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => setShowModal(false);

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/spectral-transcend/projects");
  }, []);

  async function projectReName(project_id, SourceLangId, videoSrc) {
    const req = {
      ProjectId: project_id,
      Name: reName,
      SourceLangId: SourceLangId,
      VideoUrl: videoSrc,
    };

    try {
      const response = await fetch(`${apiURL}/web/update_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        setReName("");
        setMyTransferProjects([]);
        fetchAllProjects();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleProjectReName() {
    const req = {
      ProjectId: pid,
    };

    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        projectReName(data.Id, data.SourceLangId, data.VideoUrl);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const toggleOptions = (id) => {
    getProjectForDownload(id);
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (project_id) => {
    setPid(project_id);
    setShow(true);
    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "projects/rename_project",
    });
  };
  async function getProjectForDownload(project_id) {
    const req = {
      ProjectId: project_id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        setReName(data.Name);
        if (
          data.ResultVideos[0].Status === 11 ||
          data.ResultVideos[0].Status === 12 ||
          data.ResultVideos[0].Status === 13
        ) {
          if (data.ResultVideos[0].DubbedVideoUrl) {
            setDisableDownload(false);
            setDownloadUrl(data.ResultVideos[0].DubbedVideoUrl);
          } else if (data.ResultVideos[0].Status === 14) {
            setDownloadUrl(data.ResultVideos[0].SpectralSyncedVideoUr);
          }
        } else {
          setDisableDownload(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDeleteProject() {
    const req = {
      ProjectId: pid,
    };

    try {
      const response = await fetch(`${apiURL}/web/delete_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        setPid("");
        setMyTransferProjects([]);
        fetchAllProjects();
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  }

  // voice api call

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // console.log("voice list is",data.VoiceList[0]);
          localStorage.setItem("voiceList", JSON.stringify(data.VoiceList));
          setVoice1(data.VoiceList[0]);
          setVoicesArray(data.VoiceList);
        }
      } else {
        // setFlag(false);
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAllProjects = async (pageNo = 1) => {
    const requestBody = {
      UserId: localStorage.getItem("userSessionId"),
      Type: 4,
      PageNo: pageNo,
      PageSize: 10,
    };
    try {
      const response = await fetch(projectsApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      if (data.IsValid) {
        setMyTransferProjects((prevProjects) => [
          ...prevProjects,
          ...data.ProjectList,
        ]);
        setTempTransferProjects(data.ProjectList);
        setPageNumber(pageNo);
        setProjectUserId(data.ProjectList[0].UserId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllProjects(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowMore = () => {
    fetchAllProjects(pageNumber + 1);
  };

  useEffect(() => {
    console.log(projectId);
    setProjectIdFromUrl(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (projectIdFromUrl) {
      getProject(projectIdFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUserId]);

  async function getProject(id) {
    const req = {
      ProjectId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        if (data.UserId === projectUserId) {
          //  conditions to check project status
          if (data.Status === 1 || data.Status === 0) {
            // conditions to check result video status
            if (
              data.ResultVideos[0].Status === 201 ||
              data.ResultVideos[0].Status === 202 ||
              data.ResultVideos[0].Status === 203 ||
              data.ResultVideos[0].Status === 204
            ) {
              // proceed to speakers selection page

              setProjectId(data.Id);
              fetchVoice(data.Id);
              setProjectName(data.Name);
              setLanguageId(data.ResultVideos[0].TargetLangId);
              setVideoUrl(data.VideoUrl);
              setSelectedVoiceTransferComponent("audio_segments");
              navigate(
                `/voice-transfer/${data.Id.toLowerCase()}/audio-segments`
              );

              // setSelectedVoiceTransferComponent("speaker");
            } else if (
              data.ResultVideos[0].Status === 205 ||
              data.ResultVideos[0].Status === 2040
            ) {
              // proceed to translation processing page
              setProjectId(data.Id);
              setProjectName(data.Name);
              setLanguageId(data.ResultVideos[0].TargetLangId);
              setSelectedVoiceTransferComponent("download");
              navigate(`/voice-transfer/${data.Id.toLowerCase()}/download`);

              setVideoUrl(data.VideoUrl);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const filteredProjects = myTransferProjects.filter((project) =>
    project.Name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div onMouseDown={(e) => setOpenMenuId(null)}>
      <div className="user-btns">
        <button
          onClick={() => {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (
              userInfo &&
              userInfo.LimitCredits > 0 &&
              userInfo.LimitCredits > userInfo.UsageCredits
            ) {
              setSelectedVoiceTransferComponent("create");
              navigate("/voice-transfer/create");
            } else {
              if (userInfo.LimitCredits <= 0) {
                notify(
                  "Subscription has expired. Renew your subscription to keep using Spectral."
                );
              } else if (userInfo.LimitCredits <= userInfo.UsageCredits) {
                notify("Insufficient credits available for project creation!");
              }
            }
          }}
          className="create-btn"
        >
          <span className="create-icon">
            <IoCut />
          </span>
          Create Project
        </button>
      </div>
      <div
        className="mt-5 ms-5 mb-3"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <span style={{ fontWeight: 500, fontSize: "18px" }}>
          Recent Projects
        </span>
      </div>
      {filteredProjects.length === 0 && searchText !== "" ? (
        <p className="no-projects-txt">
          No projects found with the given search text.
        </p>
      ) : null}
      <div className="transcend-cards-container">
        {filteredProjects
          // .filter((project) => project.Status !== 4)
          .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
          // .slice(0, displayedCards)
          .map((projects, index) => {
            // Parse the timestamp string into a Date object
            const createdAtDate = new Date(projects.CreatedAt + "Z");
            return (
              <Card
                className="tanscendProjectsCard mt-4 ms-5 "
                key={projects.Id}
                style={{
                  border: "none",
                  backgroundColor: "#f7f7f9",
                }}
                onMouseEnter={(e) => {
                  const editButton =
                    e.currentTarget.querySelector(".edit-button");
                  editButton.style.opacity = 1;
                }}
                onMouseLeave={(e) => {
                  const editButton =
                    e.currentTarget.querySelector(".edit-button");
                  editButton.style.opacity = 0;
                }}
              >
                <div
                  style={{
                    position: "relative",
                    flex: "1",
                  }}
                >
                  {projects.ThumbnailUrl !== null ? (
                    <Card.Img
                      className="transcendCardThumbnail"
                      src={projects.ThumbnailUrl}
                      alt={projects.Name}
                    />
                  ) : (
                    <Card.Img
                      className="transcendCardThumbnail"
                      src={thumbnail}
                      alt={projects.Name}
                    />
                  )}

                  <div
                    className="edit-button"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      opacity: 0,
                      transition: "opacity 0.3s",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "8px 16px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => getProject(projects.Id)}
                  >
                    Edit
                  </div>
                  <div
                    className="status-label"
                    style={{ position: "absolute", bottom: 15, left: 10 }}
                  >
                    <span>
                      {projects.ResultVideos[0].Status === 201
                        ? "Creating segments"
                        : projects.ResultVideos[0].Status === 202
                        ? "Segments created"
                        : projects.ResultVideos[0].Status === 204
                        ? "VC processed"
                        : projects.ResultVideos[0].Status === 203
                        ? "VC processing"
                        : projects.ResultVideos[0].Status === 205
                        ? "VC result ready"
                        : projects.ResultVideos[0].Status === 2040
                        ? "AudioSegments Merging"
                        : projects.ResultVideos[0].Status === 0
                        ? "Files Uploaded"
                        : null}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "black",
                    fontWeight: 500,
                    marginTop: "8px",
                  }}
                >
                  <div>
                    <span
                      title={projects.Name.length > 30 ? projects.Name : null}
                    >
                      {" "}
                      {projects.Name.length > 30
                        ? projects.Name.slice(0, 30) + "..."
                        : projects.Name}
                    </span>
                    <br />
                    <span style={{ color: "grey", fontWeight: 400 }}>
                      Created:{" "}
                      <ReactTimeAgo date={createdAtDate} timeStyle="round" />
                    </span>
                  </div>
                  {/* css for options button in usepage.css */}
                  <Button
                    variant="text"
                    style={{
                      fontSize: "25px",
                      padding: 0,
                      height: "26px",
                      border: "none",
                      outline: "none",
                      position: "relative",
                    }}
                    className="optionsButton"
                    onClick={(e) => toggleOptions(projects.Id)}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <FiMoreVertical />
                    {openMenuId === projects.Id && (
                      <div className="options-menu">
                        <button
                          style={{ fontWeight: 500 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShow(projects.Id);
                          }}
                        >
                          <span className="me-1">
                            <MdOutlineEdit />
                          </span>
                          Rename
                        </button>
                        {disableDownload === false ? (
                          <button
                            style={{ fontWeight: 500 }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <a
                              style={{ textDecoration: "none", color: "black" }}
                              href={downloadUrl}
                            >
                              {" "}
                              <span className="me-1">
                                <GoDownload />
                              </span>
                              Download video
                            </a>
                          </button>
                        ) : (
                          <button
                            style={{
                              color: "grey",
                              fontWeight: 500,
                              cursor: "not-allowed",
                            }}
                            disabled
                          >
                            <span className="me-1">
                              <GoDownload />
                            </span>
                            Download video
                          </button>
                        )}
                        <button
                          style={{ fontWeight: 500 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            openModal(projects.Id);
                          }}
                        >
                          <span className="me-1">
                            <RiDeleteBin6Line />
                          </span>
                          Delete
                        </button>
                      </div>
                    )}
                  </Button>
                </div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Rename project</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Form.Label column sm="2">
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Project name"
                      defaultValue={reName}
                      onBlur={(e) => {
                        setReName(e.target.value);
                      }}
                    />
                    <div className="mt-4 mb-2">
                      <Row>
                        <Col>
                          <Button
                            variant="secondary"
                            block
                            onClick={handleClose}
                            style={{ width: "100%" }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            block
                            onClick={(e) => {
                              handleProjectReName();
                            }}
                            style={{ width: "100%" }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* Modal for delete project warning */}

                <Modal show={showModal} onHide={closeModal} size="md">
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm delete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to delete the project
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="warning"
                      onClick={(e) => handleDeleteProject()}
                    >
                      Confirm
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Card>
            );
          })}
        {myTransferProjects.length === 0 ? (
          <div className="transcend-welcome">
            <p>Welcome to Spectral. Let's create a project.</p>
          </div>
        ) : null}
      </div>
      <div
        className="mb-5"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {myTransferProjects.length > 0 &&
          tempTransferProjects.length === 10 && (
            <Button
              style={{ fontSize: "14px", fontWeight: 500 }}
              variant="outline-primary"
              onClick={handleShowMore}
            >
              Show More
            </Button>
          )}
      </div>
      <ToastContainer />
    </div>
  );
}
