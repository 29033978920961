import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { FaMicrophone } from "react-icons/fa6";
import { BsFillMicMuteFill } from "react-icons/bs";
import { FaPlay, FaPause } from "react-icons/fa";
import { RiScissorsCutFill } from "react-icons/ri";

export default function Record({
  segmentData,
  audioUrl,
  setAudioUrl,
  audioBlob,
  setAudioBlob,
  totalDuration,
  setTotalDuration,
  generatedUrl,
  segmentName,
  trimmedUrl,
  setTrimmedUrl,
  setLoopTranscript,
  showRecordPanel,
  setShowRecordPanel,
  trimDuration,
  setTrimDuration,
  isRegionResizable,
  setIsRegionResizable,
  allowGenerate,
  setAllowGenerate,
  allowResize,
  setAllowResize,
}) {
  const wavesurferRef = useRef(null);
  const wavesurferRef2 = useRef(null);

  const [record, setRecord] = useState(null);
  const [scrollingWaveform] = useState(true);
  const [, setRecordingBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [, setAvailableDevices] = useState([]);
  const [selectedDevice] = useState("");
  const progressRef = useRef("00:00:00:00");

  useEffect(() => {
    createWaveSurfer();

    RecordPlugin.getAvailableAudioDevices().then((devices) => {
      setAvailableDevices(devices);
    });

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //creating the wavesurfer instance for record panel
  const createWaveSurfer = () => {
    wavesurferRef.current = WaveSurfer.create({
      container: "#mic",
      waveColor: "black",
      progressColor: "black",
      height: 100,
    });

    const recordPlugin = wavesurferRef.current.registerPlugin(
      RecordPlugin.create({ scrollingWaveform, renderRecordedAudio: false })
    );

    //making the recorded audio more compactable.
    recordPlugin.on("record-end", async (blob) => {
      const audioContext = new AudioContext();
      const arrayBuffer = await blob.arrayBuffer();

      audioContext.decodeAudioData(arrayBuffer, async (audioBuffer) => {
        // Encode the audioBuffer to WAV and then to base64 URL
        const wavBlob = audioBufferToWavBlob(audioBuffer);
        const base64Url = await blobToBase64(wavBlob);

        setAudioUrl(base64Url);
        setAllowGenerate(true);
        setRecordingBlob(blob);
        console.log(progressRef);

        // Optionally hide the record panel and show another panel
        setShowRecordPanel(true);
      });
    });

    // Utility function to convert audioBuffer to WAV blob
    function audioBufferToWavBlob(audioBuffer) {
      const numOfChannels = audioBuffer.numberOfChannels;
      const length = audioBuffer.length * numOfChannels * 2 + 44;
      const buffer = new ArrayBuffer(length);
      const view = new DataView(buffer);
      const channels = [];
      let offset = 0;
      let pos = 0;

      // Write WAV header
      setUint32(0x46464952); // "RIFF"
      setUint32(length - 8); // file length - 8
      setUint32(0x45564157); // "WAVE"

      setUint32(0x20746d66); // "fmt " chunk
      setUint32(16); // length = 16
      setUint16(1); // PCM (uncompressed)
      setUint16(numOfChannels);
      setUint32(audioBuffer.sampleRate);
      setUint32(audioBuffer.sampleRate * 2 * numOfChannels); // avg. bytes/sec
      setUint16(numOfChannels * 2); // block-align
      setUint16(16); // 16-bit (hardcoded in this demo)

      setUint32(0x61746164); // "data" - chunk
      setUint32(length - pos - 4); // chunk length

      // Write interleaved data
      for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
        channels.push(audioBuffer.getChannelData(i));
      }

      while (pos < length) {
        for (let i = 0; i < numOfChannels; i++) {
          const sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
          view.setInt16(
            pos,
            sample < 0 ? sample * 0x8000 : sample * 0x7fff,
            true
          ); // convert to PCM
          pos += 2;
        }
        offset++;
      }

      return new Blob([buffer], { type: "audio/wav" });

      function setUint16(data) {
        view.setUint16(pos, data, true);
        pos += 2;
      }

      function setUint32(data) {
        view.setUint32(pos, data, true);
        pos += 4;
      }
    }

    // Utility function to convert blob to base64 URL
    function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    recordPlugin.on("record-progress", (time) => {
      updateProgress(time);
    });

    setRecord(recordPlugin);
  };

  const updateProgress = async (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const milliseconds = Math.floor((time % 1000) / 10);

    const formattedTime = [hours, minutes, seconds, milliseconds]
      .map((v, i) => (i < 3 && v < 10 ? "0" + v : v)) // Add leading zero to hours, minutes, and seconds
      .join(":");

    if (formattedTime) {
      progressRef.current.textContent = formattedTime;
    }
  };

  const handleRecord = () => {
    if (isRecording === true || isPaused === true) {
      record.stopRecording();
      setIsRecording(false);
      setIsPaused(false);
      setShowRecordPanel(true);
      setAllowGenerate(true);
      setAllowResize(true);
    } else {
      record.startRecording({ deviceId: selectedDevice }).then(() => {
        setIsRecording(true);
        setShowRecordPanel(false);
        setIsRegionResizable(true);
        setAllowGenerate(false);
        setTrimmedUrl(null);
      });
    }
  };

  const handlePause = () => {
    if (record.isPaused()) {
      record.resumeRecording();
      setIsPaused(false);
    } else {
      record.pauseRecording();
      setIsPaused(true);
    }
  };

  //if the audio is already present(uploaded), then load the recorded panel instead of record panel
  useEffect(() => {
    if (audioUrl) {
      setShowRecordPanel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  return (
    <div style={{ width: "100%" }}>
      <div className={showRecordPanel ? "hidden-region" : ""}>
        <div>
          <span>Duration: </span>{" "}
          <span className="oo" ref={progressRef}>
            00:00:00:00
          </span>
        </div>

        <div
          id="mic"
          style={{
            border: "1px solid #ddd",
            borderRadius: "4px",
            marginTop: "1rem",
            width: "100%",
            height: "100px",
          }}
          ref={wavesurferRef}
        ></div>

        <div style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}>
          {" "}
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100px",
            }}
            variant="outline-dark"
            onClick={handleRecord}
          >
            {isRecording || isPaused ? (
              <span>
                <span>
                  <BsFillMicMuteFill />
                </span>{" "}
                Stop
              </span>
            ) : (
              <span>
                <span>
                  <FaMicrophone />
                </span>{" "}
                Record
              </span>
            )}
          </Button>
          {isRecording && (
            <Button variant="outline-dark" onClick={handlePause}>
              {isPaused ? "Resume" : "Pause"}
            </Button>
          )}{" "}
        </div>
      </div>
      {/* ) : ( */}
      <div
        className={showRecordPanel ? "" : "hidden-region"}
        style={{ position: "relative" }}
      >
        <div id="recordings" style={{ width: "100%", position: "relative" }}>
          <WaveSurferComponent
            wavesurferRef2={wavesurferRef2}
            url={audioUrl}
            audioUrl={audioUrl}
            setAudioUrl={setAudioUrl}
            trimmedUrl={trimmedUrl}
            setTrimmedUrl={setTrimmedUrl}
            setLoopTranscript={setLoopTranscript}
            trimDuration={trimDuration}
            setTrimDuration={setTrimDuration}
            generatedUrl={generatedUrl}
            allowGenerate={allowGenerate}
            setAllowGenerate={setAllowGenerate}
            segmentData={segmentData}
            segmaneName={segmentName}
            audioBlob={audioBlob}
            setTotalDuration={setTotalDuration}
            totalDuration={totalDuration}
            isRegionResizable={isRegionResizable}
            setIsRegionResizable={setIsRegionResizable}
            allowResize={allowResize}
            setAllowResize={setAllowResize}
          />
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "150px",
              position: "absolute",
              bottom: "0px",
              left: "0px",
            }}
            variant="outline-dark"
            onClick={handleRecord}
          >
            {isRecording || isPaused ? (
              <span>
                <span>
                  <BsFillMicMuteFill />
                </span>{" "}
                Stop
              </span>
            ) : (
              <span>
                <span>
                  <FaMicrophone />
                </span>{" "}
                Record Again
              </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

//second wavesurfer componennt for recorded/uploaded audio visualization
const WaveSurferComponent = ({
  url,
  segmentData,
  audioBlob,
  segmentName,
  trimDuration,
  setTrimDuration,
  trimmedUrl,
  setTrimmedUrl,
  generatedUrl,
  wavesurferRef2,
  audioUrl,
  setAudioUrl,
  setLoopTranscript,
  isRegionResizable,
  setIsRegionResizable,
  allowResize,
  setAllowResize,
}) => {
  const regionElementRef = useRef(null);
  const [trimStart, setTrimStart] = useState(0);
  const [trimEnd, setTrimEnd] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRegionResized, setIsRegionResized] = useState(false);

  const formatTime = (durationInSeconds) => {
    const milliseconds = durationInSeconds * 1000;
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    const millis = Math.floor((milliseconds % 1000) / 10);

    const formattedTime = [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0"),
      millis.toString().padStart(2, "0"),
    ].join(":");

    return formattedTime;
  };

  //handler function for downloading the audio
  const downloadRecording = () => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${segmentName}_recording.wav`; // Set the filename
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    } else if (audioUrl !== "") {
      console.log("jbjbjb");
      console.log(audioUrl);
      console.log(trimmedUrl, "ye hai");

      const a = document.createElement("a");
      a.href = audioUrl !== "" ? audioUrl : trimmedUrl;
      a.download = `${segmentName}_recording.wav`; // Set the filename
      document.body.appendChild(a);
      a.click();
    }
  };

  //creating the wavesurfer instance for the recorded panel
  useEffect(() => {
    if (!url) return; // Exit early if url is not defined

    wavesurferRef2.current = WaveSurfer.create({
      container: "#recorded-audio",
      waveColor: "black",
      progressColor: "black",
      height: 100,
      // backend: 'MediaElement',
      // url,
    });

    wavesurferRef2.current.load(url, null, "auto");

    const wsRegions = wavesurferRef2.current.registerPlugin(
      RegionsPlugin.create()
    );

    const randomColor = () => `rgba(153, 148, 151, 0.24)`;

    //region is created once the url is decoded.
    wavesurferRef2.current.on("decode", () => {
      const duration = wavesurferRef2.current.getDuration();
      const region = wsRegions.addRegion({
        id: "id",
        start: 0,
        end: duration,
        color: randomColor(),
        drag: true,
        resize: isRegionResizable,
      });

      region.element.style.cursor = "ew-resize";
      region.element.style.borderRadius = "5px";
      region.element.style.pointerEvents = "all";
      region.element.style.position = "absolute";
      region.element.style.borderLeft = "5px solid grey";
      region.element.style.borderRight = "5px solid grey";

      //setting the duration for trim
      setTrimDuration(region.end - region.start);

      //executes everytime the region changes
      region.on("update", (updatedRegion) => {
        setTrimDuration(region.end - region.start);
        setTrimStart(region.start);
        setTrimEnd(region.end);
        setIsRegionResized(true);
      });

      regionElementRef.current = region.element;

      //happening inside region
      wsRegions.on("region-in", (region) => {
        const video = document.getElementById("custom-video-player");

        const checkTime = () => {
          if (video.currentTime === segmentData.TranscriptEndTime) {
            video.pause();
          }

          wavesurferRef2.current.un("audioprocess", checkTime);
        };

        wavesurferRef2.current.on("audioprocess", checkTime);
      });

      //when the region event ends
      wsRegions.on("region-out", (region) => {
        console.log("region-out", region);

        // region.pause();
        const wavesurfer = wavesurferRef2.current;
        const video = document.getElementById("custom-video-player");

        const checkTime = () => {
          if (wavesurferRef2.current.getCurrentTime() >= region.end) {
            wavesurferRef2.current.pause();
            video.pause();
            setLoopTranscript(true);
            video.muted = false;
            wavesurfer.seekTo(region.start / wavesurfer.getDuration());
            wavesurferRef2.current.pause();

            video.currentTime = segmentData.TranscriptStartTime;
            wavesurferRef2.current.un("audioprocess", checkTime);
          }
        };

        wavesurferRef2.current.on("audioprocess", checkTime);
      });

      //when the region is clicked
      wsRegions.on("region-clicked", (region, e) => {
        e.stopPropagation(); // prevent triggering a click on the waveform
        region.play();
        region.setOptions({ color: randomColor() });
      });
    });

    //when the cursor reachs the end.
    wavesurferRef2.current.on("finish", () => {
      // const duration = wavesurferRef2.current.getDuration();
      const video = document.getElementById("custom-video-player");

      video.pause();
      setLoopTranscript(true);
      video.muted = false;
      video.currentTime = segmentData.TranscriptStartTime;
    });

    const handlePause = () => setIsPlaying(false);
    const handlePlay = () => setIsPlaying(true);

    wavesurferRef2.current.on("pause", handlePause);
    wavesurferRef2.current.on("play", handlePlay);

    //cleaning up when the instance is ended.
    return () => {
      if (wavesurferRef2.current) {
        wavesurferRef2.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (trimmedUrl !== null) {
      setAudioUrl(trimmedUrl);
      setIsRegionResized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedUrl]);

  //catching the region click event outside the instance
  const triggerRegionClick = () => {
    const video = document.getElementById("custom-video-player");

    if (regionElementRef.current) {
      regionElementRef.current.click();
      video.play();
      video.muted = true;
      setLoopTranscript(false);
    }
  };

  //handler function for trimming the audio
  const trimAudio = async () => {
    if (trimStart === 0 && trimEnd === 0) {
      alert("Please select a region to trim.");
      return;
    }

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const startOffset = Math.floor(audioBuffer.sampleRate * trimStart);
    const endOffset = Math.floor(audioBuffer.sampleRate * trimEnd);
    const length = endOffset - startOffset;

    const trimmedBuffer = audioContext.createBuffer(
      audioBuffer.numberOfChannels,
      length,
      audioBuffer.sampleRate
    );

    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      trimmedBuffer.copyToChannel(
        channelData.subarray(startOffset, endOffset),
        channel
      );
    }

    const offlineContext = new OfflineAudioContext(
      trimmedBuffer.numberOfChannels,
      trimmedBuffer.length,
      trimmedBuffer.sampleRate
    );

    const source = offlineContext.createBufferSource();
    source.buffer = trimmedBuffer;
    source.connect(offlineContext.destination);
    source.start();

    const renderedBuffer = await offlineContext.startRendering();

    const wavBlob = bufferToWave(renderedBuffer, 0, renderedBuffer.length);
    const trimmedUrl = URL.createObjectURL(wavBlob);

    setTrimmedUrl(trimmedUrl);
    console.log(trimStart, trimEnd);
  };

  const bufferToWave = (abuffer, offset, len) => {
    const numOfChan = abuffer.numberOfChannels;
    const length = len * numOfChan * 2 + 44;
    const buffer = new ArrayBuffer(length);
    const view = new DataView(buffer);
    const channels = [];
    let pos = 0;

    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(abuffer.sampleRate);
    setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (hardcoded in this demo)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    for (let i = 0; i < abuffer.numberOfChannels; i++)
      channels.push(abuffer.getChannelData(i));

    while (pos < length) {
      for (let i = 0; i < numOfChan; i++) {
        const sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        view.setInt16(
          pos,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        );
        pos += 2;
      }
      offset++;
    }

    return new Blob([buffer], { type: "audio/wav" });

    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <span>Duration: </span> <span> {formatTime(trimDuration)}</span>
      </div>
      <div
        style={{ width: "100%", height: "100px", marginTop: "1rem" }}
        id="recorded-audio"
        ref={wavesurferRef2}
      ></div>

      <div
        style={{
          marginLeft: "10.5rem",
          display: "flex",
          gap: "1rem",
          paddingTop: "1rem",
        }}
      >
        <Button variant="outline-dark" onClick={triggerRegionClick}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </Button>
        <Button
          variant="outline-dark"
          onClick={trimAudio}
          disabled={!isRegionResized || !allowResize}
        >
          <RiScissorsCutFill />
        </Button>

        <Button
          variant="outline-dark"
          onClick={downloadRecording}
          disabled={!audioBlob && audioUrl === "" && generatedUrl === ""}
        >
          <FaDownload />
        </Button>
      </div>
    </div>
  );
};
