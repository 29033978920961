import React, { useEffect, useState } from "react";
import { Button, Pagination } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";

export default function UserList({
  searchText,
  userList,
  setUserList,
  filteredUserList,
  setFilteredUserList,
  setSelectedAdminComponent,
  user,
  setUser,
  addUserClicked,
  setAddUserClicked,
}) {
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const request = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/get_all_users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserList(data.AllUsers);
          setFilteredUserList(data.AllUsers); // Set filteredUserList initially with all users
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Change the format as needed
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredUserList(userList); // If searchText is empty, show all users
      return;
    }

    const filteredUsers = userList.filter(
      (user) =>
        (user.Name &&
          user.Name.toLowerCase().includes(searchText.toLowerCase())) ||
        (user.Email &&
          user.Email.toLowerCase().includes(searchText.toLowerCase())) ||
        (user.SubscriptionName &&
          user.SubscriptionName.toLowerCase().includes(
            searchText.toLowerCase()
          )) ||
        (searchText.toLowerCase() === "active" &&
          user.SubscriptionStatus === 1) ||
        (searchText.toLowerCase() === "inactive" &&
          user.SubscriptionStatus === 0) ||
        (user.Phone &&
          user.Phone.toLowerCase().includes(searchText.toLowerCase())) ||
        (user.Country &&
          user.Country.toLowerCase().includes(searchText.toLowerCase()))
    );
    setFilteredUserList(filteredUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, userList]);

  // Get current users
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUserList.slice(
    indexOfFirstUser,
    indexOfLastUser
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function handleEdit(user) {
    console.log("user is ", user);
    setUser(user);
    console.log(user);
    setSelectedAdminComponent("user-edit");
    navigate("/admin/edit");

    setAddUserClicked(false);
  }

  function handleAddUser(user) {
    console.log("user is ", user);
    setUser(user);
    console.log(user);
    setSelectedAdminComponent("user-edit");
    navigate("/admin/edit");
    setAddUserClicked(true);
  }
  return (
    <div className="mt-2 ms-5 me-5">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {" "}
        <Button variant="primary" onClick={(e) => handleAddUser(user)}>
          Add
        </Button>
      </div>
      <Table striped bordered hover className="mt-4">
        {currentUsers.length > 0 && (
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Country</th>
              <th>Email</th>
              <th>Subscription Name</th>
              <th>Usage Credits / Limit Credits</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Subscription Status</th>
              <th>Edit</th>
            </tr>
          </thead>
        )}
        <tbody>
          {currentUsers.map((user, index) => (
            <tr key={index}>
              <td
                style={{
                  height: "50px",
                  width: "10%",
                  maxWidth: "9vw",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {user.Name || ""}
              </td>
              <td style={{ height: "50px" }}>{user.Phone || ""}</td>
              <td style={{ height: "50px" }}>{user.Country || ""}</td>
              <td style={{ height: "50px" }}>{user.Email || ""}</td>
              <td style={{ height: "50px" }}>{user.SubscriptionName || ""}</td>
              <td style={{ height: "50px" }}>
                {user.UsageCredits} / {user.LimitCredits}
              </td>
              <td style={{ height: "50px" }}>{formatDate(user.StartDate)}</td>
              <td style={{ height: "50px" }}>{formatDate(user.EndDate)}</td>
              <td style={{ height: "50px" }}>
                {user.SubscriptionStatus === 1
                  ? "Active"
                  : user.SubscriptionStatus === 0
                  ? "Inactive"
                  : ""}
              </td>
              <td>
                <Button
                  size="sm"
                  variant="success"
                  onClick={(e) => handleEdit(user)}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {filteredUserList.length > itemsPerPage && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} />
            <Pagination.Prev
              onClick={() =>
                setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1))
              }
            />
            {Array.from(
              {
                length: Math.min(
                  Math.ceil(filteredUserList.length / itemsPerPage),
                  5
                ),
              },
              (_, i) => {
                const lastPage = Math.ceil(
                  filteredUserList.length / itemsPerPage
                );
                let startPage;
                if (lastPage <= 5 || currentPage <= 3) {
                  startPage = 1;
                } else if (currentPage + 1 >= lastPage) {
                  startPage = lastPage - 4;
                } else {
                  startPage = currentPage - 2;
                }
                const pageNumber = startPage + i;
                return (
                  <Pagination.Item
                    key={pageNumber}
                    onClick={() => paginate(pageNumber)}
                    active={pageNumber === currentPage}
                  >
                    {pageNumber}
                  </Pagination.Item>
                );
              }
            )}

            <Pagination.Next
              onClick={() =>
                setCurrentPage((next) =>
                  next < Math.ceil(filteredUserList.length / itemsPerPage)
                    ? next + 1
                    : Math.ceil(filteredUserList.length / itemsPerPage)
                )
              }
            />
            <Pagination.Last
              onClick={() =>
                setCurrentPage(
                  Math.ceil(filteredUserList.length / itemsPerPage)
                )
              }
            />
          </Pagination>
        </div>
      )}
    </div>
  );
}
