import React, { useEffect, useRef, useState } from "react";
import { Button, Table, Pagination, Modal, Form } from "react-bootstrap";
import { FaUpload } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

export default function ManageUserProjects({ searchText, filterOn }) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [projectList, setProjectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [project, setProject] = useState({});
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setFile(null);
    setUploadPercentage(0);
  };
  const handleShow = () => setShow(true);

  function getAllUsers() {
    const request = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/get_all_users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          let users = Array.from(
            data.AllUsers.map((user) => `${user.Name || ""} (${user.Email})`)
          );
          users.unshift("All users");
          setUsers(users);
          setAllUsers(data.AllUsers);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  function getAllProjects() {
    setLoading(true);
    const request = {
      UserId: userId,
      Type: 3,
    };
    fetch(`${apiURL}/web/get_project_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setProjectList(data.ProjectList);
          setLoading(false);
        } else {
          setProjectList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllProjects(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // Filter projects before pagination
  const filteredProjects = projectList.filter((project) => {
    const searchTextLowerCase = searchText.toLowerCase();
    return (
      searchText === "" ||
      project.Name.toLowerCase().includes(searchTextLowerCase) ||
      project.Email.toLowerCase().includes(searchTextLowerCase) ||
      project.UserName.toLowerCase().includes(searchTextLowerCase)
    );
  });

  // Pagination logic
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Change the format as needed
  };

  const sourceLanguage = (langId) => {
    const languagesList = JSON.parse(localStorage.getItem("languagesList"));
    if (langId !== null) {
      const matchingLanguage = languagesList.find(
        (language) => language.Id === langId
      );
      return matchingLanguage ? matchingLanguage.Name : "";
    } else {
      return "";
    }
  };

  function handleFileChange(selectedFile) {
    // console.log(selectedFile.name);
    setFile(selectedFile);
  }

  function handleDrop(e) {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    // console.log(selectedFile.name);
    setFile(selectedFile);
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  function handleUpload() {
    uploadFile();
  }

  function uploadFile() {
    if (file) {
      setFlag(true);
      const formData = new FormData();
      formData.append("filename", file.name);
      formData.append("file", file);
      // console.log(formData);
      axios
        .post(`${apiURL}/util/file_upload_aws`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercentage(percentCompleted); // Assuming setUploadProgress is a function to update the UI with the percentage
          },
        })
        .then((response) => {
          const res = response.data;
          if (res.IsValid) {
            if (res.Url) {
              console.log(res.Url);
              replace_dubbed_video(res.Url);
            }
          } else {
            notify("Upload unsuccessful, please upload a valid file!");
            setFlag(false);
          }
        })
        .catch((error) => {
          console.error("Error occurred during upload:", error);
        });
    }
  }

  const notifySuccess = () => {
    toast.success("Dubbed video is updated successfully.", {
      className: "custom-success-toast",
    });
  };

  function replace_dubbed_video(url) {
    if (project !== undefined && project !== null) {
      const request = {
        ProjectId: project.Id,
        TargetLangId: project.ResultVideos[0].TargetLangId,
        DubbedVideoUrl: url,
      };
      fetch(`${apiURL}/web/replace_dubbed_video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the data from the API
          if (data.IsValid) {
            handleClose();
            setFlag(false);
            notifySuccess();
          }
        })
        .catch((error) => {
          // Handle errors
          setFlag(false);
          console.error("Error:", error);
        });
    }
  }

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  return (
    <div className="ms-5 me-5">
      {filterOn && (
        <Form.Select
          size="sm"
          style={{ width: "20%" }}
          onChange={(e) => {
            // console.log('the dropdown value is',e.target.value)
            if (e.target.value !== "All users") {
              let email = e.target.value.match(/\(([^)]+)\)/)?.[1];
              let matchingUser = allUsers.find((user) => user.Email === email);

              if (matchingUser) {
                setCurrentPage(1);
                // console.log(matchingUser.Id,matchingUser.Name);
                setUserId(matchingUser.Id);
              }
            } else {
              setUserId(null);
            }
          }}
        >
          {users.map((val, index) => (
            <option key={index}>{val}</option>
          ))}
        </Form.Select>
      )}

      {filteredProjects.length === 0 && loading === false ? (
        <p className="no-projects-txt">No records found.</p>
      ) : null}
      <Table className="mt-4" striped bordered hover>
        {filteredProjects.length !== 0 && (
          <thead>
            <tr>
              <th style={{ height: "50px", width: "20%" }}>Project Name</th>
              <th style={{ height: "50px", width: "20%" }}>User</th>
              <th style={{ height: "50px", width: "15%" }}>Source Language</th>
              <th style={{ height: "50px", width: "15%" }}>Target Language</th>
              <th style={{ height: "50px", width: "8%" }}>Created Date</th>
              <th style={{ height: "50px", width: "15%" }}>Status</th>
              <th style={{ height: "50px", width: "7%" }}>Action</th>
            </tr>
          </thead>
        )}
        {loading === false && (
          <tbody>
            {currentProjects.map((project, index) => (
              <tr key={project.Id}>
                <td
                  style={{
                    height: "50px",
                    width: "20%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "10vw",
                  }}
                >
                  {project.Name || ""}
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "20%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "10vw",
                    overflow: "hidden",
                  }}
                >
                  {project.UserName} ({project.Email})
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "15%",
                    maxWidth: "10vw",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {sourceLanguage(project.SourceLangId)}
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "15%",
                    maxWidth: "10vw",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {project.ResultVideos[0].TargetLangName || ""}
                </td>
                <td style={{ height: "50px", width: "8%" }}>
                  {formatDate(project.CreatedAt) || ""}
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "15%",
                    maxWidth: "10vw",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {project.ResultVideos[0].Status === 1
                    ? "Video uploaded"
                    : project.ResultVideos[0].Status === 5
                    ? "Speakers selected"
                    : project.ResultVideos[0].Status === 6
                    ? "Speakers selected"
                    : project.ResultVideos[0].Status === 7
                    ? "Transcription done"
                    : project.ResultVideos[0].Status === 8
                    ? "Transcription done"
                    : project.ResultVideos[0].Status === 9
                    ? "Translation done"
                    : project.ResultVideos[0].Status === 10
                    ? "Translation done"
                    : project.ResultVideos[0].Status === 11
                    ? "Dubbed video ready"
                    : project.ResultVideos[0].Status === 12
                    ? "LipSync video processing"
                    : project.ResultVideos[0].Status === 13
                    ? "LipSync video processing"
                    : project.ResultVideos[0].Status === 14
                    ? "LipSync video processing"
                    : project.ResultVideos[0].Status === 1499
                    ? "LipSync video processing"
                    : project.ResultVideos[0].Status === 15
                    ? "LipSync video ready"
                    : project.ResultVideos[0].Status === 101
                    ? "Translation done"
                    : null}
                </td>
                <td style={{ height: "50px", width: "7%" }}>
                  {project.ResultVideos[0].Status === 11 ? (
                    <Button
                      size="sm"
                      onClick={(e) => {
                        setProject(project);
                        handleShow();
                      }}
                    >
                      Upload
                    </Button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {loading && (
        <p
          className="mt-5"
          style={{
            fontWeight: 600,
            textAlign: "center",
            width: "100%",
            height: "50px",
          }}
        >
          loading...
        </p>
      )}
      {filteredProjects.length >= projectsPerPage || currentPage > 1 ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {loading === false && (
            <Pagination>
              <Pagination.First onClick={() => setCurrentPage(1)} />
              <Pagination.Prev
                onClick={() =>
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1))
                }
              />
              {Array.from(
                {
                  length: Math.min(
                    Math.ceil(filteredProjects.length / projectsPerPage),
                    5
                  ),
                },
                (_, i) => {
                  const lastPage = Math.ceil(
                    filteredProjects.length / projectsPerPage
                  );
                  let startPage;
                  if (lastPage <= 5 || currentPage <= 3) {
                    startPage = 1;
                  } else if (currentPage + 1 >= lastPage) {
                    startPage = lastPage - 4;
                  } else {
                    startPage = currentPage - 2;
                  }
                  const pageNumber = startPage + i;
                  return (
                    <Pagination.Item
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      active={pageNumber === currentPage}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                }
              )}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((next) =>
                    next < Math.ceil(filteredProjects.length / projectsPerPage)
                      ? next + 1
                      : Math.ceil(filteredProjects.length / projectsPerPage)
                  )
                }
              />
              <Pagination.Last
                onClick={() =>
                  setCurrentPage(
                    Math.ceil(filteredProjects.length / projectsPerPage)
                  )
                }
              />
            </Pagination>
          )}
        </div>
      ) : null}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", position: "relative" }}
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {flag === false ? (
            <div>
              {file !== null ? (
                <Button
                  variant="text"
                  className="delete-button"
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 15,
                    fontWeight: 700,
                  }}
                  onClick={handleDeleteFile}
                >
                  <RiDeleteBin6Line />
                </Button>
              ) : null}
              {file === null ? (
                <div>
                  <Button
                    variant="text"
                    style={{ fontSize: "50px" }}
                    onClick={handleUploadClick}
                  >
                    <FaUpload />
                  </Button>
                  <p style={{ fontWeight: 500 }}>
                    Drop your video file here, or click to browse
                  </p>
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: "none" }}
                    accept="video/mp4,video/*"
                    onChange={(e) => handleFileChange(e.target.files[0])}
                  />
                </div>
              ) : (
                <div>
                  <p className="mb-2 mt-2">{file.name}</p>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div style={{ width: "100px", height: "100px" }}>
                <CircularProgressbar
                  styles={buildStyles({ textSize: "30px", trailColor: "grey" })}
                  value={uploadPercentage}
                  text={`${uploadPercentage}%`}
                />
              </div>
              <p>File upload in progress</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {file !== null && flag === false ? (
            <Button variant="primary" onClick={(e) => handleUpload()}>
              Upload
            </Button>
          ) : (
            <Button variant="secondary" disabled>
              Upload
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
