import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Table } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa6';
import { BsInfoCircleFill } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { SiVerizon } from 'react-icons/si';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FileValidation from '../../../utils/FileValidation';
import { SiReverbnation } from 'react-icons/si';
import { PiSpeakerSimpleLowBold } from 'react-icons/pi';
import { TfiPulse } from 'react-icons/tfi';
import { SiAudiomack } from 'react-icons/si';
import { GiStopwatch } from 'react-icons/gi';
import { PiSpeakerSimpleHighBold } from 'react-icons/pi';
import { CgShapeZigzag } from 'react-icons/cg';
import { SiZig } from 'react-icons/si';
import { LuSignalHigh } from 'react-icons/lu';
import { FiSpeaker } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';

import axios from 'axios';

export default function AddVoice({
  labels,
  setLabels,
  samples,
  setSamples,
  vName,
  setVName,
  description,
  sampleCount,
  setSampleCount,
  setDescription,
  uploadPercentage,
  flag,
  isChecked,
  setIsChecked,
  isEditing,
  sampleUrls,
  setSampleUrls,
  tabKey,
  setTabKey,
  isPolicyChecked,
  setIsPolicyChecked,
  existingVoiceId,
  setExistingVoiceId,
  addExistingVoice,
  handleSave,
  addVoiceClicked,
  setUploadPercentage,
  setSoundsLikeParam,
  setSoundsLikeStatus,
  newExistingVoiceName,
  setNewExistingVoiceName,
  userInfo,
  vType,
  newSliderValues,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [addLabel, setAddLabel] = useState(false);
  const [labelCount, setLabelCount] = useState(0);
  const [previewUrl, setPreviewUrl] = useState('');

  const inputRef = useRef(null);
  const inputValueRef = useRef(null);
  const [labelKey, setLabelKey] = useState('');
  const [labelValue, setLabelValue] = useState('');
  const labelColors = ['#fbf8c5', '#dce1e7', '#f4eaff', '#e6ebff', '#ffcc9959'];
  const fileInputRef = useRef(null);

  //
  const [showPreview, setShowPreview] = useState(false);
  const handlePreviewClose = () => setShowPreview(false);
  const handlePreviewShow = () => setShowPreview(true);

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  function deleteLabel(index) {
    // Create a copy of the labels array
    const updatedLabels = [...labels];
    // Remove the label at the specified index
    updatedLabels.splice(index, 1);
    // Update the labels state
    setLabels(updatedLabels);
    // Decrement the label count
    setLabelCount((prevCount) => prevCount - 1);
  }
  function deleteSample(index) {
    // Create a copy of the labels array
    const updatedSamples = [...samples];
    // Remove the label at the specified index
    updatedSamples.splice(index, 1);
    // Update the labels state
    setSamples(updatedSamples);
  }

  function deleteSampleUrl(id) {
    // Find the index of the element with the given id in the sampleUrls array
    const index = sampleUrls.findIndex((sample) => sample.Id === id);

    // If the element is found, update its status to 3
    if (index !== -1) {
      const updatedSampleUrls = [...sampleUrls];
      updatedSampleUrls[index].Status = 3;

      // Update the state with the modified sampleUrls array
      setSampleUrls(updatedSampleUrls);
      setSampleCount(sampleCount - 1);
    } else {
      console.error(`Sample with id ${id} not found.`);
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function handleUploadClick() {
    fileInputRef.current.click();
  }
  function addLabels() {
    const label = { key: labelKey, value: labelValue };

    // Check if the labelKey and labelValue are not empty
    if (labelKey.trim() !== '' && labelValue.trim() !== '') {
      // Check if the labelKey and labelValue pair does not already exist
      const isLabelUnique = !labels.some(
        (l) => l.key === labelKey && l.value === labelValue
      );

      if (isLabelUnique) {
        // Check if the label count is less than 5
        if (labelCount < 5) {
          // Add the new label to the labels state array
          setLabels((prevLabels) => [...prevLabels, label]);
          // Clear the labelKey and labelValue inputs
          setLabelKey('');
          setLabelValue('');
          inputRef.current.value = '';
          inputValueRef.current.value = '';
          // Increment the label count
          setLabelCount((prevCount) => prevCount + 1);
        } else {
          // Alert the user that the maximum label count is reached
          alert('Maximum label count reached (5 labels).');
        }
      } else {
        // Alert the user that the label already exists
        alert('Label already exists. Please enter a unique label.');
      }
    } else {
      // Alert the user if the key or value is empty
      alert('Label key and value cannot be empty.');
    }
  }

  // files upload handleFileChange function

  const handleFileChange = (e) => {
    const files = e.target.files;

    // Maintain a list of filenames to check for duplicates
    const existingFileNames = samples.map((file) => file.name);

    // Iterate through the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if the file has already been added
      if (existingFileNames.includes(file.name)) {
        alert(`File already added: ${file.name}`);
        continue;
      }

      // Check file size (max 10MB)
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > maxSize) {
        alert(`File size exceeds 10MB limit: ${file.name}`);
        continue;
      }

      // Get the file extension
      const fileNameParts = file.name.split('.');
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      // Check file type (MP3, WAV)
      const allowedExtensions = ['mp3', 'wav'];
      if (!allowedExtensions.includes(fileExtension)) {
        alert(`Unsupported file type: ${file.name}`);
        continue;
      }

      // Create a new File with a different name
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${file.name}`;

      const updatedFile = new File(
        [file.slice(0, file.size, file.type)],
        fileNameWithTimestamp,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );

      // Add the valid file to the selectedFiles state
      setSamples((prevFiles) => [...prevFiles, updatedFile]);

      // Add the filename to the existingFileNames list
      existingFileNames.push(updatedFile.name);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    // Maintain a list of filenames without timestamps to check for duplicates
    const existingFileNamesWithoutTimestamp = samples.map((file) =>
      file.name.replace(/^\d+_/, '')
    );

    // Iterate through the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Extract the filename without timestamp using a regular expression
      const filenameWithoutTimestamp = file.name.replace(/^\d+_/, '');

      // Check if the file has already been added
      if (
        existingFileNamesWithoutTimestamp.includes(filenameWithoutTimestamp)
      ) {
        alert(`File already added: ${file.name}`);
        continue;
      }

      // Check file size (max 10MB)
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > maxSize) {
        alert(`File size exceeds 10MB limit: ${file.name}`);
        continue;
      }

      // Get the file extension
      const fileNameParts = file.name.split('.');
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      // Check file type (MP3, WAV)
      const allowedExtensions = ['mp3', 'wav'];
      if (!allowedExtensions.includes(fileExtension)) {
        alert(`Unsupported file type: ${file.name}`);
        continue;
      }

      // Create a new File with a different name
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${file.name}`;

      const updatedFile = new File(
        [file.slice(0, file.size, file.type)],
        fileNameWithTimestamp,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      // Add the valid file to the selectedFiles state
      setSamples((prevFiles) => [...prevFiles, updatedFile]);

      // Add the filename to the existingFileNames list
      existingFileNamesWithoutTimestamp.push(updatedFile.name);
    }
  };

  useEffect(() => {
    // Filter out sampleUrls with Status equal to 3
    if (isEditing) {
      console.log(sampleUrls);

      const validSampleUrls = sampleUrls.filter(
        (sample) => sample.Status !== 3
      );

      if (validSampleUrls.length > 0) {
        setSampleCount(validSampleUrls.length + samples.length);
        setLabelCount(labels.length);
      }
    } else {
      setSampleCount(samples.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples, labels]);

  const menuItems = [
    {
      id: 'ThreeEQ',
      name: '3-EQ',
      icon: <PiSpeakerSimpleLowBold />,
      sliders: [{ name: 'Bass' }, { name: 'Midrange' }, { name: 'Treble' }],
    },
    {
      id: 'Distortion',
      name: 'Distortion',
      icon: <SiZig />,

      sliders: [{ name: 'Distortion Level' }],
    },
    {
      id: 'Delay',
      name: 'Delay',
      icon: <GiStopwatch />,

      sliders: [
        { name: 'Delay Volume' },
        { name: 'Delay Time' },
        { name: 'Number Of Echos' },
      ],
    },
    {
      id: 'PitchShifter',
      name: 'Pitch Shifter',
      icon: <LuSignalHigh />,

      sliders: [{ name: 'Pitch' }],
    },
    {
      id: 'Reverb',
      name: 'Reverb',
      icon: <SiReverbnation />,

      sliders: [
        { name: 'Reverb Time' },
        { name: 'Reverb Level' },
        { name: 'Vocal Level' },
      ],
    },
    {
      id: 'Tremolo',
      name: 'Tremolo',
      icon: <TfiPulse />,

      sliders: [{ name: 'Tremolo Pitch' }, { name: 'Tremolo Volume' }],
    },
    {
      id: 'HighPass',
      name: 'High Pass',
      icon: <SiAudiomack />,

      sliders: [{ name: 'Cutoff(H)' }],
    },
    {
      id: 'Lowpass',
      name: 'Low Pass',
      icon: <CgShapeZigzag />,

      sliders: [{ name: 'Cutoff(L)' }],
    },
    {
      id: 'Echo',
      name: 'Echo',
      icon: <FiSpeaker />,

      sliders: [{ name: 'Delay' }, { name: 'Decay' }],
    },
    // {
    //   id: 'Equalizer',
    //   name: 'Equalizer',
    //   icon: <HiAdjustmentsVertical />,

    //   sliders: [{ name: 'Dry' }, { name: 'ER' }, { name: 'Reverb' }],
    // },
    {
      id: 'Volumebar',
      name: 'Volume bar',
      icon: <PiSpeakerSimpleHighBold />,

      sliders: [{ name: 'Output Volume' }, { name: 'Left-Right playback' }],
    },
  ];

  const sliderConfig = {
    Bass: { min: -80, max: 10, step: 1, defaultValue: 8 },
    Midrange: { min: -80, max: 10, step: 1, defaultValue: 4 },
    Treble: { min: -80, max: 10, step: 1, defaultValue: 7 },
    'Distortion Level': { min: 0, max: 1, step: 0.01, defaultValue: 0.7 },
    'Delay Volume': { min: -30, max: 1, step: 0.001, defaultValue: -5 },
    'Delay Time': { min: 0, max: 5, step: 0.001, defaultValue: 0.5 },
    Pitch: { min: 0.5, max: 2, step: 0.1, defaultValue: 0.7 },
    'Reverb Time': { min: 0, max: 100, step: 1, defaultValue: 60 },
    'Reverb Level': { min: -80, max: 10, step: 1, defaultValue: 5 },
    'Vocal Level': { min: -80, max: 10, step: 1, defaultValue: 0 },
    'Tremolo Pitch': { min: 0.001, max: 20, step: 0.001, defaultValue: 5 },
    'Tremolo Volume': { min: 1, max: 100, step: 1, defaultValue: 100 },
    'Cutoff(H)': { min: 10, max: 8000, step: 1, defaultValue: 2000 },
    'Cutoff(L)': { min: 10, max: 8000, step: 1, defaultValue: 2000 },
    Delay: { min: 0.001, max: 100, step: 0.001, defaultValue: 0.2 },
    Decay: { min: 0, max: 1, step: 0.01, defaultValue: 0.5 },
    'Output Volume': { min: 0, max: 100, step: 0.01, defaultValue: 1 },
    'Left-Right playback': { min: -100, max: 100, step: 1, defaultValue: 0 },
  };
  const [isSoundsLikeChecked, setIsSoundsLikeChecked] = useState(false); // Checkbox state
  const [activeRadio, setActiveRadio] = useState('CxL'); // Radio button state
  const [activeMenu, setActiveMenu] = useState('ThreeEQ'); // Selected menu
  const [previewClicked, setPreviewClicked] = useState(false);

  useEffect(() => {
    setSoundsLikeStatus(isSoundsLikeChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoundsLikeChecked]);

  const [sliderValues, setSliderValues] = useState({
    BaseVoiceCloningEngine: 0, // Default value at the top level
    ...menuItems.reduce((acc, item) => {
      acc[item.id] = {
        Status: false, // Default OFF for all menus
        ...item.sliders.reduce((sAcc, slider) => {
          sAcc[slider.name.replace(/\s+/g, '')] =
            sliderConfig[slider.name]?.defaultValue || 0;
          return sAcc;
        }, {}),
      };
      return acc;
    }, {}),
  });

  // Handle the primary toggle for each menu
  const handlePrimaryToggle = (menuId) => {
    setSliderValues((prev) => ({
      ...prev,
      [menuId]: {
        ...prev[menuId],
        Status: !prev[menuId].Status, // Toggle overall menu status
      },
    }));
  };

  // Handle individual slider changes
  const handleSliderChange = (menuId, sliderName, value) => {
    setSliderValues((prev) => ({
      ...prev,
      [menuId]: {
        ...prev[menuId],
        [sliderName]: value, // Update slider value
      },
    }));
  };

  // Handle radio button change for BaseVoiceCloningEngine
  const handleRadioChange = (value) => {
    setActiveRadio(value);
    setSliderValues((prev) => ({
      ...prev,
      BaseVoiceCloningEngine: value === 'CxL' ? 0 : 1,
    }));
  };

  useEffect(() => {
    setSoundsLikeParam(sliderValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValues]);

  const updateSliderValues = (newSliderValues) => {
    setSliderValues((prev) => {
      // Merge the new values into the previous state
      const updatedValues = { ...prev };

      // Iterate over the keys of newSliderValues
      for (const key in newSliderValues) {
        if (newSliderValues.hasOwnProperty(key)) {
          // If the key exists in the previous state and is an object, merge deeply
          if (
            typeof newSliderValues[key] === 'object' &&
            !Array.isArray(newSliderValues[key])
          ) {
            updatedValues[key] = {
              ...updatedValues[key],
              ...newSliderValues[key],
            };
          } else {
            // Otherwise, update the key directly
            updatedValues[key] = newSliderValues[key];
          }
        }
      }

      return updatedValues;
    });
  };

  useEffect(() => {
    if (vType === 3) {
      setIsSoundsLikeChecked(true);
      updateSliderValues(newSliderValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSliderValues]);

  function fetchPreview(url) {
    console.log(sliderValues);

    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      SampleAudio: url,
      SoundsLikeParams: sliderValues,
    };

    fetch(`${apiURL}/dub/soundslike/preview`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setPreviewUrl(data.PreviewUrl);
          setPreviewClicked(false);
          handlePreviewShow();
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  function handlePreview() {
    if (sampleUrls && isEditing) {
      fetchPreview(sampleUrls[0].Url);
      // console.log(sampleUrls[0].Url);
    } else {
      uploadSample();
    }
  }

  function uploadSample() {
    setPreviewClicked(true);
    console.log(samples, sampleUrls);
    if (!samples || samples.length === 0) {
      notify('Provide at least one sample.');
      setPreviewClicked(false);
      return;
    }
    for (const file of samples) {
      if (!FileValidation(file.name)) {
        return; // Exit the function if any filename is invalid
      }
    }
    const formData = new FormData();
    if (samples.length > 0) {
      formData.append('file0', samples[0]);
    }
    axios
      .post(`${apiURL}/util/multifile_upload_aws`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted); // Assuming setUploadProgress is a function to update the UI with the percentage
        },
      })
      .then((response) => {
        const res = response.data;
        if (res.IsValid) {
          fetchPreview(res.Urls[0]);
        } else {
          notify('Failed to upload files');
          console.error('Failed to upload files');
        }
      })
      .catch((error) => {
        // setFlag(false);
        console.error('Error occurred during upload:', error);
      });
  }

  return (
    <div style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
      {/* {isEditing === false  ? ( */}
      <Tabs
        defaultActiveKey="new-voice"
        id="add-voice"
        className="mb-3"
        onSelect={(k) => {
          if (k === 'existing-voice') {
            setTabKey(k);
          } else {
            setTabKey('');
          }
        }}
      >
        <Tab eventKey="new-voice" title={isEditing ? null : 'New Voice'}>
          <div style={{ gap: '2rem' }}>
            <div style={{ width: '100%' }}>
              <Form.Label style={{ fontWeight: 500 }}>Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={isEditing ? vName : undefined}
                onBlur={(e) => setVName(e.target.value)}
              />
              <div
                className="fileUpload"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e)}
              >
                {flag === false ? (
                  <div>
                    <Button
                      variant="text"
                      style={{ fontSize: '50px', marginBottom: '10px' }}
                      onClick={handleUploadClick}
                    >
                      <FaUpload />
                    </Button>
                    <p style={{ color: 'black' }}>
                      Drop your audio here, or click to browse
                    </p>
                    <p style={{ color: 'grey', fontSize: '14px' }}>
                      Audio files upto 10MB each
                    </p>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: 'none' }}
                      accept=".mp3, .wav"
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div>
                    {uploadPercentage !== 100 ? (
                      <div style={{ width: '150px', height: '150px' }}>
                        <CircularProgressbar
                          value={uploadPercentage}
                          text={`${uploadPercentage}%`}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '20px',
                        }}
                      >
                        <span style={{ marginRight: '10px', fontWeight: 500 }}>
                          Voice Clone creation in progress
                        </span>{' '}
                        <Spinner
                          size="sm"
                          animation="border"
                          variant="success"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="samplesTable">
                <span style={{ fontWeight: 500 }}>Samples</span>
                <span className="ms-2">{sampleCount} / 25</span>
                <div>
                  <Table striped bordered className="mt-2">
                    {sampleUrls
                      ? sampleUrls
                          .filter((sample) => sample.Status !== 3)
                          .map((sample, index) => (
                            <tr style={{ height: '80px' }} key={sample.Id}>
                              {' '}
                              <td
                                style={{
                                  maxWidth: '150px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  paddingLeft: '20px',
                                }}
                              >
                                {sample.Name}
                              </td>
                              <td>
                                {' '}
                                <audio
                                  className="ms-5"
                                  src={sample.Url}
                                  controls
                                  disablePictureInPicture
                                  controlsList="nodownload noplaybackrate"
                                />{' '}
                              </td>
                              <td>
                                {' '}
                                <Button
                                  className="me-3"
                                  variant="text"
                                  style={{ padding: 0 }}
                                  onClick={(e) => deleteSampleUrl(sample.Id)}
                                >
                                  <RiDeleteBin6Line />
                                </Button>{' '}
                              </td>
                            </tr>
                          ))
                      : null}
                    {samples
                      ? samples.map((sample, index) => (
                          <tr style={{ height: '80px' }} key={index}>
                            {' '}
                            <td
                              style={{
                                maxWidth: '150px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                paddingLeft: '20px',
                              }}
                            >
                              {sample.name}
                            </td>
                            <td>
                              {' '}
                              <audio
                                className="ms-5"
                                src={URL.createObjectURL(sample)}
                                controls
                                disablePictureInPicture
                                controlsList="nodownload noplaybackrate"
                              />{' '}
                            </td>
                            <td>
                              {' '}
                              <Button
                                className="me-3"
                                variant="text"
                                style={{ padding: 0 }}
                                onClick={(e) => deleteSample(index)}
                              >
                                <RiDeleteBin6Line />
                              </Button>{' '}
                            </td>
                          </tr>
                        ))
                      : null}
                  </Table>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <span style={{ fontWeight: 500 }}>Labels</span>
              <span className="ms-2">{labelCount} / 5</span>
              {addLabel === false && labels.length === 0 ? (
                <div className="mt-2 add-labels">
                  <p className="ms-3" style={{ fontSize: '20px' }}>
                    <BsInfoCircleFill />
                  </p>
                  <p className="mt-2" style={{ color: 'grey' }}>
                    No labels? Click{' '}
                    <Button
                      className="mb-2"
                      style={{ padding: 0, color: '#0d6efd' }}
                      variant="text"
                      onClick={(e) => setAddLabel(true)}
                    >
                      <FaPlus />
                    </Button>{' '}
                    to add first one{' '}
                  </p>
                </div>
              ) : (
                <div className="mt-2 labels-div">
                  {labels.map((tag, index) => (
                    <div
                      className="label-pair"
                      key={index}
                      style={{
                        backgroundColor:
                          labelColors[index % labelColors.length],
                      }}
                    >
                      <span>
                        {tag.key} : {tag.value}
                      </span>
                      <Button
                        className="ms-2 mb-2"
                        style={{ padding: 0 }}
                        variant="text"
                        onClick={() => deleteLabel(index)}
                      >
                        <RiDeleteBin6Line />
                      </Button>
                    </div>
                  ))}
                  {labelCount < 5 ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}
                    >
                      <Form.Control
                        type="text"
                        style={{ width: '150px' }}
                        onBlur={(e) => setLabelKey(e.target.value)}
                        id="labelkey"
                        ref={inputRef}
                        placeholder="key e.g. accent"
                      />{' '}
                      <span>:</span>
                      <Form.Control
                        type="text"
                        style={{
                          width: '150px',
                          '::placeholder': { fontSize: '12px' },
                        }}
                        id="labelvalue"
                        onBlur={(e) => setLabelValue(e.target.value)}
                        ref={inputValueRef}
                        placeholder="value e.g. Indian"
                      />
                      <Button
                        variant="text"
                        style={{ color: 'green' }}
                        onClick={(e) => addLabels()}
                      >
                        <SiVerizon />
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}
              {userInfo.SoundsLikeAccess === true &&
                (vType === null || vType === 3) && (
                  <div className="checkbox-section">
                    <Form.Check
                      className="mt-5 mb-3"
                      type="checkbox"
                      onChange={() =>
                        setIsSoundsLikeChecked(!isSoundsLikeChecked)
                      }
                      checked={isSoundsLikeChecked}
                      label={
                        <>
                          <span style={{ color: 'grey' }}>SoundsLike</span>
                        </>
                      }
                    />
                  </div>
                )}

              {isSoundsLikeChecked &&
                userInfo.SoundsLikeAccess === true &&
                (vType === null || vType === 3) && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        // marginBottom: '20px',
                        gap: '40px',
                      }}
                    >
                      <span style={{ fontWeight: '500' }}>
                        Base Voice Cloning Engine
                      </span>
                      <div style={{ display: 'flex', gap: '40px' }}>
                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            fontWeight: '500',
                          }}
                        >
                          <input
                            type="radio"
                            name="voiceClone"
                            value="CxL"
                            checked={activeRadio === 'CxL'}
                            onChange={(e) => handleRadioChange(e.target.value)}
                          />
                          CxL
                        </label>
                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            fontWeight: '500',
                          }}
                        >
                          <input
                            type="radio"
                            name="voiceClone"
                            value="CxR"
                            checked={activeRadio === 'CxR'}
                            onChange={(e) => handleRadioChange(e.target.value)}
                          />
                          CxR
                        </label>
                      </div>
                    </div>

                    <div className="audio-eq-container">
                      {/* Sidebar */}
                      <div className="audio-eq-sidebar">
                        {menuItems.map((item) => (
                          <div
                            style={{
                              paddingLeft: '5px',
                              paddingRight: '5px',
                            }}
                            className={`menu-item ${
                              activeMenu === item.id ? 'active' : ''
                            }`}
                            key={item.id}
                            onClick={() => setActiveMenu(item.id)}
                          >
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                paddingLeft: '5px',
                              }}
                            >
                              {item.icon}
                              {item.name}
                            </span>{' '}
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={sliderValues[item.id]?.Status || false}
                                onChange={() => handlePrimaryToggle(item.id)}
                              />
                              <span className="slider-toggle"></span>
                            </label>
                          </div>
                        ))}
                      </div>

                      {/* Right Panel */}
                      <div className="audio-eq-panel">
                        <div className="slider-panel">
                          <div className="slider-panel-header">
                            <span
                              style={{
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {
                                menuItems.find((item) => item.id === activeMenu)
                                  ?.name
                              }
                            </span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  sliderValues[activeMenu]?.Status || false
                                }
                                onChange={() => handlePrimaryToggle(activeMenu)}
                              />
                              <span className="slider-toggle"></span>
                            </label>
                          </div>

                          <div
                            className="slider-container"
                            style={{ marginTop: '10px' }}
                          >
                            {menuItems
                              .find((item) => item.id === activeMenu)
                              ?.sliders.map((slider) => (
                                <div className="slider-group" key={slider.name}>
                                  <div className="slider-group2">
                                    <label>{slider.name}</label>
                                    <span>
                                      {sliderValues[activeMenu]?.[
                                        slider.name.replace(/\s+/g, '')
                                      ] ??
                                        sliderConfig[slider.name]?.defaultValue}
                                    </span>
                                  </div>
                                  {/* <label>{slider.name}</label> */}
                                  <input
                                    type="range"
                                    min={sliderConfig[slider.name]?.min || 0}
                                    max={sliderConfig[slider.name]?.max || 100}
                                    step={sliderConfig[slider.name]?.step || 1}
                                    value={
                                      sliderValues[activeMenu]?.[
                                        slider.name.replace(/\s+/g, '')
                                      ] ??
                                      sliderConfig[slider.name]?.defaultValue
                                    }
                                    onChange={(e) =>
                                      handleSliderChange(
                                        activeMenu,
                                        slider.name.replace(/\s+/g, ''),
                                        parseFloat(e.target.value)
                                      )
                                    }
                                    disabled={!sliderValues[activeMenu]?.Status} // Disable slider if toggle is OFF
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    {previewClicked === false ? (
                      <Button onClick={handlePreview}>Preview</Button>
                    ) : (
                      <Button variant="dark" disabled>
                        Processing Audio...
                      </Button>
                    )}
                  </div>
                )}

              <div className="mt-3">
                <Form.Label style={{ fontWeight: 500 }}>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  defaultValue={isEditing ? description : undefined}
                  onBlur={(e) => setDescription(e.target.value)}
                />
              </div>

              <Form.Check
                className="mt-5 mb-3"
                type="checkbox"
                onChange={handleCheckboxChange}
                checked={isChecked}
                label={
                  <>
                    <span style={{ color: 'grey' }}>
                      I hereby confirm that I have all necessary rights or
                      consents to upload and clone these voice samples and that
                      I will not use the platform-generated Content for any
                      illegal, fraudulent, or harmful purpose. I reaffirm my
                      obligation to abide by Spectral Studio
                    </span>
                    <span style={{ color: 'grey' }}>'s </span>
                    <span
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() =>
                        window.open(
                          'https://spectralstudios.ai/terms/',
                          '_blank'
                        )
                      }
                    >
                      Terms of Service
                    </span>
                    <span style={{ color: 'gray' }}> and </span>
                    <span
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() =>
                        window.open(
                          'https://spectralstudios.ai/privacy/',
                          '_blank'
                        )
                      }
                    >
                      Privacy Policy
                    </span>
                  </>
                }
              />
            </div>
            <Modal show={showPreview} onHide={handlePreviewClose}>
              <Modal.Header closeButton>
                <Modal.Title>Audio:</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <audio
                    id="audio-Player"
                    type="audio/wav"
                    preload="auto"
                    autoPlay
                    className="ms-2"
                    src={previewUrl}
                    controls
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                  />
                </div>{' '}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handlePreviewClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Tab>

        {!isEditing && (
          <Tab eventKey="existing-voice" title="Existing Voice">
            <Form.Label style={{ fontWeight: 500 }}>Name</Form.Label>
            <Form.Control
              type="text"
              value={newExistingVoiceName}
              onChange={(e) => setNewExistingVoiceName(e.target.value)}
            />
            <Form.Label style={{ fontWeight: 500, marginTop: '10px' }}>
              Existing Voice Id
            </Form.Label>
            <Form.Control
              type="text"
              onBlur={(e) => setExistingVoiceId(e.target.value)}
            />
            <Form.Check
              className="mt-5 mb-3"
              type="checkbox"
              onChange={(e) => setIsPolicyChecked(!isPolicyChecked)}
              checked={isPolicyChecked}
              label={
                <>
                  <span style={{ color: 'grey' }}>
                    I hereby confirm that I have all necessary rights or
                    consents to upload and clone these voice samples and that I
                    will not use the platform-generated Content for any illegal,
                    fraudulent, or harmful purpose. I reaffirm my obligation to
                    abide by Spectral Studio
                  </span>
                  <span style={{ color: 'grey' }}>'s </span>
                  <span
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() =>
                      window.open('https://spectralstudios.ai/terms/', '_blank')
                    }
                  >
                    Terms of Service
                  </span>
                  <span style={{ color: 'gray' }}> and </span>
                  <span
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() =>
                      window.open(
                        'https://spectralstudios.ai/privacy/',
                        '_blank'
                      )
                    }
                  >
                    Privacy Policy
                  </span>
                </>
              }
            />
          </Tab>
        )}
      </Tabs>
      {/* ) : null} */}

      {tabKey !== 'existing-voice' ? (
        <div style={{ height: '12vh' }}>
          {flag === false && isChecked && vName !== '' && sampleCount > 0 ? (
            <Button variant="secondary" onClick={handleSave}>
              {isEditing ? 'Edit Voice' : 'Add Voice'}
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleSave} disabled>
              {isEditing ? 'Edit Voice' : 'Add Voice'}
            </Button>
          )}
        </div>
      ) : (
        <div style={{ height: 'fit-content' }}>
          {isPolicyChecked &&
          addVoiceClicked === false &&
          existingVoiceId !== '' &&
          newExistingVoiceName !== '' ? (
            <Button variant="secondary" onClick={(e) => addExistingVoice()}>
              Add Voice
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleSave} disabled>
              Add Voice
            </Button>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
