import React, { useEffect, useState } from 'react';
import VoiceTransferCreate from './VoiceTransferCreate';
import AudioSegments from './AudioSegments';
import DubbedAudio from './DubbedAudio';
import VoiceTransferHome from './VoiceTransferHome';
import { Routes, Route, Outlet, useLocation } from 'react-router-dom';

export default function VoiceTransfer({
  setSearchText,
  searchText,
  setUserInfo,
  selectedVoiceTransferComponent,
  setSelectedVoiceTransferComponent,
  menuLock,
  setMenuLock,
  projectName,
  setProjectName,
}) {
  const [projectId, setProjectId] = useState('');
  // const projectIdLc = projectId.toLowerCase();
  const [languageId, setLanguageId] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [, setSelectedLangArray] = useState([]);
  const [voice1, setVoice1] = useState({});
  const [voicesArray, setVoicesArray] = useState([]);
  const [, setSpeakers] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    document.title = 'Voice Transfer - Spectral Studio';
    setSearchText('');
  });

  const location = useLocation();

  useEffect(() => {
    // Check if the pathname matches 'voiceTransfer' and not 'voiceTransfer/...'
    const isVoiceTransferHome = location.pathname === '/voice-transfer';

    if (isVoiceTransferHome) {
      setMenuLock(false);
    } else {
      setMenuLock(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (selectedVoiceTransferComponent !== 'voiceTransferHome') {
      setMenuLock(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVoiceTransferComponent]);

  // --------------------------------------------------------------------------------------------------------------------------

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // console.log("voice list is",data.VoiceList[0]);
          localStorage.setItem('voiceList', JSON.stringify(data.VoiceList));
          setVoice1(data.VoiceList[0]);
          setVoicesArray(data.VoiceList);
        }
      } else {
        // setFlag(false);
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchVoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonProps = {
    searchText,
    setSelectedVoiceTransferComponent,
    setUserInfo,
    menuLock,
    setMenuLock,
    projectId,
    setProjectId,
    voicesArray,
    setProjectName,
    setSelectedLangArray,
    setVoice1,
    setVoicesArray,
    projectName,
    setSpeakers,
    voice1,
    languageId,
    setLanguageId,
    videoUrl,
    setVideoUrl,
  };

  return (
    <div className="spectralTranscend">
      {(location.pathname === '/voice-transfer' ||
        location.pathname === '/voice-transfer/home') && (
        <h2 className="mt-4 ms-5">Voice Transfer</h2>
      )}
      {(location.pathname === '/voice-transfer' ||
        location.pathname === '/voice-transfer/home') && (
        <span className="mt-2 ms-5" style={{ color: 'grey' }}>
          Fully flexible end-to-end localization workflow for powerful, dynamic
          and human-in-the-loop localization.
        </span>
      )}
      <div>
        <Routes>
          <Route path="/" element={<VoiceTransferHome {...commonProps} />} />
          <Route path="home" element={<VoiceTransferHome {...commonProps} />} />
          <Route
            path="create"
            element={<VoiceTransferCreate {...commonProps} />}
          />
          <Route
            path=":projectIdLc/audio-segments"
            element={
              <AudioSegments
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectId={projectId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                languageId={languageId}
                videoUrl={videoUrl}
                setProjectName={setProjectName}
                projectName={projectName}
              />
            }
          />
          <Route
            path=":projectIdLc/download"
            element={
              <DubbedAudio
                setProjectId={setProjectId}
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectId={projectId}
                setLanguageId={setLanguageId}
                languageId={languageId}
                voicesArray={voicesArray}
                setVoicesArray={setVoicesArray}
              />
            }
          />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
}
