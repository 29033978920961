import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import loading from "../../../multimedia/loading.gif";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineEdit } from "react-icons/ai";

import SelectSpeakerVoiceModal from "./SelectSpeakerVoiceModal";
import "./spectralTranscend.css";
import { FaUpload } from "react-icons/fa6";
import { GrClose } from "react-icons/gr";
import Form from "react-bootstrap/Form";
// import InputGroup from "react-bootstrap/InputGroup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileValidation from "../../../utils/FileValidation";
// import loading from '../../../multimedia/loading.gif';
import { v4 as uuidv4 } from "uuid";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// import { FaYoutube } from 'react-icons/fa';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import axios from "axios";

export default function TranscendCreate({
  setSelectedVoiceTransferComponent,
  projectId,
  setProjectId,
  languageId,
  setLanguageId,
  setVideoUrl,
  setSelectedLangArray,
  projectName,
  setProjectName,
  videoUrl,
  voicesArray,
  setVoicesArray,
  voiceId,

  voice1,
  setVoice1,
  setSpeakers,
  setUserInfo,
}) {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const fileInputRef = useRef(null);
  // const audioFileInputRef = useRef(null);
  // const backgroundFileInputRef = useRef(null);
  // const [language, setLanguage] = useState([]);
  const [flag, setFlag] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [audioSourceUrl, setAudioSourceUrl] = useState("");
  const [durationError, setDurationError] = useState(false);
  const [timeCost, setTimeCost] = useState(0);
  const userDetails = JSON.parse(localStorage.getItem("userInfo"));
  const [isYoutube, setISYoutube] = useState(false);
  const [, setUrlVal] = useState("");
  const [originalLanguageId] = useState(null);
  // const [, setDriveThumbnail] = useState('');
  // const [, setDriveTitle] = useState('');
  const [, setClickedOk] = useState(false);
  const [wait, setWait] = useState(false);
  const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
  // const [, setAudioFile] = useState(null);
  // const [, setBackgroundFile] = useState(null);
  // const [, setAudioFileUrl] = useState('');
  // const [, setBackgroundFileUrl] = useState('');
  const [selectVoice, setSelectVoice] = useState(voice1);
  // const [deleteClicked, setDeleteClicked] = useState(false);

  //

  // const [selectVoice, setSelectVoice] = useState(voice1);
  // const [activeSpeakerIndex, setActiveSpeakerIndex] = useState(null);
  // const [deleteClicked, setDeleteClicked] = useState(false);
  // const [show, setShow] = useState(false);
  // const [showDp, setShowDp] = useState(false);
  // const [addClicked, setAddClicked] = useState(false);
  // const [nextClicked, setNextClicked] = useState(false);
  // const [speakerId, setSpeakerId] = useState("");
  // const [speakerVoiceId, setSpeakerVoiceId] = useState("");
  // const [speaker_Name, setSpeaker_Name] = useState("");
  // const [faceList, setFaceList] = useState([]);

  // const [voices, setVoices] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.set({ page: "/spectral-transcend/create" });
    ReactGA.pageview("/spectral-transcend/create");
  }, []);

  const [tempSelectVoice, setTempSelectVoice] = useState({});

  const handleShow = () => {
    setTempSelectVoice(selectVoice);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleSelectVoice = () => {
    setSelectVoice(tempSelectVoice);
    handleClose();
  };

  // function selectOriginalLanguage(value) {
  //   // console.log(value);
  //   if (value === 'Auto Detect') {
  //     setOriginalLanguageId(null);
  //   } else {
  //     const reqLanguage = language.find((lang) => lang.Name === value);
  //     if (reqLanguage) {
  //       setOriginalLanguageId(reqLanguage.Id);
  //     }
  //   }
  // }

  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("userInfo")]);

  function fetchUserInfo(id) {
    const reqBody = {
      UserId: id,
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem("userInfo", JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  // function for get project api call
  async function getProject(pId) {
    console.log("its working");
    const req = {
      ProjectId: pId,
    };
    // console.log('required req is',req);
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        setVideoUrl(data.VideoUrl);
        initiateAudioProcess(
          data.VideoUrl,
          localStorage.getItem("userSessionId"),
          pId
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  function calculateTimeCost(duration) {
    const timeInterval = 30;
    const maxDuration = userDetails.LimitVideoLength * 60;

    if (duration <= 0) {
      setTimeCost(0);
    } else if (duration < maxDuration) {
      if (duration <= timeInterval) {
        const timeCostValue = 0.5;
        setTimeCost(timeCostValue);
      } else {
        const intervals = Math.ceil(duration / timeInterval);
        const timeCostValue = intervals * 0.5;
        setTimeCost(timeCostValue);
      }
    } else {
      const timeCostValue = Math.floor(maxDuration / timeInterval) * 0.5;
      setTimeCost(timeCostValue);
    }
  }

  // function fetchLanguageName() {
  //   setLanguage(JSON.parse(localStorage.getItem('languagesList')));
  // }

  useEffect(() => {
    handleVoiceSelection();
    console.log("hii");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (file !== null) {
      setAudioSourceUrl(URL.createObjectURL(file));
      console.log(audioSourceUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  function uploadFile(selectedFile) {
    // Validate the filename
    if (!FileValidation(selectedFile.name)) {
      return; // Exit the function if validation fails
    }

    // Continue with the upload process if validation passes
    setFlag(true);
    const formData = new FormData();
    formData.append("filename", selectedFile.name);
    formData.append("file", selectedFile);

    axios
      .post(`${apiURL}/util/file_upload_aws`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted); // Assuming setUploadProgress is a function to update the UI with the percentage
        },
      })
      .then((response) => {
        const res = response.data;
        if (res.IsValid) {
          const id = localStorage.getItem("userSessionId");
          if (res.Url && id) {
            createProject(res.Url, id, selectVoice.Id);
          }
        } else {
          notify("Upload unsuccessful, please upload a valid file!");
          console.error("Failed to upload file");
        }
      })
      .catch((error) => {
        setFlag(false);
        console.error("Error occurred during upload:", error);
      });
  }

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  // const [checkboxValues, setCheckboxValues] = useState(
  //   language.reduce((acc, language) => {
  //     acc[language] = false;
  //     return acc;
  //   }, {})
  // );
  // const isAnyCheckboxChecked = Object.entries(checkboxValues)
  //   .filter(([key, value]) => key !== 'Select Language')
  //   .map(([key, value]) => value)
  //   .includes(true);

  // const handleCheckboxChange = (selectedLanguage) => {
  //   if (selectedLanguage !== 'Select Language') {
  //     setCheckboxValues((prevValues) => {
  //       // Create a new object with only the selected language
  //       const newValues = { [selectedLanguage]: !prevValues[selectedLanguage] };
  //       return newValues;
  //     });
  //   } else {
  //     setCheckboxValues((prevValues) => {
  //       const newValues = {};
  //       for (const key in prevValues) {
  //         newValues[key] = false;
  //       }
  //       return newValues;
  //     });
  //   }
  // };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  // const handleAudioUploadClick = () => {
  //   audioFileInputRef.current.click();
  // };
  // const handleBackgroundUploadClick = () => {
  //   backgroundFileInputRef.current.click();
  // };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile.type.startsWith("audio/")) {
      e.target.value = null;
      notify("Please select a valid audio file.");
      return;
    } else {
      // file duration calculation
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      // Event listener for when metadata is loaded
      video.addEventListener("loadedmetadata", () => {
        // Get the duration in seconds
        const durationInSeconds = video.duration;
        calculateTimeCost(Math.floor(durationInSeconds));
        // Check if the duration is more than 2 minutes (120 seconds)
        if (durationInSeconds > userDetails.LimitVideoLength * 60) {
          setDurationError(true);
        }
        // Clean up the video element
        URL.revokeObjectURL(video.src);
      });
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${selectedFile.name}`;
      // Create a new File object with the updated name
      const updatedFile = new File([selectedFile], fileNameWithTimestamp, {
        type: selectedFile.type,
        lastModified: selectedFile.lastModified,
      });
      setFile(updatedFile);
      const url = URL.createObjectURL(updatedFile);
      console.log("Generated URL:", url);
      console.log(file);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    if (!selectedFile.type.startsWith("audio/")) {
      e.target.value = null;
      notify("Please select a valid audio file.");
      return;
    } else {
      // file duration calculation
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      // Event listener for when metadata is loaded
      video.addEventListener("loadedmetadata", () => {
        // Get the duration in seconds
        const durationInSeconds = video.duration;
        calculateTimeCost(Math.floor(durationInSeconds));
        // Check if the duration is more than 2 minutes (120 seconds)
        if (durationInSeconds > userDetails.LimitVideoLength * 60) {
          setDurationError(true);
        }
        // Clean up the video element
        URL.revokeObjectURL(video.src);
      });
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${selectedFile.name}`;
      // Create a new File object with the updated name
      const updatedFile = new File([selectedFile], fileNameWithTimestamp, {
        type: selectedFile.type,
        lastModified: selectedFile.lastModified,
      });
      setFile(updatedFile);
    }
  };

  // handle audio and background file changes

  // function handleAudioFileChange(e) {
  //   const selectedFile = e.target.files[0];
  //   if (!selectedFile.type.startsWith('audio/')) {
  //     e.target.value = null;
  //     notify('Please select a valid audio file.');
  //     return;
  //   } else {
  //     setAudioFile(selectedFile);

  //     const formData = new FormData();
  //     formData.append('filename', selectedFile.name);
  //     formData.append('file', selectedFile);
  //     // console.log(formData);
  //     axios
  //       .post(`${apiURL}/util/file_upload_aws`, formData, {})
  //       .then((response) => {
  //         const res = response.data;
  //         if (res.IsValid) {
  //           setAudioFileUrl(res.Url);
  //         } else {
  //           notify('Upload unsuccessful, please upload a valid file!');
  //           console.error('Failed to upload file');
  //         }
  //       })
  //       .catch((error) => {
  //         setFlag(false);
  //         console.error('Error occurred during upload:', error);
  //       });
  //   }
  // }
  // const handleDropDialogue = (e) => {
  //   e.preventDefault();
  //   const selectedFile = e.dataTransfer.files[0];
  //   if (!selectedFile.type.startsWith('audio/')) {
  //     e.target.value = null;
  //     notify('Please select a valid audio file.');
  //     return;
  //   } else {
  //     setAudioFile(selectedFile);

  //     const formData = new FormData();
  //     formData.append('filename', selectedFile.name);
  //     formData.append('file', selectedFile);
  //     // console.log(formData);
  //     axios
  //       .post(`${apiURL}/util/file_upload_aws`, formData, {})
  //       .then((response) => {
  //         const res = response.data;
  //         if (res.IsValid) {
  //           setAudioFileUrl(res.Url);
  //         } else {
  //           notify('Upload unsuccessful, please upload a valid file!');
  //           console.error('Failed to upload file');
  //         }
  //       })
  //       .catch((error) => {
  //         setFlag(false);
  //         console.error('Error occurred during upload:', error);
  //       });
  //   }
  // };

  // function handleBackgroundFileChange(e) {
  //   const selectedFile = e.target.files[0];
  //   if (!selectedFile.type.startsWith('audio/')) {
  //     e.target.value = null;
  //     notify('Please select a valid audio file.');
  //     return;
  //   } else {
  //     setBackgroundFile(selectedFile);
  //     const formData = new FormData();
  //     formData.append('filename', selectedFile.name);
  //     formData.append('file', selectedFile);
  //     // console.log(formData);
  //     axios
  //       .post(`${apiURL}/util/file_upload_aws`, formData, {})
  //       .then((response) => {
  //         const res = response.data;
  //         if (res.IsValid) {
  //           setBackgroundFileUrl(res.Url);
  //         } else {
  //           notify('Upload unsuccessful, please upload a valid file!');
  //           console.error('Failed to upload file');
  //         }
  //       })
  //       .catch((error) => {
  //         setFlag(false);
  //         console.error('Error occurred during upload:', error);
  //       });
  //   }
  // }
  // const handleDropBackground = (e) => {
  //   e.preventDefault();
  //   const selectedFile = e.dataTransfer.files[0];
  //   if (!selectedFile.type.startsWith('audio/')) {
  //     e.target.value = null;
  //     notify('Please select a valid audio file.');
  //     return;
  //   } else {
  //     setBackgroundFile(selectedFile);

  //     const formData = new FormData();
  //     formData.append('filename', selectedFile.name);
  //     formData.append('file', selectedFile);
  //     // console.log(formData);
  //     axios
  //       .post(`${apiURL}/util/file_upload_aws`, formData, {})
  //       .then((response) => {
  //         const res = response.data;
  //         if (res.IsValid) {
  //           setBackgroundFileUrl(res.Url);
  //         } else {
  //           notify('Upload unsuccessful, please upload a valid file!');
  //           console.error('Failed to upload file');
  //         }
  //       })
  //       .catch((error) => {
  //         setFlag(false);
  //         console.error('Error occurred during upload:', error);
  //       });
  //   }
  // };

  // function to delete video file

  const handleDelete = () => {
    setFile(null);
    setDurationError(false);
    setUrl("");
    setTimeCost(0);
    setISYoutube(false);
    setUrlVal("");
    setClickedOk(false);
    setWait(false);
  };

  // voice api call

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // console.log("voice list is",data.VoiceList[0]);
          localStorage.setItem("voiceList", JSON.stringify(data.VoiceList));
          setVoice1(data.VoiceList[0]);
          setVoicesArray(data.VoiceList);
        }
      } else {
        setFlag(false);
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function createProject(fileUrl, id, selectedLanguages) {
    if (timeCost + userDetails.UsageCredits <= userDetails.LimitCredits) {
      setFlag(true);
      const request = {
        Name: projectName,
        OrgId: null,
        UserId: id,
        Type: 4,
        SourceLangId: originalLanguageId,
        // VideoUrl: fileUrl,
        // DubbingLanguageList: selectedLanguages,
        VocalOnlyUrl: fileUrl,
        // audioFileUrl && backgroundFileUrl !== '' ? audioFileUrl : null,
        // MusicOnlyUrl:
        //   audioFileUrl && backgroundFileUrl !== '' ? backgroundFileUrl : null,
      };

      fetch(`${apiURL}/web/create_voice_transfer_project`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.IsValid === true) {
            if (userDetails.IsDubbingClient === true) {
              fetchUserInfo(id);
              setSelectedVoiceTransferComponent("audio_segments");
              navigate(
                `/voice-transfer/${data.Id.toLowerCase()}/audio-segments`
              );

              // setSelectedVoiceTransferComponent('speaker');
              // getProject(data.ProjectId);
            } else {
              // const foundLanguage = language.find(
              //   (lang) => lang.Name === selectedLanguages[0]
              // );

              // setLanguageId(foundLanguage.Id);
              setSelectedVoiceTransferComponent("audio_segments");
              navigate(
                `/voice-transfer/${data.ProjectId.toLowerCase()}/audio-segments`
              );

              fetchUserInfo(id);
              fetchVoice(data.ProjectId);
              setVideoUrl(fileUrl);
              setProjectId(data.ProjectId);
              setProjectName(projectName);
              setSelectedLangArray(selectedLanguages);
              getProject(data.ProjectId);
            }
          } else {
            setFlag(false);
            console.error("Error:", data.Error);
          }
        })
        .catch((error) => {
          setFlag(false);
          console.error("API call failed:", error);
        });
    } else {
      setFlag(false);
      notify("Credits exceeding limit.");
    }
  }

  // detect all faces api call
  function detectAllFaces(pId, foundLanguage) {
    const req = {
      ProjectId: pId,
    };

    fetch(`${apiURL}/dub/detect_all_faces`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          console.log("ye kar raha");

          setSelectedVoiceTransferComponent("audio_segments");
        } else {
          setFlag(false);
          // console.error('Error:', data.Error);
        }
      })
      .catch((error) => {
        setFlag(false);
        // console.error('API call failed:', error);
      });
  }

  //-----------------------------------------------------------------
  function initiateAudioProcess(fileUrl, uId, pId, foundLanguage) {
    let requestBody = {
      // VideoUrl: fileUrl,
      //  "DubbingLanguageList": selectedLanguages,
      // UserId: uId,
      ProjectId: pId,
      Type: 4,
      VoiceId: selectVoice.Id,
    };

    fetch(`${apiURL}/dub/initiate_voice_transfer_project`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          console.log("ye hgcyitdukar raha");

          addSpeaker1(pId, foundLanguage);
          detectAllFaces(pId);
          setSelectedVoiceTransferComponent("audio_segments");
        } else {
          setFlag(false);
          // console.error('Error:', data.Error);
        }
      })
      .catch((error) => {
        setFlag(false);
        // console.error('API call failed:', error);
      });
  }
  //  add speaker 1 function

  function addSpeaker1(pId, foundLanguage) {
    const request = {
      ProjectId: pId,
      SpeakerList: [
        {
          Id: uuidv4(),
          Name: "Speaker 1",
          ProjectId: pId,
          LangId: foundLanguage.Id,
          FaceIds: [],
        },
      ],
    };

    fetch(`${apiURL}/web/update_project_speaker`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          setSpeakers(data.SpeakerData);
        } else {
          // console.error('Error:', data.Error);
        }
      })
      .catch((error) => {
        // console.error('API call failed:', error);
      });
  }

  function handleVoiceSelection() {
    return selectVoice.Id !== "";
  }

  //------------------------------------
  function handleNext() {
    if (projectId === "") {
      if (
        handleVoiceSelection() &&
        (file !== null || url.trim() !== "") &&
        projectName !== ""
      ) {
        if (file !== null) {
          uploadFile(file);
        } else if (url.trim() !== "") {
          // const selectedLanguages = Object.keys(checkboxValues).filter(
          //   (language) => checkboxValues[language]
          // );
          const id = localStorage.getItem("userSessionId");
          if (isYoutube) {
            const watchUrl = embeddedToNormalUrl(url);
            createProject(watchUrl, id, [selectVoice.Id]); // Use selected voice
          } else {
            createProject(url, id, [selectVoice.Id]);
            // Use selected voice
          }
        }
      } else {
        let errorMessage = "";

        if (!handleVoiceSelection()) {
          notify("Please select a voice.");
        }
        if (projectName === "") {
          errorMessage += "Please Enter a Project Name.\n\n";
          notify("Please Enter a Project Name.");
        }
        if (file === null && url.trim() === "") {
          if (errorMessage !== "") {
            errorMessage += "\n";
          }
          notify("Please upload an audio.");
        }
      }
    } else {
      if (handleVoiceSelection() && videoUrl && projectName !== "") {
        console.log("kar raha kaam");

        fetchVoice(projectId);
        setSelectedVoiceTransferComponent("audio_segments");
      } else {
        let errorMessage = "";

        if (!handleVoiceSelection() && !handleSelectVoice()) {
          errorMessage += "Please select a voice.";
        }
        if (errorMessage !== "") {
          notify(errorMessage);
        }
      }
    }
  }

  // function embedYouTubeUrl(value) {
  //   // Regular expression for validating YouTube URL
  //   const youtubeRegex =
  //     /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  //   if (youtubeRegex.test(value)) {
  //     // Extract video ID from the URL
  //     const videoId = value.match(youtubeRegex)[1];
  //     // Construct the embedded URL
  //     const embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
  //     setUrl(embeddedUrl);
  //     setISYoutube(true);
  //   }
  // }

  function embeddedToNormalUrl(embeddedUrl) {
    const videoId = embeddedUrl.match(/embed\/([^&?]*)/)[1];
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  // function handleUrl(value) {
  //   if (value !== '') {
  //     setClickedOk(true);
  //     setWait(true);
  //     const req = {
  //       UserId: localStorage.getItem('userSessionId'),
  //       VideoUrl: value,
  //     };

  //     fetch(`${apiURL}/web/video_info`, {
  //       method: 'POST',

  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //       },
  //       body: JSON.stringify(req),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (
  //           data.IsValid === true &&
  //           (data.VideoSource === 0 || data.VideoSource === 1)
  //         ) {
  //           if (data.VideoSource === 0) {
  //             embedYouTubeUrl(value);
  //             calculateTimeCost(data.Duration);
  //             if (data.Duration > userDetails.LimitVideoLength * 60) {
  //               setDurationError(true);
  //             }
  //           } else if (data.VideoSource === 1) {
  //             setISYoutube(false);
  //             setUrl(value);
  //             setWait(false);
  //             calculateTimeCost(data.Duration);
  //             setDriveThumbnail(data.ThumbnailUrl);
  //             setDriveTitle(data.VideoName);
  //             if (data.Duration > userDetails.LimitVideoLength * 60) {
  //               setDurationError(true);
  //             }
  //           }
  //         } else {
  //           notify(
  //             'There seems to be some issue with the video link, please try another valid link'
  //           );
  //           setWait(false);
  //           // console.error('Error:', data.Error);
  //         }
  //       })
  //       .catch((error) => {
  //         // console.error('API call failed:', error);
  //       });
  //   }
  // }

  return (
    // <div className='syncPage'>
    <div style={{ padding: "1%" }}>
      <Row>
        <Col md={8} sm={12}>
          <div className="fileUploadContainer">
            <div
              className="mb-3"
              style={{
                position: "absolute",
                top: 20,
                left: 25,
                width: "90%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span className="me-3 ms-2">Project Name :</span>

              {projectId === "" ? (
                <div style={{ position: "relative", width: "75%" }}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Project Name"
                    style={{ width: "80%" }}
                    onBlur={(e) => {
                      setProjectName(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <Form.Control
                  type="text"
                  style={{ width: "80%" }}
                  value={projectName}
                />
              )}
            </div>

            {file !== null || url.trim() !== "" ? (
              <Button
                variant="text"
                className="delete-button"
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  fontWeight: 700,
                }}
                onClick={handleDelete}
              >
                <GrClose />
              </Button>
            ) : null}

            {file === null && url.trim() === "" ? (
              <div
                className="video-upload-form"
                style={{ position: "absolute", top: 80, height: 300 }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <p style={{ textAlign: "left" }}>Audio file upload :</p>
                <Button
                  variant="text"
                  style={{ fontSize: "50px", marginBottom: "10px" }}
                  onClick={handleUploadClick}
                >
                  <FaUpload />
                </Button>
                <p style={{ color: "black" }}>
                  Drop your audio here, or click to browse
                </p>

                {wait === true ? (
                  <span className="ms-2">
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={loading}
                      alt="loading"
                    />
                  </span>
                ) : null}
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  accept="audio/mpeg, audio/*"
                  onChange={handleFileChange}
                />
              </div>
            ) : (
              <div style={{ position: "absolute", top: 80 }}>
                {file !== null ? (
                  isFirefox ? (
                    <audio className="">
                      <source
                        src={URL.createObjectURL(file)}
                        type="audio/mp3"
                        controls
                      />
                      Your browser does not support the video tag.
                    </audio>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>Selected Audio</p>
                      <audio
                        style={{
                          width: "600px",
                          height: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                        type=""
                        className="sourceVideo"
                        src={audioSourceUrl}
                        controls
                        // disablePictureInPicture
                        // controlsList="nodownload noplaybackrate"
                      />
                    </div>
                  )
                ) : url !== "" && isYoutube ? (
                  <div></div>
                ) : url !== "" && isYoutube === false ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "30vw",
                      minHeight: "max-content",
                      border: "1px solid #dee2e6",
                      justifyContent: "space-between",
                      padding: "4%",
                      borderRadius: "8px",
                    }}
                  >
                    <Button
                      className="mb-2"
                      variant="text"
                      style={{ padding: 0, fontSize: "20px" }}
                      onClick={handleDelete}
                    >
                      <RiDeleteBin6Line />
                    </Button>
                  </div>
                ) : null}
              </div>
            )}

            {durationError ? (
              <div
                style={{
                  textAlign: "center",
                  position: "absolute",
                  top: "52vh",
                }}
              >
                <p style={{ color: "red" }}>
                  Auduio length is longer than {userDetails.LimitVideoLength}{" "}
                  mins so it will be trimmed automatically.
                </p>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "97%",
                position: "absolute",
                top:
                  file === null && url.trim() === ""
                    ? 402
                    : durationError
                    ? "56vh"
                    : "53vh",
                justifyContent: "space-between",
              }}
            ></div>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="fileLanguageContainer">
            <div>
              {/* ------------- */}
              <div>
                <p className="mt-2">Select Voice :</p>

                <div
                  className=""
                  style={{ display: "flex", justifyContent: "start" }}
                >
                  <InputGroup
                    style={{
                      border: ".3px solid #dbdbdb",
                      borderRadius: "5px",
                    }}
                    className=""
                  >
                    <Form.Control
                      id={selectVoice.Id}
                      className="speakerVoice"
                      style={{ border: "1px solid white", cursor: "pointer" }}
                      readOnly
                      value={`${selectVoice.Name} (${selectVoice.Provider})`}
                      placeholder="select voice"
                      onClick={handleShow}
                    />
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "none",
                      }}
                      onClick={handleShow}
                    >
                      <AiOutlineEdit />
                    </Button>
                  </InputGroup>
                </div>

                {/* ---------- */}

                <Modal
                  size="lg"
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}>
                      Select Voice for the audio
                    </Modal.Title>
                  </Modal.Header>

                  {/* ---------------------------------------------------select voice */}

                  <SelectSpeakerVoiceModal
                    tempSelectVoice={tempSelectVoice}
                    setTempSelectVoice={setTempSelectVoice}
                    handleClose={handleClose}
                  />

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "rgb(0 151 254)",
                        color: "white",
                      }}
                      onClick={handleSelectVoice}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "auto",
                borderTop: "1px solid rgb(222 226 230)",
                paddingTop: 20,
              }}
            >
              <span style={{ marginLeft: "2%" }}>Credit usage</span>
              <span style={{ marginRight: "2%" }}>{timeCost} min</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12}>
          <div className="nxtBtnContainer mb-3">
            {flag === false ? (
              <button className="nxtBtn" onClick={(e) => handleNext()}>
                Next
              </button>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                {" "}
                <button className="nxtBtn" disabled>
                  Uploading
                </button>{" "}
                <span>
                  {uploadPercentage < 100 && url === "" ? (
                    <div style={{ width: "60px", height: "60px" }}>
                      <CircularProgressbar
                        styles={buildStyles({
                          textSize: "30px",
                          trailColor: "grey",
                        })}
                        value={uploadPercentage}
                        text={`${uploadPercentage}%`}
                      />
                    </div>
                  ) : null}
                  {uploadPercentage > 99 || url !== "" ? (
                    <img
                      style={{ width: "50px", height: "50px" }}
                      src={loading}
                      alt="loading"
                    />
                  ) : null}
                </span>{" "}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
}
