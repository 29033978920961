import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { FaPlay, FaPause } from "react-icons/fa";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import "./audioSegments.css";

export default function SplitAudio({
  handleSplitClose,
  projectId,
  segmentData,
  reFectchSegments,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [flag, setFlag] = useState(false);
  const wavesurferRef2 = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [splitPoint, setSplitPoint] = useState(null);

  useEffect(() => {
    setAudioUrl(segmentData.VC_SegmentUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regionElementRef = useRef(null);
  // const [trimStart, setTrimStart] = useState(0);
  // const [trimEnd, setTrimEnd] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRegionResized, setIsRegionResized] = useState(false);

  const formatTime = (durationInSeconds) => {
    const milliseconds = durationInSeconds * 1000;
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    const millis = Math.floor((milliseconds % 1000) / 10);

    const formattedTime = [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0"),
      millis.toString().padStart(2, "0"),
    ].join(":");

    return formattedTime;
  };

  const splitSegment = async () => {
    setFlag(true);
    const request = {
      ProjectId: projectId,
      TransferAudioSegmentId: segmentData.Id,
      TransferAudioSegmentResultId: segmentData.VC_SegmentId,
      SplitPoint: parseFloat(splitPoint.toFixed(3)),
    };
    try {
      const response = await fetch(`${apiURL}/dub/split_VT_segment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const result = await response.json();

        if (result.IsValid) {
          setFlag(false);
          handleSplitClose();
          reFectchSegments();
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!audioUrl) return; // Exit early if url is not defined

    wavesurferRef2.current = WaveSurfer.create({
      container: "#split_panel",
      waveColor: "black",
      progressColor: "black",
      height: 100,
      // backend: 'MediaElement',
      // url,
    });

    wavesurferRef2.current.load(audioUrl, null, "auto");

    const wsRegions = wavesurferRef2.current.registerPlugin(
      RegionsPlugin.create()
    );

    const randomColor = () => `rgba(153, 148, 151, 0.24)`;

    //region is created once the url is decoded.
    wavesurferRef2.current.on("decode", () => {
      const duration = wavesurferRef2.current.getDuration();
      const region = wsRegions.addRegion({
        id: "id",
        start: 0,
        end: duration,
        color: randomColor(),
        drag: false,
        resize: true,
      });

      const leftHandle = region.element.querySelector("region-handle-left");
      if (leftHandle) {
        leftHandle.style.display = "none";
      }

      region.element.style.cursor = "ew-resize";
      region.element.style.borderRadius = "5px";
      region.element.style.pointerEvents = "all";
      region.element.style.position = "absolute";
      region.element.style.borderLeft = "5px solid grey";
      region.element.style.borderRight = "5px solid grey";

      //setting the duration for trim
      setSplitPoint(region.end);

      //executes everytime the region changes
      region.on("update", (updatedRegion) => {
        setSplitPoint(region.end);
        setIsRegionResized(true);
      });

      regionElementRef.current = region.element;

      wsRegions.on("region-out", (region) => {
        console.log("region-out", region);

        // region.pause();
        const wavesurfer = wavesurferRef2.current;

        const checkTime = () => {
          if (wavesurferRef2.current.getCurrentTime() >= region.end) {
            wavesurferRef2.current.pause();

            wavesurfer.seekTo(region.start / wavesurfer.getDuration());
            wavesurferRef2.current.pause();

            wavesurferRef2.current.un("audioprocess", checkTime);
          }
        };

        wavesurferRef2.current.on("audioprocess", checkTime);
      });

      //when the region is clicked
      wsRegions.on("region-clicked", (region, e) => {
        e.stopPropagation(); // prevent triggering a click on the waveform
        region.play();
        region.setOptions({ color: randomColor() });
      });
    });

    const handlePause = () => setIsPlaying(false);
    const handlePlay = () => setIsPlaying(true);

    wavesurferRef2.current.on("pause", handlePause);
    wavesurferRef2.current.on("play", handlePlay);

    //cleaning up when the instance is ended.
    return () => {
      if (wavesurferRef2.current) {
        wavesurferRef2.current.destroy();
      }
    };
  }, [audioUrl]);

  const triggerRegionClick = () => {
    if (regionElementRef.current) {
      regionElementRef.current.click();
    }
  };

  useEffect(() => {
    if (isRegionResized === true) {
      console.log(splitPoint.toFixed(2));
      console.log(segmentData.VC_Segment_Duration.toFixed(2));
      if (
        splitPoint.toFixed(2) === segmentData.VC_Segment_Duration.toFixed(2)
      ) {
        setIsRegionResized(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitPoint]);

  return (
    <div>
      <Modal.Body>
        {/* <Container> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <span>Duration: </span> <span> {formatTime(splitPoint)}</span>
          </div>
          <div
            style={{ width: "100%", height: "100px", marginTop: "1rem" }}
            id="split_panel"
            ref={wavesurferRef2}
          ></div>

          <div
            style={{
              display: "flex",
              gap: "1rem",
              paddingTop: "1rem",
            }}
          >
            <Button variant="outline-dark" onClick={triggerRegionClick}>
              {isPlaying ? <FaPause /> : <FaPlay />}
            </Button>
          </div>
        </div>

        {/* </Container> */}
      </Modal.Body>
      {flag === false ? (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSplitClose}>
            Close
          </Button>
          <Button
            disabled={!isRegionResized}
            variant="primary"
            onClick={splitSegment}
          >
            Save
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="mt-2 mb-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span
              style={{ marginRight: "10px", fontSize: "14px", fontWeight: 500 }}
            >
              Audio split is in progress{" "}
            </span>{" "}
            <Spinner size="sm" animation="border" variant="success" />
          </div>
        </Modal.Footer>
      )}
      <ToastContainer />
    </div>
  );
}
