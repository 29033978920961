import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ErrorToast = () => {
  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  notify(
    'An error occurred. Please try again or contact support if issue persists.'
  );
  return <ToastContainer />;
};

export default ErrorToast;
