

export default function ProjectSettings() {
  return (
    <div>
      <div>
        <h3 className="mt-4">Project Settings</h3>
      </div>

      {/* <div className='scrollableDiv'
          
              >
            <AspectRatio/>
           <BackgroundAndUpload/>
           <Duration/>
            </div> */}
    </div>
  );
}
