import React, { useEffect } from "react";
import $ from "jquery";

const Marker = ({
  initialMarkings,
  positionSettings,
  audioPositionSettings,
  min,
  zoomLevel,
  setZoomLevel,
  lock,
}) => {
  const markingStyle = {
    width: "2px",
    height: "10px",
    backgroundColor: "gray",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const labelStyle = {
    position: "absolute",
    top: "-22px",
    fontSize: "12px",
    textAlign: "center", // Center the text horizontally
    transform: "translateX(-50%)", // Center the label horizontally
  };

  const markings = [];
  const offset = min;
  let lastLabeledValue = -1; // Initialize with a value that won't be present in markings
  // Determine dynamic labeling conditions based on initialMarkings
  let labelFrequency = 5;
  if (initialMarkings <= 60) labelFrequency = 5;
  else if (initialMarkings <= 120) labelFrequency = 10;
  else if (initialMarkings <= 300) labelFrequency = 30;
  else if (initialMarkings <= 1000) labelFrequency = 50;
  else labelFrequency = 100;
  if (lock === false) {
    setZoomLevel(labelFrequency);
  }

  for (let i = min; i <= initialMarkings; i++) {
    const isLabeled = i % zoomLevel === 0;
    const leftPosition = `${
      ((i - offset) / (initialMarkings - offset)) * 100
    }%`;
    if (initialMarkings > 300 && i % 2 !== 0) {
      continue; // Skip non-labeled markings when initialMarkings > 300
    }
    if (initialMarkings > 700 && i % 5 !== 0) {
      continue; // Skip non-labeled markings when initialMarkings > 300
    }
    if (initialMarkings > 2000 && i % 10 !== 0) {
      continue; // Skip non-labeled markings when initialMarkings > 300
    }
    if (lock === true) {
      if (initialMarkings > 0 && initialMarkings < 3600 && i % 1 !== 0) {
        continue; // Skip non-labeled markings when initialMarkings > 300
      }
    }
    if (isLabeled && i !== lastLabeledValue) {
      markings.push(
        <div
          key={`label-${i}`}
          style={{
            ...labelStyle,
            left: leftPosition,
          }}
        >
          {i}
        </div>
      );
      lastLabeledValue = i; // Update the last labeled value to avoid duplicates
    }

    markings.push(
      <div
        key={i}
        style={{
          ...markingStyle,
          left: leftPosition,
          height: isLabeled ? "15px" : "10px",
        }}
      />
    );
  }

  useEffect(() => {
    $("#markerScale").slider();
    const setWidth = () => {
      positionSettings();
      audioPositionSettings();
    };

    setWidth();
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMarkings]);

  return <div id="markerScale">{markings}</div>;
};

export default Marker;
