import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { BsPlusSquareFill } from 'react-icons/bs';
import { AiOutlineEdit } from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import SelectSpeakerVoiceModal from './SelectSpeakerVoiceModal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import loading from '../../../multimedia/loading.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectSpeakerPic from './SelectSpeakerPic';
import CustomVideoPlayer from './CustomVideoPlayer';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Image from 'react-bootstrap/Image';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ReactGA from 'react-ga';
import useProjectIdFromUrl from '../../../utils/UseProjectIdFromUrl';

export default function TranscendSpeakers({
  setSelectedTranscendComponent,
  projectId,
  setProjectId,
  languageId,
  videoUrl,
  selectedLangArray,
  voice1,
  voicesArray,
  setSpeakers,
  speakers,
}) {
  const navigate = useNavigate();
  // const location = useLocation();
  const apiURL = process.env.REACT_APP_API_URL;
  const [speakerCount, setSpeakerCount] = useState(1);
  // voice needs to be removed and voiceId has to be changed from null
  const [selectVoice, setSelectVoice] = useState(voice1);
  const [activeSpeakerIndex, setActiveSpeakerIndex] = useState(null);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [show, setShow] = useState(false);
  const [showDp, setShowDp] = useState(false);
  const [flag, setFlag] = useState(false);
  const [addClicked, setAddClicked] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [speakerId, setSpeakerId] = useState('');
  const [speakerVoiceId, setSpeakerVoiceId] = useState('');
  const [speaker_Name, setSpeaker_Name] = useState('');
  const [, setFaceList] = useState([]);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [, setZoomLevel] = useState(100);
  const [seekTime] = useState({ value: 0 });
  const [currentTime, setCurrentTime] = useState(0);
  const [loopTranscript, setLoopTranscript] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [data] = useState([]);
  const [, setCIndex] = useState(0);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/spectral-transcend/speakers');
    ReactGA.set({ page: '/spectral-transcend/speakers' });
  }, []);


  useProjectIdFromUrl(projectId, setProjectId)
  
  const handleClose = () => setShow(false);
  const handleCloseDp = () => setShowDp(false);
  const handleShow = (index) => {
    setActiveSpeakerIndex(index);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'speakers/select_speaker',
    });
    setShow(true);
  };
  const handleShowDp = (index, id, voice_id, name) => {
    // console.log("speaker Id is ,",id,name,voice_id);
    setSpeakerId(id);
    setSpeakerVoiceId(voice_id);
    setSpeaker_Name(name);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'speakers/select_speaker_image',
    });
    setShowDp(true);
  };

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  function handleLoop() {
    setLoopTranscript(!loopTranscript);
    const currentIndex = data.findIndex((transcript, index) => {
      if (currentTime < data[0].StartTime) {
        return true;
      } else if (
        currentTime >= transcript.StartTime &&
        currentTime <= transcript.EndTime
      ) {
        return true;
      } else if (
        index < data.length - 1 &&
        currentTime > transcript.EndTime &&
        currentTime < data[index + 1].StartTime
      ) {
        return true;
      }
      return false;
    });
    setCIndex(currentIndex);
  }

  // get all speakers function which is called as soon as component is mounted

  function getSpeakers() {
    const req = {
      ProjectId: projectId,
    };

    fetch(`${apiURL}/web/get_project_speaker`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.IsValid) {
          if (res.SpeakerList.length > 0) {
            setSpeakers(res.SpeakerList);
          }
        }
      });
  }

  useEffect(() => {
    getSpeakers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (index, newName) => {
    const updatedSpeakers = [...speakers];
    updatedSpeakers[index].Name = newName;
    updatedSpeakers[index]['FaceIds'] = [];
    // api call to update speaker Name

    const request = {
      ProjectId: projectId,
      SpeakerList: [updatedSpeakers[index]],
    };

    fetch(`${apiURL}/web/update_project_speaker`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          getSpeakers();
        } else {
          console.error('Error:', data.Error);
          setNextClicked(false);
        }
      })
      .catch((error) => {
        console.error('API call failed:', error);
        setNextClicked(false);
      });
  };

  // fucntion for checking highest number in speaker.Name

  const findHighestNumber = (speakers) => {
    let highestNumber = 0;

    for (const speaker of speakers) {
      if (speaker.Name.startsWith('Speaker ')) {
        const number = parseInt(speaker.Name.split(' ')[1]);

        if (!isNaN(number) && number > highestNumber) {
          highestNumber = number;
        }
      }
    }

    return highestNumber;
  };
  // voice needs to be changed to voice id
  const handleAddSpeaker = () => {
    setAddClicked(true);
    const highestNumber = findHighestNumber(speakers);
    const newSpeaker = {
      Id: uuidv4(),
      Name:
        highestNumber > 0
          ? `Speaker ${highestNumber + 1}`
          : `Speaker ${speakers.length + 1}`,
      // VoiceId: voice1.Id,
      ProjectId: projectId,
      LangId: languageId,
      FaceIds: [],
    };

    // update speaker api call when new speaker is added

    const request = {
      ProjectId: projectId,
      SpeakerList: [newSpeaker],
    };

    fetch(`${apiURL}/web/update_project_speaker`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          // setFlag(true);
          setSpeakerCount(speakerCount + 1);
          setSpeakers([...speakers, data.SpeakerData[0]]);
          setAddClicked(false);
        } else {
          setAddClicked(false);
          console.error('Error:', data.Error);
          setNextClicked(false);
        }
      })
      .catch((error) => {
        setAddClicked(false);
        console.error('API call failed:', error);
        setNextClicked(false);
      });
  };

  // voice needs to be changed to voice id
  const handleVoiceChange = (newVoiceId) => {
    if (activeSpeakerIndex !== null) {
      const updatedSpeakers = [...speakers]; // Make a copy of the existing array
      updatedSpeakers[activeSpeakerIndex].VoiceId = newVoiceId;
      updatedSpeakers[activeSpeakerIndex]['FaceIds'] = [];
      // api call to update speaker voice

      const request = {
        ProjectId: projectId,
        SpeakerList: [updatedSpeakers[activeSpeakerIndex]],
      };

      fetch(`${apiURL}/web/update_project_speaker`, {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.IsValid === true) {
            getSpeakers();
          } else {
            console.error('Error:', data.Error);
            setNextClicked(false);
          }
        })
        .catch((error) => {
          console.error('API call failed:', error);
          setNextClicked(false);
        });
    }
    handleClose();
  };

  function transcendProcess() {
    let requestBody = {
      VideoUrl: videoUrl,
      //  "DubbingLanguageList": selectedLangArray,
      UserId: localStorage.getItem('userSessionId'),
      ProjectId: projectId,
      Type: 3,
    };

    fetch(`${apiURL}/dub/process`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          setProjectId(data.ProjectId);
          setSelectedTranscendComponent('transcribe');
          navigate(`/transcend/${projectId.toLowerCase()}/transcribe`);
        } else {
          console.error('Error:', data.Error);
        }
      })
      .catch((error) => {
        console.error('API call failed:', error);
      });
  }

  function handleNext() {
    // Check if all speakers have a valid VoiceId
    const areAllVoiceIdsValid = speakers.every(
      (speaker) =>
        speaker.VoiceId !== '' &&
        speaker.VoiceId !== undefined &&
        speaker.VoiceId !== null
    );
    const areAllNamesValid = speakers.every(
      (speaker) =>
        speaker.Name !== '' &&
        speaker.Name !== undefined &&
        speaker.Name !== null
    );

    const areAllNamesUnique = checkIfAllNamesUnique();

    if (areAllVoiceIdsValid && areAllNamesValid && areAllNamesUnique) {
      setNextClicked(true);
      const request = {
        ProjectId: projectId,
        SpeakerList: speakers.map((speaker) => ({
          ...speaker,
          FaceIds: [],
        })),
      };

      fetch(`${apiURL}/web/update_project_speaker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.IsValid === true) {
            setFlag(true);
            transcendProcess();
          } else {
            console.error('Error:', data.Error);
            setNextClicked(false);
          }
        })
        .catch((error) => {
          console.error('API call failed:', error);
          setNextClicked(false);
        });
    } else {
      if (!areAllVoiceIdsValid) {
        if (speakers.length === 1) {
          notify('* Please select voice for speaker');
        } else {
          notify('* Please select voice for all speakers');
        }
      }
      if (!areAllNamesValid) {
        if (speakers.length === 1) {
          notify('* Please add name for speaker');
        } else {
          notify('* Please add name for all speakers');
        }
      }
      if (!areAllNamesUnique) {
        notify('* Please ensure all names are unique');
      }
    }
  }

  // fucntion to check unique names
  function checkIfAllNamesUnique() {
    const names = speakers.map((speaker) => speaker.Name);
    const uniqueNames = new Set(names);
    return uniqueNames.size === names.length;
  }

  // function for Delete Speaker api call

  function deleteSpeaker(id) {
    const reqBody = {
      ProjectSpeakerId: id,
    };

    if (speakers.length > 1) {
      setDeleteClicked(true);
      fetch(`${apiURL}/web/delete_project_speaker`, {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(reqBody),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.IsValid === true) {
            setDeleteClicked(false);
            getSpeakers();
          } else {
            setDeleteClicked(false);
            console.error('Error:', data.Error);
          }
        })
        .catch((error) => {
          setDeleteClicked(false);
          console.error('API call failed:', error);
        });
    } else {
      setDeleteClicked(false);
      notify('* Atleast one speaker is mandatory');
    }
  }

  return (
    // <div className='syncPage'>
    <div style={{ padding: '1%' }}>
      <div className="transcendPage2">
        <h5 style={{ textAlign: 'center', marginBottom: '30px' }}>
          Speaker Settings
        </h5>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1%',
          }}
        >
          <div
            style={{
              width: '49.5%',
              maxWidth: '49.5%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CustomVideoPlayer
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              videoRef={videoRef}
              videoUrl={videoUrl}
              currentTime={currentTime}
              setZoomLevel={setZoomLevel}
              setCurrentTime={setCurrentTime}
              seekTime={seekTime}
              handleLoop={handleLoop}
              loopTranscript={loopTranscript}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>

          <div
            style={{
              width: '49.5%',
              maxWidth: '49.5%',
            }}
          >
            <Row>
              <Col md={6} sm={6}>
                <h5 style={{ textAlign: 'center' }}>Speaker :</h5>
              </Col>
              <Col md={6} sm={6}>
                <h5 style={{ textAlign: 'center' }}>Select Voice :</h5>
              </Col>
            </Row>

            <Container className="scrollList">
              {speakers.map((speaker, index) => (
                <Row
                  key={speaker.Id}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#b6b7b975' : '#eeeef0',
                    padding: '10px 0',
                    display: 'flex',
                    alignItems: 'center', // Align contents vertically center
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    height: '10vh',
                  }}
                >
                  <Col md={6} sm={6}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span>
                        {' '}
                        {speaker.FaceUrl ? (
                          <Image
                            src={speaker.FaceUrl}
                            roundedCircle
                            style={{
                              height: '50px',
                              width: '50px',
                              marginRight: '20px',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              handleShowDp(
                                index,
                                speaker.Id,
                                speaker.VoiceId,
                                speaker.Name
                              );
                            }}
                          />
                        ) : (
                          <Button
                            variant="text"
                            style={{
                              fontSize: '30px',
                              marginRight: '30px',
                              height: '60px',
                              padding: 0,
                            }}
                            onClick={(e) => {
                              handleShowDp(
                                index,
                                speaker.Id,
                                speaker.VoiceId,
                                speaker.Name
                              );
                            }}
                          >
                            <FaUserCircle />
                          </Button>
                        )}
                      </span>
                      <Form.Control
                        type="text"
                        style={{ width: '300px', border: '4px solid white' }}
                        className="speakerName"
                        defaultValue={speaker.Name}
                        onBlur={(e) => {
                          handleNameChange(index, e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6} sm={6}>
                    <div
                      className=""
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <InputGroup
                        style={{ width: '300px' }}
                        className="speakerVoiceInput"
                      >
                        <Form.Control
                          className="speakerVoice"
                          style={{ border: '1px solid white' }}
                          readOnly
                          value={
                            (
                              voicesArray.find(
                                (voice) => voice.Id === speaker.VoiceId
                              ) || {}
                            ).Name || speaker.VoiceId
                          }
                          placeholder="select voice"
                          onClick={() => handleShow(index)}
                        />
                        <Button
                          onClick={() => handleShow(index)}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: 'none',
                          }}
                        >
                          <AiOutlineEdit />
                        </Button>
                      </InputGroup>
                      {deleteClicked === false ? (
                        <Button
                          variant="text"
                          className="ms-3"
                          style={{ fontSize: '20px' }}
                          onClick={(e) => deleteSpeaker(speaker.Id)}
                        >
                          <RiDeleteBin6Line />
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          className="ms-3"
                          style={{ fontSize: '20px', border: 'none' }}
                          disabled
                        >
                          <RiDeleteBin6Line />
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              ))}

              {/* ----------------- */}
              <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{ textAlign: 'center' }}>
                    Select Speaker
                  </Modal.Title>
                </Modal.Header>

                {/* --------------------------------------------------- */}

                <SelectSpeakerVoiceModal
                  selectVoice={selectVoice}
                  setSelectVoice={setSelectVoice}
                  handleClose={handleClose}
                />

                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: 'rgb(0 151 254)',
                      color: 'white',
                    }}
                    onClick={(e) => {
                      handleVoiceChange(selectVoice.Id);
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                size="lg"
                show={showDp}
                onHide={handleCloseDp}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{ textAlign: 'center' }}>
                    Select speaker image
                  </Modal.Title>
                </Modal.Header>

                <SelectSpeakerPic
                  handleCloseDp={handleCloseDp}
                  projectId={projectId}
                  speakerId={speakerId}
                  getSpeakers={getSpeakers}
                  speakerVoiceId={speakerVoiceId}
                  speaker_Name={speaker_Name}
                  setFaceList={setFaceList}
                  languageId={languageId}
                />
              </Modal>
            </Container>

            <Row className="addSpeakerRow mt-2">
              <Col md={12} sm={12}>
                <Container
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {addClicked === false ? (
                    <Button variant="text" onClick={handleAddSpeaker}>
                      <span
                        style={{
                          fontSize: '20px',
                          color: 'black',
                          marginRight: '10px',
                        }}
                      >
                        <BsPlusSquareFill />
                      </span>
                      <span style={{ fontWeight: '500', cursor: 'pointer' }}>
                        Add New Speaker
                      </span>
                    </Button>
                  ) : (
                    <Button variant="text" disabled>
                      <span
                        style={{
                          fontSize: '20px',
                          color: 'black',
                          marginRight: '10px',
                        }}
                      >
                        <BsPlusSquareFill />
                      </span>
                      <span style={{ fontWeight: '500', cursor: 'pointer' }}>
                        Add New Speaker
                      </span>
                    </Button>
                  )}
                </Container>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="nxtBtnContainer">
        {flag === false ? (
          <button
            className="nxtBtn"
            onClick={(e) => {
              handleNext();
            }}
          >
            Next
          </button>
        ) : flag === true && nextClicked === true ? (
          <div>
            {' '}
            <button className="nxtBtn" disabled>
              Next
            </button>{' '}
            <span>
              <img
                style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                src={loading}
                alt="loading"
              />
            </span>{' '}
          </div>
        ) : (
          <button className="nxtBtn" disabled>
            Next
          </button>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
