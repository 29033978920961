import React, { useEffect, useState, useRef } from "react";
import "./mediafiles.css";
import ToolBar from "../VideoFiles/ToolBar";
import Marker from "../VideoFiles/Marker";

import { Col, Row } from "react-bootstrap";
import Audio from "./Audio";
import Video from "./Video";
import { FaVolumeUp, FaVideo } from "react-icons/fa";

export default function MediaFiles({
  durationInSeconds,
  selectedVideoFile,
  setSelectedVideoFile,
  selectedAudioFile,
  setSelectedAudioFile,
}) {
  const [initialMarkings, setInitialMarkings] = useState(120);
  const [totalAudioDuration, setTotalAudioDuration] = useState(0);
  const [min, setMin] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [lock, setLock] = useState(false);
  const max = 3600;

  // Video.js states
  const fileInputRef = useRef(null);
  const [xPosition, setXPosition] = useState(0);
  const [reqMarking, setReqMarking] = useState(0);
  const [videoScrollPosition, setVideoScrollPosition] = useState(0);

  // Video.js functions

  function positionSettings() {
    const markingsDiv = document.getElementById("markerScale");
    if (markingsDiv) {
      console.log("initialMarkings for video.js ", initialMarkings);
      const scaleWidth = markingsDiv.clientWidth;
      const markingInterval = scaleWidth / initialMarkings;
      setXPosition(reqMarking * markingInterval);
    }
    // setPosition({x:xPosition,y:2});
  }

  //Audio.js states
  const audioFileInputRef = useRef(null);
  const [xCoordinate, setXCoordinate] = useState([]);
  const [reqAudioMarking, setReqAudioMarking] = useState([]);

  // Audio.js functions

  function audioPositionSettings() {
    const markingsDiv = document.getElementById("markerScale");
    if (markingsDiv) {
      const scaleWidth = markingsDiv.clientWidth;
      const markingInterval = scaleWidth / initialMarkings;

      // Calculate xCoordinates based on reqAudioMarkings
      const updatedXCoordinates = reqAudioMarking.map(
        (marking) => marking * markingInterval
      );
      setXCoordinate(updatedXCoordinates);

      // Assuming you have a way to calculate reqAudioMarkings, update it here
      // const updatedReqAudioMarkings = xCoordinate.map(
      //   (xCoord) => xCoord / markingInterval
      // );
      // setReqAudioMarking(updatedReqAudioMarkings);
    }
  }
  // function setOverFlow() {
  //   var videoDiv = document.getElementById("video");
  //   var audioDiv = document.getElementById("audio");

  //   // Set the overflow property to "hidden" for both divs
  //   if (videoDiv) {
  //     videoDiv.style.overflowX = "hidden";
  //   }

  //   if (audioDiv) {
  //     audioDiv.style.overflowX = "hidden";
  //   }
  // }

  const zoomOut = () => {
    setLock(false);
    if (initialMarkings < max) {
      if (initialMarkings < 60) {
        if (initialMarkings % 5 === 0) {
          setInitialMarkings(initialMarkings + 5);
        }
      } else if (initialMarkings < 120) {
        if (initialMarkings % 10 === 0) {
          setInitialMarkings(initialMarkings + 10);
        }
      } else if (initialMarkings < 300) {
        if (initialMarkings % 30 === 0) {
          setInitialMarkings(initialMarkings + 30);
        }
      } else if (initialMarkings >= 300 && initialMarkings % 100 === 0) {
        setInitialMarkings(initialMarkings + 100);
      } else {
        // Default zoom out step for large values
        setInitialMarkings(initialMarkings + 100);
      }

      console.log("on zoom out", initialMarkings);
    }
    console.log("after zoom out initial markings are", initialMarkings);
    positionSettings();
    audioPositionSettings();
  };

  const zoomIn = () => {
    setLock(false);
    if (initialMarkings > 25) {
      if (initialMarkings <= 60 && initialMarkings % 5 === 0) {
        setInitialMarkings(initialMarkings - 5);
      } else if (initialMarkings <= 120 && initialMarkings % 10 === 0) {
        setInitialMarkings(initialMarkings - 10);
      } else if (initialMarkings <= 300 && initialMarkings % 30 === 0) {
        setInitialMarkings(initialMarkings - 30);
      } else if (initialMarkings > 300 && initialMarkings % 100 === 0) {
        setInitialMarkings(initialMarkings - 100);
      } else {
        // Default zoom in step for large values
        setInitialMarkings(initialMarkings - 100);
      }
    }

    positionSettings();
    audioPositionSettings();
  };

  useEffect(() => {
    console.log("total audio duration is", totalAudioDuration);
    console.log("vf d is", selectedVideoFile.duration);
    if (totalAudioDuration > 120 || selectedVideoFile.duration > 120) {
      // Calculate adjusted markings without directly updating state
      if (
        totalAudioDuration > selectedVideoFile.duration ||
        selectedVideoFile.duration === undefined
      ) {
        console.log("yes");
        const adjustedMarkings = Math.ceil(totalAudioDuration / 100) * 100;
        setInitialMarkings(adjustedMarkings);
      } else {
        const adjustedMarkings =
          Math.ceil(selectedVideoFile.duration / 100) * 100;
        setInitialMarkings(adjustedMarkings);
      }

      console.log("initialMarkings adjusted based on total audio duration");
    } else {
      // Reset initialMarkings to the default value
      setInitialMarkings(120);
    }
  }, [totalAudioDuration, selectedVideoFile.duration]);

  // Video.js upload function

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleVideoFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const videoElement = document.createElement("video");
      videoElement.onloadedmetadata = () => {
        const durationInSeconds = Math.floor(videoElement.duration);
        files[0].duration = durationInSeconds; // Update the duration in the correct format
        console.log("Calculated duration (seconds):", durationInSeconds);
        setSelectedVideoFile({
          name: files[0].name,
          duration: durationInSeconds,
        });
      };
      videoElement.src = URL.createObjectURL(files[0]);
    }
    console.log("file is ", files);
  };
  const handleAudioUploadButtonClick = () => {
    audioFileInputRef.current.click();
  };

  const handleAudioFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const audioElement = document.createElement("audio");
      audioElement.onloadedmetadata = () => {
        const durationInSeconds = Math.floor(audioElement.duration);
        files[0].duration = durationInSeconds; // Update the duration in the correct format
        console.log("Calculated duration (seconds):", durationInSeconds);
        setSelectedAudioFile({
          name: files[0].name,
          duration: durationInSeconds,
        });
      };
      audioElement.src = URL.createObjectURL(files[0]);
    }
    console.log("file is ", files);
  };
  return (
    <div style={{ width: "95%" }}>
      <Row>
        <Col md={12}>
          <ToolBar
            zoomOut={zoomOut}
            zoomIn={zoomIn}
            initialMarkings={initialMarkings}
            setInitialMarkings={setInitialMarkings}
            lock={lock}
            setLock={setLock}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div>
            {/* <div style={{marginLeft:'50px'}} > */}
            <Row>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "2.5%" }}></div>
                <Marker
                  initialMarkings={initialMarkings}
                  setInitialMarkings={setInitialMarkings}
                  audioPositionSettings={audioPositionSettings}
                  positionSettings={positionSettings}
                  min={min}
                  setMin={setMin}
                  videoScrollPosition={videoScrollPosition}
                  setVideoScrollPosition={setVideoScrollPosition}
                  zoomLevel={zoomLevel}
                  setZoomLevel={setZoomLevel}
                  lock={lock}
                  setLock={setLock}
                />
              </div>
            </Row>
            <div
              className="mt-1"
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <button
                  className="audioTrackBtn"
                  onClick={handleAudioUploadButtonClick}
                >
                  <FaVolumeUp />
                </button>
                <input
                  type="file"
                  accept=".mp3"
                  ref={audioFileInputRef}
                  style={{ display: "none" }}
                  onChange={handleAudioFileChange}
                />
                <Audio
                  initialMarkings={initialMarkings}
                  durationInSeconds={durationInSeconds}
                  selectedAudioFile={selectedAudioFile}
                  setSelectedAudioFile={setSelectedAudioFile}
                  xCoordinate={xCoordinate}
                  setXCoordinate={setXCoordinate}
                  audioPositionSettings={audioPositionSettings}
                  reqAudioMarking={reqAudioMarking}
                  setReqAudioMarking={setReqAudioMarking}
                  setTotalAudioDuration={setTotalAudioDuration}
                  totalAudioDuration={totalAudioDuration}
                  setInitialMarkings={setInitialMarkings}
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  max={max}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                }}
              >
                <button
                  className="videoTrackBtn"
                  id="addVidBtn"
                  onClick={handleUploadButtonClick}
                >
                  <FaVideo />
                </button>
                <input
                  type="file"
                  accept=".mp4"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleVideoFileChange}
                />
                <Video
                  initialMarkings={initialMarkings}
                  setInitialMarkings={setInitialMarkings}
                  selectedVideoFile={selectedVideoFile}
                  setSelectedVideoFile={setSelectedVideoFile}
                  xPosition={xPosition}
                  setXPosition={setXPosition}
                  reqMarking={reqMarking}
                  setReqMarking={setReqMarking}
                  positionSettings={positionSettings}
                  min={min}
                  setMin={setMin}
                  videoScrollPosition={videoScrollPosition}
                  setVideoScrollPosition={setVideoScrollPosition}
                  zoomLevel={zoomLevel}
                  setZoomLevel={setZoomLevel}
                  lock={lock}
                  setLock={setLock}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
