import React, { useEffect, useState } from "react";
import TranslateCreate from "./TranslateCreate";
import TranslateResult from "./TranslateResult";
import "./spectralTranslate.css";
import TranslateHome from "./TranslateHome";
import { Routes, Route, useLocation } from "react-router-dom";

export default function SprectralTranslate({
  setSearchText,
  searchText,
  setUserInfo,
  selectedTranslateComponent,
  setSelectedTranslateComponent,
  menuLock,
  setMenuLock,
  projectName,
  setProjectName,
}) {
  const [projectData, setProjectData] = useState({
    ProjectId: "",
    ProjectName: "",
    VideoUrl: "",
  });
  const [projectId, setProjectId] = useState("");
  // const projectIdLc = projectId.toLowerCase();
  const [dubbedData, setDubbedData] = useState([]);
  const [processingData, setProcessingData] = useState([]);
  const [render, setRender] = useState(true);
  // const apiURL = process.env.REACT_APP_API_URL;
  const [pName, setPName] = useState("");

  const location = useLocation();

  useEffect(() => {
    document.title = "Translate - Spectral Studio";
    setSearchText("");
    // fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTranslateComponent !== "tHome") {
      setMenuLock(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTranslateComponent]);

  useEffect(() => {
    const isTranslateHome = location.pathname === "/translate";
    if (isTranslateHome) {
      setMenuLock(false);
    } else {
      setMenuLock(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const commonProps = {
    searchText,
    setSelectedTranslateComponent,
    projectId,
    setProjectId,
    projectData,
    setProjectData,
    dubbedData,
    setDubbedData,
    menuLock,
    setMenuLock,
    pName,
    setPName,
    processingData,
    setProcessingData,
    projectName,
    setProjectName,
  };

  return (
    <div className="spectralTranslate">
      {(location.pathname === "/translate" ||
        location.pathname === "/translate/home") && (
        <h2 className="mt-4 ms-5">Spectral Translate</h2>
      )}
      {(location.pathname === "/translate" ||
        location.pathname === "/translate/home") && (
        <span className="mt-2 ms-5" style={{ color: "grey" }}>
          Fully autonomous, one-click localization.
        </span>
      )}
      <Routes>
        <Route path="/" element={<TranslateHome {...commonProps} />} />
        <Route path="home" element={<TranslateHome {...commonProps} />} />
        <Route
          path="create"
          element={
            <TranslateCreate {...commonProps} setUserInfo={setUserInfo} />
          }
        />
        <Route
          path=":projectIdLc/result"
          element={
            <TranslateResult
              {...commonProps}
              selectedTranslateComponent={selectedTranslateComponent}
              key={render}
              render={render}
              setRender={setRender}
            />
          }
        />
      </Routes>
    </div>
  );
}
