import React, { useEffect, useRef, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js';
import Spinner from 'react-bootstrap/Spinner';
import './spectralTranscend.css';

export default function Timeline({
  data,
  setData,
  tempData,
  setTempData,
  newlyAddedId,
  videoRef,
  isPlaying,
  
  zoomLevel,
  setZoomLevel,
  setSeekTime,
  setScrollIndex,
  updateSingleTranscription,
  setRegionChange,
 
  peaks,
  waveDuration,
  reqIdToRemove,
  
  regionRemoveState,
  
  regionClicked,
 
  newTranscriptData,
  newlyAddedTranscript,
 
  dataRef,
}) {
  const wsRegionsRef = useRef(null);
  const currentPositionRef = useRef(0);
  // const dataRef = useRef(data);

  const [activeRegion, setActiveRegion] = useState(null);
  const [wavesurferTemp, setWaveSurferTemp] = useState(null);
  const [timelineReady, setTimelineReady] = useState(false);
  const [, setUpadateRegionIndex] = useState();
  // const [tempRegionText, setTempRegionText] = useState('');

  useEffect(() => {
    dataRef.current = data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // console.log(reqIdToRemove);
    removeRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionRemoveState]);

  const removeRegion = useCallback(() => {
    if (reqIdToRemove && wsRegionsRef.current) {
      const regionToRemove = wsRegionsRef.current.regions.find(
        (region) => region.data.Id === reqIdToRemove
      );
      // console.log(reqIdToRemove);

      if (regionToRemove) {
        regionToRemove.remove();
        // setData((prevData) =>
        //   prevData.filter((item) => item.Id !== reqIdToRemove)
        // );
      } else {
        console.log('not received');
      }
    }
  }, [reqIdToRemove]);

  // useEffect(() => {
  //   console.log(data);
  //   setTempData(data);
  // }, [data]);

  // const updateArrayWithNewObject = (newObj) => {
  //   // Find the index where newObj should be inserted
  //   const index = data.findIndex((item) => item.StartTime > newObj.StartTime);

  //   // If index is -1, it means newObj should be added to the end
  //   const updatedData =
  //     index === -1
  //       ? [...data, newObj] // Append to the end if index is not found
  //       : [
  //           ...data.slice(0, index), // Elements before the insertion index
  //           newObj, // Insert the new object
  //           ...data.slice(index), // Elements after the insertion index
  //         ];

  //   // Update the state with the newly created array
  //   setData(updatedData);
  //   // setTempData(updatedData)
  // };

  useEffect(() => {
    if (wsRegionsRef.current && newlyAddedTranscript) {
      // Only add region if it doesn't already exist
      const existingRegion = wsRegionsRef.current
        .getRegions()
        .find((region) => region.id === newlyAddedTranscript.Id);
      if (!existingRegion) {
        addRegion(newlyAddedTranscript);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyAddedTranscript]);

  function addRegion(item) {
    const contentDiv = document.createElement('div');
    contentDiv.textContent = item.Text;

    const region = wsRegionsRef.current.addRegion({
      start: item.StartTime,
      end: item.EndTime,
      color: '#ebedf0',
      content: contentDiv,
      resize: true,
      drag: true,
      id: item.Id,
    });

    region.data = { ...item };

    // setRegionClicked(item.Id)
    triggerRegionClick2(item.Id);

    // console.log(data);
  }
  useEffect(() => {
    if (wsRegionsRef.current && newTranscriptData[0]) {
      // console.log(newTranscriptData);
      updateRegion(newTranscriptData[0]);
      // console.log(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTranscriptData]);

  const updateRegion = (item) => {
    const contentDiv = document.createElement('div');
    contentDiv.textContent = item.Text;
    // console.log(updatingIndex);
    // const region = wsRegionsRef.current.regions[updatingIndex];
    const region = wsRegionsRef.current.regions.find(
      (region) => region.data.Id === item.Id
    );

    if (region) {
      region.setOptions({
        start: item.StartTime,
        end: item.EndTime,
        color: '#ebedf0',
        content: contentDiv,
        resize: true,
        drag: true,
        id: item.Id,
      });

      region.data = { ...item };
    }
    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.Id === item.Id ? { ...dataItem, ...item } : dataItem
      )
    );

    // console.log(region);
  };

  useEffect(() => {
    if (wsRegionsRef.current !== null) {
      // Listen for region-clicked event
      wsRegionsRef.current.on('region-clicked', handleRegionClick);
    }

    return () => {
      // Clean up event listener
      if (wsRegionsRef.current !== null) {
        wsRegionsRef.current.un('region-clicked', handleRegionClick);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRegion, data, setScrollIndex, setSeekTime, wavesurferTemp]);

  useEffect(() => {
    const triggerRegionClick = (regionId) => {
      if (wsRegionsRef.current && regionId) {
        const region = wsRegionsRef.current.regions.find(
          (region) => region.id === regionId
        );
        if (region) {
          // Simulate a click event
          const event = new MouseEvent('click', { bubbles: true });
          region.element.dispatchEvent(event);
        }
      }
    };

    triggerRegionClick(regionClicked);
  }, [regionClicked]);

  const triggerRegionClick = (regionId) => {
    // setActiveRegion(null);
    if (wsRegionsRef.current && regionId) {
      const region = wsRegionsRef.current.regions.find(
        (region) => region.id === regionId
      );
      if (region) {
        // Simulate a click event
        const event = new MouseEvent('click', { bubbles: true });
        region.element.dispatchEvent(event);
        setActiveRegion(region);
      }
    }
  };
  const triggerRegionClick2 = (regionId) => {
    // setActiveRegion(null);

    if (activeRegion && activeRegion.element) {
      activeRegion.element.style.outline = 'none';
      activeRegion.element.style.zIndex = 'auto';
    }

    if (wsRegionsRef.current && regionId) {
      const region = wsRegionsRef.current.regions.find(
        (region) => region.id === regionId
      );
      if (region) {
        // Simulate a click event
        const event = new MouseEvent('click', { bubbles: true });
        region.element.dispatchEvent(event);
        // setActiveRegion(region);
      }
    }
  };

  const handleRegionClick = (region, e) => {
    // setActiveRegion(null)
    e.stopPropagation();
    // console.log(region);

    // Remove styles from previously active region
    if (activeRegion && activeRegion.element) {
      activeRegion.element.style.outline = 'none';
      activeRegion.element.style.zIndex = 'auto';
    }

    // Apply styles to the clicked region
    setActiveRegion(region);
    if (region && region.element) {
      region.element.style.outline = '3px solid black';
      region.element.style.zIndex = '1000';
    }

    // Other actions you want to perform when a region is clicked
    setSeekTime({ value: region.start });
    wavesurferTemp.setTime(region.start);
    const index = data.findIndex((item) => item.Id === region.data.Id);
    if (index !== -1) {
      setScrollIndex(index);
      console.log(index)
    }
  };

  const formatTimeCallback = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const milliseconds = Math.floor((seconds % 1) * 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}.${milliseconds
      .toString()
      .padStart(3, '0')
      .slice(0, 2)}`;
  };

  const handleRegionUpdate = (region) => {
    // console.log('hii');

    // console.log(region);
    const contentDiv = document.createElement('div');
    contentDiv.textContent = region.data.Text;
    const duration = region.end - region.start;
    // Find the index of the region in the data array
    // const index = data.findIndex((item) => item.Id === region.data.Id);
    const regionIndex = findWaveSurferRegionIndex(region.id);
    // console.log(index, regionIndex);
    // console.log(dataRef.current);

    const regionId = region.data.Id;
    const matchingObject = dataRef.current.find((item) => item.Id === regionId);

    setUpadateRegionIndex(region.data.Id);

    // console.log(regionIndex, newTranscriptData[0].Text);

    if (regionIndex !== -1) {
      // Update the start and end times in the data array
      const arr = [matchingObject];
      const temp = arr.map((item) => ({
        ...item,
        StartTime: region.start,
        EndTime: region.end,
        Duration: duration,
        Text: region.data.Text,
      }));

      updateSingleTranscription(temp, region.data.Id);
      setRegionChange(true);
    }
    // setActiveRegion(index)
  };

  const findWaveSurferRegionIndex = (regionId) => {
    if (wsRegionsRef.current) {
      // Convert the regions object into an array and find the index based on the region ID
      const regionArray = Object.values(wsRegionsRef.current.regions);
      return regionArray.findIndex((region) => region.id === regionId);
    }
    return -1; // Return -1 if the region is not found
  };

  useEffect(() => {
    if (wavesurferTemp) {
      currentPositionRef.current =
        wavesurferTemp.getCurrentTime() / wavesurferTemp.getDuration();
    }
    const wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'lightgrey',
      progressColor: 'grey',
      media: videoRef.current,
      height: 120,
      barAlign: 'bottom',
      barHeight: 1,
      barWidth: 2,
      hideScrollbar: false,
      minPxPerSec: zoomLevel,
      fillParent: true,
      autoScroll: true,
      peaks: peaks,
      // normalize:true,
      duration: waveDuration,
      plugins: [
        Hover.create({
          lineColor: '#ff0000',
          formatTimeCallback: formatTimeCallback,
          lineWidth: 2,
          labelBackground: '#555',
          labelColor: '#fff',
          labelSize: '11px',
        }),
      ],
    });

    wavesurfer.registerPlugin(
      TimelinePlugin.create({
        height: 20,
        insertPosition: 'beforebegin',
        timeInterval: 0.2,
        primaryLabelInterval: 1,
        secondaryLabelInterval: 1,
        style: {
          fontSize: '12px',
          color: 'grey',
        },
      })
    );

    wavesurfer.once('interaction', () => {
      // setActiveRegion(null);
      if (activeRegion && activeRegion.element) {
        activeRegion.element.style.outline = 'none';
        activeRegion.element.style.zIndex = 'auto';
      }
    });

    /** When the audio has been decoded */
    wavesurfer.on('decode', (duration) => {
      if (currentPositionRef.current > 0) {
        wavesurfer.seekTo(currentPositionRef.current);
      }
      setTimelineReady(true);

      wsRegionsRef.current = wavesurfer.registerPlugin(RegionsPlugin.create());

      // Give regions a random color when they are created

      data.forEach((item, index) => {
        const contentDiv = document.createElement('div');
        contentDiv.textContent = item.Text;

        const region = wsRegionsRef.current.addRegion({
          start: item.StartTime,
          end: item.EndTime,
          color: '#ebedf0',
          content: contentDiv,
          // minLength: 1,
          // maxLength: 10,
          resize: true,
          drag: true,
          id: item.Id,
        });

        region.data = { ...item };
        // triggerRegionClick(region.id);
      });

      wsRegionsRef.current.on(
        'region-updated',
        handleRegionUpdate,

        (region) => {}
      );

      // Loop a region on click
      let loop = false;

      //   let activeRegion = null;
      wsRegionsRef.current.on('region-in', (region) => {
        // console.log('region-in', region)
        // activeRegion = region
        setActiveRegion(region);
      });
      wsRegionsRef.current.on('region-out', (region) => {
        // console.log('region-out', region)
        if (activeRegion === region) {
          if (loop) {
            // setIsPlaying(true);
            // region.play()
          } else {
            //   activeRegion = null
            setActiveRegion(null);
          }
        }
      });
      wsRegionsRef.current.on('region-clicked', (region, e) => {
        // setActiveRegion(null)
        console.log(region, dataRef.current);
        e.stopPropagation();
        setActiveRegion(region);
        console.log(region)

        setSeekTime({ value: region.start });
        wavesurfer.setTime(region.start);
        const index = dataRef.current.findIndex(
          (item) => item.Id === region.data.Id
        );
        if (index !== -1) {
          setScrollIndex(index);
          console.log(index)
        }
      });
    });

    wavesurfer.once('decode', () => {
      if (newlyAddedId) {
        triggerRegionClick(newlyAddedId);
        setActiveRegion(newlyAddedId);
      }
      setWaveSurferTemp(wavesurfer);
      document.querySelector('#zoom').oninput = (e) => {
        const minPxPerSec = Number(e.target.value);
        wavesurfer.zoom(minPxPerSec);
      };
      setTimelineReady(true);
    });

    // Reset the active region when the user clicks anywhere in the waveform
    wavesurfer.on('interaction', () => {
      // setActiveRegion(null);
      if (activeRegion && activeRegion.element) {
        activeRegion.element.style.outline = 'none';
        activeRegion.element.style.zIndex = 'auto';
      }
    });

    // Clean up function
    return () => {
      wavesurfer.un('region-clicked', handleRegionClick);
      wavesurfer.un('decode');
      // wavesurfer.un('region-updated', handleRegionUpdate);
      wavesurfer.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peaks]);

  useEffect(() => {
    if (isPlaying) {
      if (wavesurferTemp) {
        wavesurferTemp.play();
      }
    } else {
      if (wavesurferTemp) {
        wavesurferTemp.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  return (
    <div className="mt-2" style={{ height: 'max-content' }}>
      {timelineReady === false ? (
        <p>
          Loading timeline
          <span className="ms-1">
            <Spinner size="sm" animation="border" variant="secondary" />
          </span>
        </p>
      ) : null}
      <div
        className="mb-2"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <span className="me-2">Zoom :</span>
        {timelineReady ? (
          <input
            type="range"
            min={100}
            max={1000}
            value={zoomLevel}
            id="zoom"
            onChange={(e) => setZoomLevel(e.target.value)}
            style={{ width: '150px' }}
          />
        ) : (
          <input
            type="range"
            min={100}
            max={500}
            value={zoomLevel}
            id="zoom"
            onChange={(e) => setZoomLevel(e.target.value)}
            style={{ width: '200px' }}
            disabled
          />
        )}
      </div>
      <div id="waveform" style={{ borderTop: '1px solid grey' }}></div>
    </div>
  );
}
