import React, { useEffect } from "react";
// import VoicePage1 from './VoicePage1';
// import { Button } from 'react-bootstrap';
// import { IoFilterSharp } from 'react-icons/io5';
// import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';

// import {

//   OverlayTrigger,
//   Tooltip,

// } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import { HiInformationCircle } from 'react-icons/hi2';

import ReactGA from "react-ga";
import "./spectralDRM.css";

export default function Avatar({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
}) {
  // const [activeTab, setActiveTab] = useState('textToSpeech');
  // const [activeTab2, setActiveTab2] = useState('settings');

  // const [voicesArray, setVoicesArray] = useState([]);
  // const apiURL = process.env.REACT_APP_API_URL;

  // const InformationCircleTooltip = ({ id, text }) => (
  //   <OverlayTrigger
  //     placement="top"
  //     overlay={
  //       <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
  //         {text}
  //       </Tooltip>
  //     }
  //   >
  //     <span
  //       style={{
  //         fontSize: '16px',
  //         marginTop: '12px',
  //         marginLeft: '6px',
  //         color: 'gray',
  //       }}
  //     >
  //       <HiInformationCircle />
  //     </span>
  //   </OverlayTrigger>
  // );

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/voice_lab");
    ReactGA.set({ page: "/voice_lab" });
  }, []);

  useEffect(() => {
    document.title = "Voice - Spectral Studio";
    // fetchUserInfo();
    // getVoices();
    // setSearchText('');
  }, []);
  //   useEffect(() => {
  //     // Update userInfo state whenever localStorage userInfo changes
  //     setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
  //   }, [localStorage.getItem('userInfo')]);

  return (
    <div className="digital-twin-container">
      <header>
        <h2 className="">Digital Twin</h2>
      </header>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
        className="dt-voice-content"
      >
        <span className="ms-1" style={{ fontSize: "14px" }}>
          Coming Soon
        </span>
      </div>
    </div>
  );
}
