import React, { useEffect, useRef, useState } from "react";
// import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button, Table } from "react-bootstrap";
import { FaUpload } from "react-icons/fa6";
import { BsInfoCircleFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { SiVerizon } from "react-icons/si";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Spinner from "react-bootstrap/Spinner";

export default function AddVoice({
  setVoice,
  voice,
  labels,
  setLabels,
  samples,
  setSamples,
  vName,
  setVName,
  description,
  sampleCount,
  setSampleCount,
  setDescription,
  uploadPercentage,
  flag,
  isChecked,
  setIsChecked,
  isEditing,
  sampleUrls,
  setSampleUrls,
}) {
  const [addLabel, setAddLabel] = useState(false);
  const [labelCount, setLabelCount] = useState(0);

  const inputRef = useRef(null);
  const inputValueRef = useRef(null);
  const [labelKey, setLabelKey] = useState("");
  const [labelValue, setLabelValue] = useState("");
  const labelColors = ["#fbf8c5", "#dce1e7", "#f4eaff", "#e6ebff", "#ffcc9959"];
  const fileInputRef = useRef(null);

  function deleteLabel(index) {
    // Create a copy of the labels array
    const updatedLabels = [...labels];
    // Remove the label at the specified index
    updatedLabels.splice(index, 1);
    // Update the labels state
    setLabels(updatedLabels);
    // Decrement the label count
    setLabelCount((prevCount) => prevCount - 1);
  }
  function deleteSample(index) {
    // Create a copy of the labels array
    const updatedSamples = [...samples];
    // Remove the label at the specified index
    updatedSamples.splice(index, 1);
    // Update the labels state
    setSamples(updatedSamples);
  }

  function deleteSampleUrl(id) {
    // Find the index of the element with the given id in the sampleUrls array
    const index = sampleUrls.findIndex((sample) => sample.Id === id);

    // If the element is found, update its status to 3
    if (index !== -1) {
      const updatedSampleUrls = [...sampleUrls];
      updatedSampleUrls[index].Status = 3;

      // Update the state with the modified sampleUrls array
      setSampleUrls(updatedSampleUrls);
      setSampleCount(sampleCount - 1);
    } else {
      console.error(`Sample with id ${id} not found.`);
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function handleUploadClick() {
    fileInputRef.current.click();
  }
  function addLabels() {
    const label = { key: labelKey, value: labelValue };

    // Check if the labelKey and labelValue are not empty
    if (labelKey.trim() !== "" && labelValue.trim() !== "") {
      // Check if the labelKey and labelValue pair does not already exist
      const isLabelUnique = !labels.some(
        (l) => l.key === labelKey && l.value === labelValue
      );

      if (isLabelUnique) {
        // Check if the label count is less than 5
        if (labelCount < 5) {
          // Add the new label to the labels state array
          setLabels((prevLabels) => [...prevLabels, label]);
          // Clear the labelKey and labelValue inputs
          setLabelKey("");
          setLabelValue("");
          inputRef.current.value = "";
          inputValueRef.current.value = "";
          // Increment the label count
          setLabelCount((prevCount) => prevCount + 1);
        } else {
          // Alert the user that the maximum label count is reached
          alert("Maximum label count reached (5 labels).");
        }
      } else {
        // Alert the user that the label already exists
        alert("Label already exists. Please enter a unique label.");
      }
    } else {
      // Alert the user if the key or value is empty
      alert("Label key and value cannot be empty.");
    }
  }

  // files upload handleFileChange function

  const handleFileChange = (e) => {
    const files = e.target.files;

    // Maintain a list of filenames to check for duplicates
    const existingFileNames = samples.map((file) => file.name);

    // Iterate through the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if the file has already been added
      if (existingFileNames.includes(file.name)) {
        alert(`File already added: ${file.name}`);
        continue;
      }

      // Check file size (max 10MB)
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > maxSize) {
        alert(`File size exceeds 10MB limit: ${file.name}`);
        continue;
      }

      // Get the file extension
      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      // Check file type (MP3, WAV)
      const allowedExtensions = ["mp3", "wav"];
      if (!allowedExtensions.includes(fileExtension)) {
        alert(`Unsupported file type: ${file.name}`);
        continue;
      }

      // Create a new File with a different name
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${file.name}`;

      const updatedFile = new File(
        [file.slice(0, file.size, file.type)],
        fileNameWithTimestamp,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );

      // Add the valid file to the selectedFiles state
      setSamples((prevFiles) => [...prevFiles, updatedFile]);

      // Add the filename to the existingFileNames list
      existingFileNames.push(updatedFile.name);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    // Maintain a list of filenames without timestamps to check for duplicates
    const existingFileNamesWithoutTimestamp = samples.map((file) =>
      file.name.replace(/^\d+_/, "")
    );

    // Iterate through the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Extract the filename without timestamp using a regular expression
      const filenameWithoutTimestamp = file.name.replace(/^\d+_/, "");

      // Check if the file has already been added
      if (
        existingFileNamesWithoutTimestamp.includes(filenameWithoutTimestamp)
      ) {
        alert(`File already added: ${file.name}`);
        continue;
      }

      // Check file size (max 10MB)
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > maxSize) {
        alert(`File size exceeds 10MB limit: ${file.name}`);
        continue;
      }

      // Get the file extension
      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      // Check file type (MP3, WAV)
      const allowedExtensions = ["mp3", "wav"];
      if (!allowedExtensions.includes(fileExtension)) {
        alert(`Unsupported file type: ${file.name}`);
        continue;
      }

      // Create a new File with a different name
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${file.name}`;

      const updatedFile = new File(
        [file.slice(0, file.size, file.type)],
        fileNameWithTimestamp,
        {
          type: file.type,
          lastModified: file.lastModified,
        }
      );
      // Add the valid file to the selectedFiles state
      setSamples((prevFiles) => [...prevFiles, updatedFile]);

      // Add the filename to the existingFileNames list
      existingFileNamesWithoutTimestamp.push(updatedFile.name);
    }
  };

  useEffect(() => {
    // Filter out sampleUrls with Status equal to 3
    if (isEditing) {
      const validSampleUrls = sampleUrls.filter(
        (sample) => sample.Status !== 3
      );

      if (validSampleUrls.length > 0) {
        setSampleCount(validSampleUrls.length + samples.length);
        setLabelCount(labels.length);
      }
    } else {
      setSampleCount(samples.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples, labels]);

  return (
    <div>
      <Form.Label style={{ fontWeight: 500 }}>Name</Form.Label>
      <Form.Control
        type="text"
        defaultValue={isEditing ? vName : undefined}
        onBlur={(e) => setVName(e.target.value)}
      />
      <div
        className="fileUpload"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => handleDrop(e)}
      >
        {flag === false ? (
          <div>
            <Button
              variant="text"
              style={{ fontSize: "50px", marginBottom: "10px" }}
              onClick={handleUploadClick}
            >
              <FaUpload />
            </Button>
            <p style={{ color: "black" }}>
              Drop your audio here, or click to browse
            </p>
            <p style={{ color: "grey", fontSize: "14px" }}>
              Audio files upto 10MB each
            </p>
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              accept=".mp3, .wav"
              multiple
              onChange={handleFileChange}
            />
          </div>
        ) : (
          <div>
            {uploadPercentage !== 100 ? (
              <div style={{ width: "150px", height: "150px" }}>
                <CircularProgressbar
                  value={uploadPercentage}
                  text={`${uploadPercentage}%`}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <span style={{ marginRight: "10px", fontWeight: 500 }}>
                  Voice Clone creation in progress
                </span>{" "}
                <Spinner size="sm" animation="border" variant="success" />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="samplesTable">
        <span style={{ fontWeight: 500 }}>Samples</span>
        <span className="ms-2">{sampleCount} / 25</span>
        <div>
          <Table striped bordered className="mt-2">
            {sampleUrls
              ? sampleUrls
                  .filter((sample) => sample.Status !== 3)
                  .map((sample, index) => (
                    <tr style={{ height: "80px" }} key={sample.Id}>
                      {" "}
                      <td
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingLeft: "20px",
                        }}
                      >
                        {sample.Name}
                      </td>
                      <td>
                        {" "}
                        <audio
                          className="ms-5"
                          src={sample.Url}
                          controls
                          disablepictureinpicture
                          controlslist="nodownload noplaybackrate"
                        />{" "}
                      </td>
                      <td>
                        {" "}
                        <Button
                          className="me-3"
                          variant="text"
                          style={{ padding: 0 }}
                          onClick={(e) => deleteSampleUrl(sample.Id)}
                        >
                          <RiDeleteBin6Line />
                        </Button>{" "}
                      </td>
                    </tr>
                  ))
              : null}
            {samples
              ? samples.map((sample, index) => (
                  <tr style={{ height: "80px" }} key={index}>
                    {" "}
                    <td
                      style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingLeft: "20px",
                      }}
                    >
                      {sample.name}
                    </td>
                    <td>
                      {" "}
                      <audio
                        className="ms-5"
                        src={URL.createObjectURL(sample)}
                        controls
                        disablepictureinpicture
                        controlslist="nodownload noplaybackrate"
                      />{" "}
                    </td>
                    <td>
                      {" "}
                      <Button
                        className="me-3"
                        variant="text"
                        style={{ padding: 0 }}
                        onClick={(e) => deleteSample(index)}
                      >
                        <RiDeleteBin6Line />
                      </Button>{" "}
                    </td>
                  </tr>
                ))
              : null}
          </Table>
        </div>
      </div>
      <div className="mt-3">
        <span style={{ fontWeight: 500 }}>Labels</span>
        <span className="ms-2">{labelCount} / 5</span>
        {addLabel === false && labels.length === 0 ? (
          <div className="mt-2 add-labels">
            <p className="ms-3" style={{ fontSize: "20px" }}>
              <BsInfoCircleFill />
            </p>
            <p className="mt-2" style={{ color: "grey" }}>
              No labels? Click{" "}
              <Button
                className="mb-2"
                style={{ padding: 0, color: "#0d6efd" }}
                variant="text"
                onClick={(e) => setAddLabel(true)}
              >
                <FaPlus />
              </Button>{" "}
              to add first one{" "}
            </p>
          </div>
        ) : (
          <div className="mt-2 labels-div">
            {labels.map((tag, index) => (
              <div
                className="label-pair"
                key={index}
                style={{
                  backgroundColor: labelColors[index % labelColors.length],
                }}
              >
                <span>
                  {tag.key} : {tag.value}
                </span>
                <Button
                  className="ms-2 mb-2"
                  style={{ padding: 0 }}
                  variant="text"
                  onClick={() => deleteLabel(index)}
                >
                  <RiDeleteBin6Line />
                </Button>
              </div>
            ))}
            {labelCount < 5 ? (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Form.Control
                  type="text"
                  style={{ width: "150px" }}
                  onBlur={(e) => setLabelKey(e.target.value)}
                  id="labelkey"
                  ref={inputRef}
                  placeholder="key e.g. accent"
                />{" "}
                <span>:</span>
                <Form.Control
                  type="text"
                  style={{
                    width: "150px",
                    "::placeholder": { fontSize: "12px" },
                  }}
                  id="labelvalue"
                  onBlur={(e) => setLabelValue(e.target.value)}
                  ref={inputValueRef}
                  placeholder="value e.g. Indian"
                />
                <Button
                  variant="text"
                  style={{ color: "green" }}
                  onClick={(e) => addLabels()}
                >
                  <SiVerizon />
                </Button>
              </div>
            ) : null}
          </div>
        )}
        <div className="mt-3">
          <Form.Label style={{ fontWeight: 500 }}>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            defaultValue={isEditing ? description : undefined}
            onBlur={(e) => setDescription(e.target.value)}
          />
        </div>
        <Form.Check
          className="mt-5 mb-3"
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={isChecked}
          label={
            <>
              <span style={{ color: "grey" }}>
                I hereby confirm that I have all necessary rights or consents to
                upload and clone these voice samples and that I will not use the
                platform-generated Content for any illegal, fraudulent, or
                harmful purpose. I reaffirm my obligation to abide by Spectral
                Studio
              </span>
              <span style={{ color: "grey" }}>'s </span>
              <span
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  window.open("https://spectralstudios.ai/terms/", "_blank")
                }
              >
                Terms of Service
              </span>
              <span style={{ color: "gray" }}> and </span>
              <span
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  window.open("https://spectralstudios.ai/privacy/", "_blank")
                }
              >
                Privacy Policy
              </span>
            </>
          }
        />
      </div>
    </div>
  );
}
