import React, { useEffect, useState } from 'react';
import VoicePage1 from './VoicePage1';
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';
import Form from 'react-bootstrap/Form';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ToastContainer, toast } from 'react-toastify';
import FileValidation from '../../../utils/FileValidation';
import ErrorToast from '../../../utils/ErrorToast';
import axios from 'axios';
import AddVoice from './AddVoice';

export default function VoiceLibrary({
  setSearchText,
  searchText,
  userInfo,
  setUserInfo,
  setMenuLock,
}) {
  const navigate = useNavigate();
  const [voicesArray, setVoicesArray] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  const [type1, setType1] = useState(0);
  const [filterOn, setFilterOn] = useState(false);
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [accent, setAccent] = useState('');
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const ageArray = ['Age', 'Young', 'Middle Aged', 'Old'];
  const genderArray = ['Gender', 'Male', 'Female', 'Neutral'];
  const accentArray = [
    'Accent',
    'British',
    'American',
    'African',
    'Australian',
    'Indian',
  ];
  const categoryArray = ['Category', 'Professional', 'Voice Design'];
  const typeArray = ['All', 'Prebuilt', 'Custom', 'Imported', 'SoundsLike'];

  const [soundsLikeParams, setSoundsLikeParam] = useState({});

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory === 'Category' ? '' : selectedCategory);
  };
  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setType(selectedType);
  };

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      className: 'custom-success-toast',
    });
  };

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/voice_library');
    ReactGA.set({ page: '/voice_library' });
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (Location.pathname === 'voice-library/addvoice') {
      setMenuLock(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [, setShow] = useState(false);
  const [voice, setVoice] = useState(null);
  const [samples, setSamples] = useState([]);
  const [sampleCount, setSampleCount] = useState(0);
  const [labels, setLabels] = useState([]);
  const [vName, setVName] = useState('');
  const [newExistingVoiceName, setNewExistingVoiceName] = useState('');
  const [description, setDescription] = useState('');
  const [flag, setFlag] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sampleUrls, setSampleUrls] = useState([]);
  const [vId, setVId] = useState('');
  const [vType, setVType] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const handleClose = () => setShow(false);
  const [tabKey, setTabKey] = useState('');
  const [existingVoiceId, setExistingVoiceId] = useState('');
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [addVoiceClicked, setAddVoiceClicked] = useState(false);
  const [soundslikeStatus, setSoundsLikeStatus] = useState(false);
  const [newSliderValues, setNewSliderValues] = useState({});

  /////////////

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  function close() {
    if (flag === false) {
      setVType(null);
      setDescription('');
      setSamples([]);
      setVName('');
      setLabels([]);
      setVId('');
      setSampleUrls([]);
      handleClose();
      setExistingVoiceId('');
      setIsPolicyChecked(false);
      navigate('/voice-library');
    }
  }
  function handleSave() {
    if (isChecked && vName !== '') {
      uploadFiles(samples);
    }
  }

  function uploadFiles(samples) {
    console.log(samples);

    for (const file of samples) {
      if (!FileValidation(file.name)) {
        return; // Exit the function if any filename is invalid
      }
    }

    setFlag(true);
    const formData = new FormData();
    samples.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    // console.log(formData);
    axios
      .post(`${apiURL}/util/multifile_upload_aws`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted); // Assuming setUploadProgress is a function to update the UI with the percentage
        },
      })
      .then((response) => {
        const res = response.data;
        if (res.IsValid) {
          const fileObjects = res.Urls.map((url) => {
            const fileName = url.split('/').pop(); // Extracting name from URL
            // Remove timestamp and anything before the first underscore
            const originalFileName = fileName.split('_').slice(1).join('_');
            return {
              Id: null,
              VoiceId: null,
              Name: originalFileName,
              Url: url,
              Duration: 0,
              Status: 0,
            };
          });
          // console.log("file objects are",fileObjects);
          if (isEditing === true && sampleUrls.length > 0) {
            // console.log("in is edit block");
            // Concatenate fileObjects array with sampleUrls state array
            const updatedFileObjects = [...sampleUrls, ...fileObjects];
            // console.log("Updated file objects are", updatedFileObjects);
            updateVoice(updatedFileObjects);
          } else {
            updateVoice(fileObjects);
          }
        } else {
          notify('Failed to upload files');
          console.error('Failed to upload files');
        }
      })
      .catch((error) => {
        setFlag(false);
        console.error('Error occurred during upload:', error);
      });
  }

  function updateVoice(sampleObjs) {
    const req = {
      Id: isEditing ? vId : null,
      Name: vName,
      Type: 1,
      CreatedBy: localStorage.getItem('userSessionId'),
      ModifiedBy: localStorage.getItem('userSessionId'),
      Labels: JSON.stringify(labels.map((obj) => `${obj.key}: ${obj.value}`)),
      Description: description,
      VoiceSamples: sampleObjs,
      SoundsLike: soundslikeStatus,
      SoundsLikeParams: soundsLikeParams,
    };

    if (sampleObjs.length > 0 && localStorage.getItem('userSessionId')) {
      fetch(`${apiURL}/dub/update_voice`, {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(req),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.IsValid === true) {
            fetchUserInfo();
            setIsChecked(false);
            voiceClone(data.VoiceId);
          } else {
            if (data.ErrorResponse !== null || data.ErrorResponse !== '') {
              notify(data.ErrorResponse);
              setFlag(false);
              console.error('Error:', data.Error);
            } else {
              notify('An error occurred. Please contact support.');
              setFlag(false);
              console.error('Error:', data.Error);
            }
          }
          if (data.Error) {
            ErrorToast();
          }
        })
        .catch((error) => {
          notify('An error occurred. Please contact support.');
          setFlag(false);
          console.error('API call failed:', error);
        });
    }
  }

  function voiceClone(voiceId) {
    const request = {
      Id: voiceId,
    };

    fetch(`${apiURL}/dub/update_voice_clone`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          setDescription('');
          setSamples([]);
          setVName('');
          setLabels([]);
          setVId('');
          setSampleUrls([]);
          setVType(null);

          if (isEditing) {
            notifySuccess('Voice Clone successfully updated!');
          } else {
            notifySuccess('Voice Clone successfully created!');
          }
          setFlag(false);
          getVoices();
          close();
        } else {
          notify('An error occurred. Please contact support.');
          setFlag(false);
          console.error('Error:', data.Error);
        }
      })
      .catch((error) => {
        notify('An error occurred. Please contact support.');
        setFlag(false);
        console.error('API call failed:', error);
      });
  }

  function addExistingVoice() {
    setAddVoiceClicked(true);
    const request = {
      Name: newExistingVoiceName,
      VoiceId: existingVoiceId,
      CreatedBy: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/dub/add_existing_voice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          notifySuccess('Voice added successfully!');
          setExistingVoiceId('');
          setNewExistingVoiceName('');
          fetchUserInfo();
          getVoices();
          close();
          setAddVoiceClicked(false);
        } else {
          if (data.ErrorResponse !== null || data.ErrorResponse !== '') {
            notify(data.ErrorResponse);
            // setExistingVoiceId('');
            console.error('Error:', data.Error);
            setAddVoiceClicked(false);
          } else {
            notify('An error occurred. Please contact support.');
            // setExistingVoiceId('');
            console.error('Error:', data.Error);
            setAddVoiceClicked(false);
          }
        }
        if (data.Error) {
          ErrorToast();
        }
      })
      .catch((error) => {
        notify('An error occurred. Please contact support.');
        setExistingVoiceId('');
        console.error('API call failed:', error);
      });
  }

  const handleGenderChange = (event) => {
    const selectedGender = event.target.value;

    setGender(selectedGender === 'Gender' ? '' : selectedGender);
  };

  const handleAgeChange = (event) => {
    const selectedAge = event.target.value;
    setAge(selectedAge === 'Age' ? '' : selectedAge);
  };

  const handleAccentChange = (event) => {
    const selectedAccent = event.target.value;
    setAccent(selectedAccent === 'Accent' ? '' : selectedAccent);
  };

  const getVoices = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // Filter elements where Type is 1
          const typeOneVoices = data.VoiceList.filter(
            (voice) => voice.Type === 1
          );

          // Count the number of elements with Type 1
          const numberOfTypeOneVoices = typeOneVoices.length;

          setType1(numberOfTypeOneVoices);
          setVoicesArray(data.VoiceList);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    document.title = 'Voice Library - Spectral Studio';
    fetchUserInfo();
    getVoices();
    setSearchText('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('userInfo')]);

  return (
    <div className="voiceLibrary mt-3">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          {location.pathname === '/voice-library/addvoice' ? (
            <h2 className="mt-2 ms-5">Add Voice</h2>
          ) : (
            <h2 className="mt-2 ms-5">Voice Library</h2>
          )}
          <p className="ms-5" style={{ color: 'grey' }}>
            Your creative voice toolkit. Discover prebuilt voices or Clone your
            own voice or a voice you have a permission and rights to. Only you
            have access to the voices you create.
          </p>
        </div>

        {location.pathname !== '/voice-library/addvoice' ? (
          <Button
            className="ms-5"
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              height: '60px',
              border: '1px solid black',
              marginTop: '10px',
            }}
            onClick={(e) => {
              setFilterOn(!filterOn);
            }}
          >
            <span style={{ fontSize: '20px' }}>
              <IoFilterSharp />
            </span>
            <p style={{ fontSize: '12px' }}>filters</p>
          </Button>
        ) : null}
      </div>
      {filterOn === true && location.pathname !== '/voice-library/addvoice' ? (
        <div
          className="ms-5 mt-2"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            width: '60vw',
          }}
        >
          <Form.Select size="sm" onChange={handleTypeChange} value={type}>
            {typeArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select
            size="sm"
            onChange={handleCategoryChange}
            value={category}
          >
            {categoryArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select size="sm" onChange={handleGenderChange} value={gender}>
            {genderArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select size="sm" onChange={handleAgeChange} value={age}>
            {ageArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select size="sm" onChange={handleAccentChange} value={accent}>
            {accentArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
        </div>
      ) : null}
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <VoicePage1
                voicesArray={voicesArray}
                getVoices={getVoices}
                type1={type1}
                searchText={searchText}
                age={age}
                gender={gender}
                category={category}
                accent={accent}
                type={type}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                tabKey={tabKey}
                setTabKey={setTabKey}
                existingVoiceId={existingVoiceId}
                setExistingVoiceId={setExistingVoiceId}
                isPolicyChecked={isPolicyChecked}
                setIsPolicyChecked={setIsPolicyChecked}
                close={close}
                addVoiceClicked={addVoiceClicked}
                setAddVoiceClicked={setAddVoiceClicked}
                handleSave={handleSave}
                uploadFiles={uploadFiles}
                samples={samples}
                setSamples={setSamples}
                voiceClone={voiceClone}
                updateVoice={updateVoice}
                flag={flag}
                setFlag={setFlag}
                setVId={setVId}
                setVType={setVType}
                vName={vName}
                setVName={setVName}
                labels={labels}
                setLabels={setLabels}
                description={description}
                setDescription={setDescription}
                sampleUrls={sampleUrls}
                setSampleUrls={setSampleUrls}
                sampleCount={sampleCount}
                setSampleCount={setSampleCount}
                uploadPercentage={uploadPercentage}
                setUploadPercentage={setUploadPercentage}
                setIsEditing={setIsEditing}
                newSliderValues={newSliderValues}
                setNewSliderValues={setNewSliderValues}
              />
            }
          />
          <Route
            path="addvoice"
            element={
              <AddVoice
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                tabKey={tabKey}
                setTabKey={setTabKey}
                existingVoiceId={existingVoiceId}
                setExistingVoiceId={setExistingVoiceId}
                isPolicyChecked={isPolicyChecked}
                setIsPolicyChecked={setIsPolicyChecked}
                setVoice={setVoice}
                voice={voice}
                labels={labels}
                setLabels={setLabels}
                samples={samples}
                setSamples={setSamples}
                flag={flag}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                vName={vName}
                setVName={setVName}
                vType={vType}
                description={description}
                setDescription={setDescription}
                uploadPercentage={uploadPercentage}
                setSampleUrls={setSampleUrls}
                sampleUrls={sampleUrls}
                sampleCount={sampleCount}
                setSampleCount={setSampleCount}
                close={close}
                addVoiceClicked={addVoiceClicked}
                setAddVoiceClicked={setAddVoiceClicked}
                handleSave={handleSave}
                addExistingVoice={addExistingVoice}
                setUploadPercentage={setUploadPercentage}
                soundsLikeParams={soundsLikeParams}
                setSoundsLikeParam={setSoundsLikeParam}
                soundslikeStatus={soundslikeStatus}
                setSoundsLikeStatus={setSoundsLikeStatus}
                newExistingVoiceName={newExistingVoiceName}
                setNewExistingVoiceName={setNewExistingVoiceName}
                newSliderValues={newSliderValues}
                setNewSliderValues={setNewSliderValues}
              />
            }
          />
        </Routes>
        {/* <VoicePage1
          voicesArray={voicesArray}
          getVoices={getVoices}
          type1={type1}
          searchText={searchText}
          age={age}
          gender={gender}
          category={category}
          accent={accent}
          type={type}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        /> */}
        <ToastContainer />
      </div>
    </div>
  );
}
