import React, { useEffect } from "react";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";

import { useFormik } from "formik";
import * as Yup from "yup";
import "./registration.css";
// import { useNavigate } from "react-router-dom";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "black",
    color: "lightblue",
    "& fieldset": {
      borderColor: "lightblue",
    },
    "&:hover fieldset": {
      borderColor: "lightblue",
    },
    "&.Mui-focused fieldset": {
      borderColor: "lightblue",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "black !important", // Set the background color to black
    },
  },
  "& .MuiInputLabel-root": {
    color: "lightblue",
  },
  width: "90%",
  height: "40px",
  margin: "5%",
}));

export default function ForgotPassword({ setSelectedRegComponent }) {
  // const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
    },
  });

  useEffect(() => {
    document.title = "Forgot Password ? - Spectral Studio";
  }, []);

  return (
    <div style={{ margin: "5%", height: "55vh" }}>
      <h4 style={{ marginTop: "5%", marginLeft: "5%" }}>
        Forgot Your Password?
      </h4>
      <p
        style={{
          color: "white",
          marginLeft: "5%",
          marginTop: "5%",
          width: "90%",
          lineHeight: "20px",
        }}
      >
        Enter your work email address and we will send you instructions to reset
        your password.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <CustomTextField
          label="Work email address"
          variant="outlined"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />
        <button className="continueBtn">Continue</button>
      </form>
      <button
        type="button"
        className="backtoStudio"
        onClick={(e) => setSelectedRegComponent("login")}
      >
        <strong>Back to Spectral Studio</strong>
      </button>
    </div>
  );
}
