import React, { useEffect, useState } from "react";
// import { Button } from 'react-bootstrap';
// import { IoFilterSharp } from 'react-icons/io5';
// import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';
import { Table, Pagination } from "react-bootstrap";
// import Form from 'react-bootstrap/Form';
// import { HiInformationCircle } from 'react-icons/hi2';
import ReactGA from "react-ga";
import "./spectralDRM.css";

export default function Transaction({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
}) {
  // const [activeTab, setActiveTab] = useState('textToSpeech');
  // const [activeTab2, setActiveTab2] = useState('settings');
  const [drmTransaction, setDrmTransaction] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const enumMapping = {
    1: "Translate",
    2: "Personalise",
    3: "Transcend",
    4: "VoiceTransfer",
  };
  const apiURL = process.env.REACT_APP_API_URL;

  // const InformationCircleTooltip = ({ id, text }) => (
  //   <OverlayTrigger
  //     placement="top"
  //     overlay={
  //       <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
  //         {text}
  //       </Tooltip>
  //     }
  //   >
  //     <span
  //       style={{
  //         fontSize: '16px',
  //         marginTop: '12px',
  //         marginLeft: '6px',
  //         color: 'gray',
  //       }}
  //     >
  //       <HiInformationCircle />
  //     </span>
  //   </OverlayTrigger>
  // );

  const getDrmTransactions = async () => {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
      TopN: 25,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_drm_transactions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setDrmTransaction(data.TransactionList);
          console.log(drmTransaction);
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/voice_lab");
    ReactGA.set({ page: "/voice_lab" });
  }, []);

  useEffect(() => {
    document.title = "Transactions - Spectral Studio";
    getDrmTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalPages = Math.ceil(drmTransaction.length / itemsPerPage);
  const paginatedTransactions = drmTransaction.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="digital-twin-container">
      <header>
        <h2 className="">Transactions</h2>
      </header>
      <div
        style={{ position: "relative", overflowY: "auto" }}
        className="dt-voice-content"
      >
        <div className="tableDiv2">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: "500",
                background: "#fff",
              }}
              className=""
            >
              Transaction List
            </span>
          </div>

          <Table striped bordered className="mt-2">
            <thead>
              <tr>
                <th>No.</th>
                <th>DateTime</th>
                <th>Project</th>
                <th>IP</th>
                <th>Type</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {paginatedTransactions.map((data, index) => (
                <tr key={data.TransactionId}>
                  <td style={{ verticalAlign: "middle" }}>
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {new Date(data.CreatedAt)
                      .toISOString()
                      .replace(/[a-zA-Z]/g, " ")}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {data.ProjectName}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{data.VoiceName}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    {enumMapping[data.ProjectType]}
                  </td>
                  <td
                    className="fixed-column1"
                    style={{ verticalAlign: "middle", width: "350px" }}
                  >
                    <audio
                      preload="none"
                      style={{ height: "40px", width: "100%" }}
                      key={data.TransactionId}
                      className="audioFiles"
                      src={`${data.Result.trim()}?random=${Math.random()}`}
                      controls
                      disablepictureinpicture
                      controlslist="nodownload noplaybackrate"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.First
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages).keys()].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => setCurrentPage(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
}
