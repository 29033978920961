import React, { useState } from "react";
import { GrMicrophone } from "react-icons/gr";
import { RxScissors } from "react-icons/rx";
import { BiFastForward, BiRewind, BiVolumeFull } from "react-icons/bi";
import { FaPlay } from "react-icons/fa";
import { FiZoomOut, FiZoomIn } from "react-icons/fi";
import Button from "react-bootstrap/Button";

export default function ToolBar({
  zoomOut,
  zoomIn,
  initialMarkings,
  setInitialMarkings,
  lock,
  setLock,
}) {
  // const [volume, setVolume] = useState(50);
  // const handleVolumeChange = (e) => {
  //   setVolume(e.target.value);
  //   // You can add logic here to control the volume as needed
  // };
  return (
    <div>
      <div className="toolBarContainer">
        <div className="modify">
          <Button variant="text">
            <span className="t-button">
              <RxScissors />
            </span>
            <span style={{ fontWeight: 500 }}>Split</span>
          </Button>

          <Button variant="text">
            <span className="t-button">
              <GrMicrophone />
            </span>
            <span style={{ fontSize: "16px", fontWeight: 500 }}>Voiceover</span>
          </Button>
        </div>

        <div>
          <Button
            variant="text"
            style={{ fontSize: "x-large" }}
            className="vplay-button"
          >
            <BiRewind />
          </Button>
          <Button
            variant="text"
            className="play-button"
            style={{ fontSize: "30px" }}
          >
            <FaPlay />
          </Button>
          <Button
            variant="text"
            style={{ fontSize: "x-large" }}
            className="vplay-button"
          >
            <BiFastForward />
          </Button>
          <Button
            variant="text"
            className="vol-button"
            style={{ fontSize: "x-large" }}
          >
            <BiVolumeFull />
          </Button>
        </div>

        <SeekBar
          zoomOut={zoomOut}
          zoomIn={zoomIn}
          initialMarkings={initialMarkings}
          setInitialMarkings={setInitialMarkings}
          lock={lock}
          setLock={setLock}
        />
      </div>
    </div>
  );
}

function SeekBar({
  zoomOut,
  zoomIn,
  initialMarkings,
  setInitialMarkings,
  lock,
  setLock,
}) {
  const [isDragging, setIsDragging] = useState(false);

  const handleChange = (e) => {
    setLock(false);
    if (!isDragging) return;
    const newValue = e.target.value;
    const zoomDirection = newValue > initialMarkings ? "zoomOut" : "zoomIn";

    if (zoomDirection === "zoomIn" && initialMarkings <= 25) {
      // If already zoomed in and at 25, don't zoom in further
      return;
    }

    setInitialMarkings(newValue);

    if (zoomDirection === "zoomIn") {
      zoomIn();
    } else {
      zoomOut();
    }
  };
  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div style={{ marginRight: "10px" }}>
      <Button
        variant="text"
        className="zoomButtons"
        id="zoomOutButton"
        onClick={zoomOut}
        style={{ fontSize: "x-large" }}
      >
        <FiZoomOut />
      </Button>
      <input
        type="range"
        id="ZoomSeekBar"
        min={0}
        max={3600}
        value={initialMarkings}
        onChange={(e) => handleChange(e)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          width: "150px",
          height: "6px",
          background: "gray",
          borderRadius: "5px",
          appearance: "none",
          outline: "none",
          margin: "0 10px",
          transform: "scaleX(-1)",
          cursor: "pointer",
        }}
      />
      <Button
        variant="text"
        style={{ fontSize: "x-large" }}
        className="zoomButtons"
        id="zoomInButton"
        onClick={zoomIn}
      >
        <FiZoomIn />
      </Button>
    </div>
  );
}
