import React, { useEffect, useState } from "react";
import { Navbar, NavDropdown, Nav, Row, Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import userPic from "../multimedia/userpic.png";
import SpectralTranscend from "./DashboardComponents/SpectralTranscendComponents/SpectralTranscend";

import SprectralTranslate from "./DashboardComponents/SpectralTranslate/SprectralTranslate";
import VoiceLibrary from "./DashboardComponents/VoiceLibraryComponents/VoiceLibrary";
import VoiceLab from "./DashboardComponents/VoiceLabComponents/VoiceLab";
import VoiceTransfer from "./DashboardComponents/VoiceTransferComponents/VoiceTransfer";
import Profile from "./DashboardComponents/Profile";
import { MdArrowBackIos } from "react-icons/md";
import Admin from "./DashboardComponents/Admin/Admin";
import DigitalTwin from "./DashboardComponents/SpectralDRM/DigitalTwin";
import Projects from "./DashboardComponents/SpectralDRM/Projects";
import DashboardDRM from "./DashboardComponents/SpectralDRM/DashboardDRM";
import AuditLog from "./DashboardComponents/SpectralDRM/AuditLog";
import { Routes, Route, useLocation } from "react-router-dom";

export default function UserPage({
  selectedDashboardComponent,
  setSelectedDashboardComponent,
  render,
  setRender,
  userId,
  setUserId,
  selectedTranscendComponent,
  setSelectedTranscendComponent,
  selectedVoiceTransferComponent,
  setSelectedVoiceTransferComponent,
  selectedTranslateComponent,
  setSelectedTranslateComponent,
  menuLock,
  setMenuLock,
  selectedAdminComponent,
  setSelectedAdminComponent,
  selectedDigitalTwinComponent,
  setSelectedDigitalTwinComponent,
  selectedProjectsComponent,
  setSelectedProjectsComponent,
  setShowProjectsSubButtons,
  showProjectsSubButtons,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { component } = useParams();
  const [searchText, setSearchText] = useState("");
  const [helpUrl, setHelpUrl] = useState("");
  const [projectName, setProjectName] = useState("");
  // ----------------------

  localStorage.getItem("userSessionId");
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );

  useEffect(() => {
    const pathParts = location.pathname.split("/"); // Split the pathname
    const firstPart = pathParts[1];

    if (firstPart === "transcend") {
      // Check the first part of the path
      const idFromUrl = pathParts[2]; // Extract the project ID (assuming it's the third part)
      const subPath = pathParts[3]; // Check for the fourth part of the path

      // List of valid sub-paths or undefined paths
      const validSubPaths = [
        "audio-segments",
        "download",
        "transcribe",
        "translate",
        "speaker",
        "create",
        undefined,
      ];

      // Check if the path starts with 'transcend' and if the project ID exists
      if (firstPart === "transcend" && idFromUrl) {
        // setProjectId(idFromUrl.toUpperCase());

        // Navigate to /transcend if the sub-path is not valid
        if (!validSubPaths.includes(subPath)) {
          navigate(`/transcend/${idFromUrl}/download`);
        }
      }
    } else if (firstPart === "voice-transfer") {
      const idFromUrl = pathParts[2]; // Extract the project ID (assuming it's the third part)
      const subPath = pathParts[3]; // Check for the fourth part of the path

      // List of valid sub-paths or undefined paths
      const validSubPaths = ["audio-segments", "download", "create", undefined];

      if (firstPart === "voice-transfer" && idFromUrl) {
        if (!validSubPaths.includes(subPath)) {
          navigate(`/voice-transfer/${idFromUrl}/download`);
        }
      }
    } else if (firstPart === "translate") {
      const idFromUrl = pathParts[2]; // Extract the project ID (assuming it's the third part)
      const subPath = pathParts[3]; // Check for the fourth part of the path

      // List of valid sub-paths or undefined paths
      const validSubPaths = ["result", undefined];

      if (firstPart === "translate" && idFromUrl) {
        if (!validSubPaths.includes(subPath)) {
          navigate(`/translate/${idFromUrl}/result`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("userInfo")]);

  useEffect(() => {
    if (component) {
      setSelectedDashboardComponent(component);
      setSearchText("");
      if (component === "spectral-transcend") {
        setHelpUrl("https://go.cynapto.com/iRNjB");
      } else if (component === "spectral-translate") {
        setHelpUrl("https://go.cynapto.com/U6n7u");
      } else if (component === "voice-library") {
        setHelpUrl("https://go.cynapto.com/UkyGe");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, selectedDashboardComponent]);

  function redirect() {
    // console.log('clicked redirect');
    // console.log('component is',selectedDashboardComponent);
    setProjectName("");
    if (selectedDashboardComponent === "spectral-transcend") {
      setMenuLock(false);
      setSelectedTranscendComponent("transcendHome");
      navigate("/transcend");
      setRender(!render);
    } else if (selectedDashboardComponent === "voice-transfer") {
      setMenuLock(false);
      setSelectedVoiceTransferComponent("voiceTransferHome");
      navigate("/voice-transfer");
      setRender(!render);
    } else if (selectedDashboardComponent === "spectral-translate") {
      setMenuLock(false);
      setSelectedTranslateComponent("tHome");
      navigate("/translate");
      setRender(!render);
    } else if (selectedDashboardComponent === 'voice-library') {
      setMenuLock(false);
      // setSelectedTranslateComponent('tHome');
      navigate('/voice-library');
      setRender(!render);
    } else {
      navigate("/transcend");
    }
  }

  const handleLogout = () => {
    // clear user session, redirect to login page
    if (localStorage.getItem("userSessionId") !== null) {
      setUserId("");
      localStorage.clear();
      navigate("/account/login");
    }
  };
  return (
    <div className="user-page">
      <Row>
        <Navbar className="user-nav ">
          <Nav
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            {menuLock === false ? (
              <div className="search-bar">
                <div className="search-icon">
                  <BsSearch />
                </div>
                <input
                  value={searchText}
                  className="search"
                  type="search"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            ) : (
              <div className="back-div">
                <Button
                  variant="text"
                  style={{ fontSize: "20px", fontWeight: 600 }}
                  onClick={(e) => redirect()}
                >
                  <MdArrowBackIos />
                </Button>
                {projectName !== null &&
                projectName !== "" &&
                projectName !== undefined ? (
                  <span className="projectName">{projectName}</span>
                ) : null}
              </div>
            )}

            <span
              className="ms-auto"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {localStorage.getItem("userSessionId") !== null &&
              userInfo &&
              userInfo.Role !== "DRM" ? (
                <span
                  className="me-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <label htmlFor="credits">
                    {userInfo.UsageCredits}/{userInfo.LimitCredits} min
                  </label>
                  <meter
                    className="credit-range"
                    id="credits"
                    min="0"
                    max={userInfo.LimitCredits}
                    value={userInfo.UsageCredits}
                  >
                    {userInfo.UsageCredits}/{userInfo.LimitCredits}
                  </meter>
                </span>
              ) : null}
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {userInfo.Role !== "DRM" ? (
                  <button
                    className="help-btn"
                    onClick={() => {
                      window.open(helpUrl, "_blank");
                    }}
                  >
                    <AiOutlineQuestionCircle />
                  </button>
                ) : null}

                <NavDropdown
                  className="user"
                  title={
                    <img
                      src={userPic}
                      alt="userpic"
                      style={{ width: "60px", height: "40px" }}
                    />
                  }
                  id="basic-nav-dropdown"
                >
                  {localStorage.getItem("userSessionId") !== null ? (
                    <NavDropdown.Item onClick={(e) => navigate("/profile")}>
                      Profile
                    </NavDropdown.Item>
                  ) : null}
                  {localStorage.getItem("userSessionId") !== null ? (
                    <NavDropdown.Item onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  ) : null}
                </NavDropdown>
              </span>
            </span>
          </Nav>
        </Navbar>
      </Row>
      <Row>
        <div style={{ height: "90vh", borderTop: "1px solid lightgrey" }}>
          {/* {renderSelectedDashboardComponent()} */}
          {JSON.parse(localStorage.getItem("userInfo")).IsDubbingClient ===
          true ? (
            <Routes>
              <Route
                path="voice-transfer/*"
                element={
                  <VoiceTransfer
                    key={render}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    menuLock={menuLock}
                    setMenuLock={setMenuLock}
                    setUserInfo={setUserInfo}
                    selectedVoiceTransferComponent={
                      selectedVoiceTransferComponent
                    }
                    setSelectedVoiceTransferComponent={
                      setSelectedVoiceTransferComponent
                    }
                    projectName={projectName}
                    setProjectName={setProjectName}
                  />
                }
              ></Route>

              {/* Add more routes as needed */}
            </Routes>
          ) : (
            <Routes>
              {userInfo &&
              userInfo.TranscendAccess === true &&
              userInfo.Role !== "DRM" ? (
                <Route
                  path="home"
                  element={
                    <SpectralTranscend
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      menuLock={menuLock}
                      setMenuLock={setMenuLock}
                      setUserInfo={setUserInfo}
                      selectedTranscendComponent={selectedTranscendComponent}
                      setSelectedTranscendComponent={
                        setSelectedTranscendComponent
                      }
                      projectName={projectName}
                      setProjectName={setProjectName}
                    />
                  }
                />
              ) : (
                <Route
                  path="drm/*"
                  element={
                    <DashboardDRM
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      selectedDashboardComponent={selectedDashboardComponent}
                      setSelectedDashboardComponent={
                        setSelectedDashboardComponent
                      }
                    />
                  }
                />
              )}

              {userInfo && userInfo.TranscendAccess === true ? (
                <Route
                  path="transcend/*"
                  element={
                    <SpectralTranscend
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      menuLock={menuLock}
                      setMenuLock={setMenuLock}
                      setUserInfo={setUserInfo}
                      selectedTranscendComponent={selectedTranscendComponent}
                      setSelectedTranscendComponent={
                        setSelectedTranscendComponent
                      }
                      projectName={projectName}
                      setProjectName={setProjectName}
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.TranslateAccess === true ? (
                <Route
                  path="translate/*"
                  element={
                    <SprectralTranslate
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      setUserInfo={setUserInfo}
                      menuLock={menuLock}
                      setMenuLock={setMenuLock}
                      selectedTranslateComponent={selectedTranslateComponent}
                      setSelectedTranslateComponent={
                        setSelectedTranslateComponent
                      }
                      projectName={projectName}
                      setProjectName={setProjectName}
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.VoiceLibAccess === true ? (
                <Route
                  path="voice-library/*"
                  element={
                    <VoiceLibrary
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      menuLock={menuLock}
                      setMenuLock={setMenuLock}
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.VoiceLabAccess === true ? (
                <Route
                  path="voice-lab/*"
                  element={
                    <VoiceLab
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.Role === "DRM" ? (
                <Route
                  path="drm/*"
                  element={
                    <DashboardDRM
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      selectedDashboardComponent={selectedDashboardComponent}
                      setSelectedDashboardComponent={
                        setSelectedDashboardComponent
                      }
                      showProjectsSubButtons={showProjectsSubButtons}
                      setShowProjectsSubButtons={setShowProjectsSubButtons}
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.Role === "DRM" ? (
                <Route
                  path="drm/dashboard"
                  element={
                    <DashboardDRM
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      selectedDashboardComponent={selectedDashboardComponent}
                      setSelectedDashboardComponent={
                        setSelectedDashboardComponent
                      }
                      showProjectsSubButtons={showProjectsSubButtons}
                      setShowProjectsSubButtons={setShowProjectsSubButtons}
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.Role === "DRM" ? (
                <Route
                  path="drm/digital-twin/*"
                  element={
                    <DigitalTwin
                      key={render}
                      // searchText={searchText}
                      // setSearchText={setSearchText}
                      // userInfo={userInfo}
                      // setUserInfo={setUserInfo}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      selectedDigitalTwinComponent={
                        selectedDigitalTwinComponent
                      }
                      setSelectedDigitalTwinComponent={
                        setSelectedDigitalTwinComponent
                      }
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.Role === "DRM" ? (
                <Route
                  path="drm/projects/*"
                  element={
                    <Projects
                      key={render}
                      selectedProjectsComponent={selectedProjectsComponent}
                      setSelectedProjectsComponent={
                        setSelectedProjectsComponent
                      }
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.Role === "DRM" ? (
                <Route
                  path="drm/audit-log/*"
                  element={
                    <AuditLog
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                    />
                  }
                />
              ) : null}
              {userInfo && (
                <Route
                  path="profile/*"
                  element={
                    <Profile
                      key={render}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                    />
                  }
                />
              )}
              {userInfo && userInfo.IsAdmin === true ? (
                <Route
                  path="admin/*"
                  element={
                    <Admin
                      key={render}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      selectedAdminComponent={selectedAdminComponent}
                      setSelectedAdminComponent={setSelectedAdminComponent}
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  }
                />
              ) : null}
              {userInfo && userInfo.VTAccess === true ? (
                <Route
                  path="voice-transfer/*"
                  element={
                    <VoiceTransfer
                      key={render}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      menuLock={menuLock}
                      setMenuLock={setMenuLock}
                      setUserInfo={setUserInfo}
                      selectedVoiceTransferComponent={
                        selectedVoiceTransferComponent
                      }
                      setSelectedVoiceTransferComponent={
                        setSelectedVoiceTransferComponent
                      }
                      projectName={projectName}
                      setProjectName={setProjectName}
                    />
                  }
                />
              ) : null}

              {/* Add more routes as needed */}
            </Routes>
          )}
        </div>
      </Row>
    </div>
  );
}
