import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FileValidation = (filename) => {
  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });
  const invalidChars = /[?%#/:]/;
  if (invalidChars.test(filename)) {
    notify('Filename contains invalid characters such as ?, %, #, /, and :');
    return false;
  }
  return true;
};

export default FileValidation;
