import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  FaPlay,
  FaForward,
  FaVolumeUp,
  FaPause,
  FaBackward,
  FaExpand,
  FaVolumeMute,
} from "react-icons/fa";
import { ImLoop } from "react-icons/im";

const CustomVideoPlayer = ({
  videoRef,
  videoUrl,
  currentTime,
  setCurrentTime,
  seekTime,
  handleLoop,
  loopTranscript,
  isPlaying,
  setIsPlaying,
  setZoomLevel,
  isMuted,
  setIsMuted,
}) => {
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  // const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [isVideoError, setIsVideoError] = useState(false);

  const handleVideoError = () => {
    setIsVideoError(true);
  };

  useEffect(() => {
    const video = videoRef.current;
    video.currentTime = seekTime.value;
  }, [seekTime, videoRef]);

  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener("timeupdate", () => {
      setCurrentTime(video.currentTime);
    });
    video.addEventListener("loadedmetadata", () => {
      setDuration(video.duration);
      if (video.duration < 15) {
        setZoomLevel(200);
      }
    });
    video.addEventListener("ended", () => {
      setIsPlaying(false);
    });

    return () => {
      video.removeEventListener("timeupdate", () => {});
      video.removeEventListener("loadedmetadata", () => {});
      video.removeEventListener("ended", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePlay = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleRewind = () => {
    const video = videoRef.current;
    video.currentTime -= 10;
  };

  const handleFastForward = () => {
    const video = videoRef.current;
    video.currentTime += 10;
  };

  const handleVolumeChange = (e) => {
    const video = videoRef.current;
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    video.volume = newVolume;
    setIsMuted(false);
  };

  const toggleMute = () => {
    const video = videoRef.current;
    if (isMuted) {
      video.volume = volume;
    } else {
      video.volume = 0;
    }
    setIsMuted(!isMuted);
  };

  const formatVideoTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.floor(
      (timeInSeconds - Math.floor(timeInSeconds)) * 1000
    );

    const formattedMilliseconds = milliseconds
      .toString()
      .padStart(3, "0")
      .substring(0, 2);

    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}.${formattedMilliseconds}`;
  };

  const handleSeek = (e) => {
    const video = videoRef.current;
    const newTime = e.target.value / 1000; // Convert milliseconds to seconds
    video.currentTime = newTime;
  };

  const handleFullScreen = () => {
    const video = videoRef.current;
    const fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;

    if (!fullscreenElement) {
      if (video.requestFullscreen) {
        video
          .requestFullscreen()
          .catch((error) =>
            console.log("Error attempting to enter full-screen mode:", error)
          );
      } else if (video.mozRequestFullScreen) {
        /* Firefox */
        video
          .mozRequestFullScreen()
          .catch((error) =>
            console.log("Error attempting to enter full-screen mode:", error)
          );
      } else if (video.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        video
          .webkitRequestFullscreen()
          .catch((error) =>
            console.log("Error attempting to enter full-screen mode:", error)
          );
      } else if (video.msRequestFullscreen) {
        /* IE/Edge */
        video
          .msRequestFullscreen()
          .catch((error) =>
            console.log("Error attempting to enter full-screen mode:", error)
          );
      }
    } else {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .catch((error) =>
            console.log("Error attempting to exit full-screen mode:", error)
          );
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document
          .mozCancelFullScreen()
          .catch((error) =>
            console.log("Error attempting to exit full-screen mode:", error)
          );
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document
          .webkitExitFullscreen()
          .catch((error) =>
            console.log("Error attempting to exit full-screen mode:", error)
          );
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document
          .msExitFullscreen()
          .catch((error) =>
            console.log("Error attempting to exit full-screen mode:", error)
          );
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  const isSmallScreen = window.innerWidth <= 1200;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="video-page"
    >
      {/* <video
        className="vid_sync3"
        id="custom-video-player"
        ref={videoRef}
        src={videoUrl}
      ></video> */}
      {!isVideoError ? (
        <video
          className="vid_sync3"
          id="custom-video-player"
          ref={videoRef}
          src={videoUrl}
          onError={handleVideoError}
        ></video>
      ) : (
        <video
          className="vid_sync4"
          id="custom-video-player"
          ref={videoRef}
          alt="No video available"
        />
      )}
      <input
        className="custom-video-seek mt-2"
        type="range"
        min="0"
        max={duration * 1000}
        value={currentTime * 1000}
        onChange={handleSeek}
        style={{
          width: "100%",
          height: "6px",
          outline: "none",
          cursor: "pointer",
          background: "grey",
          border: "none",
          marginBottom: "10px",
          display: "flex",
        }}
      />
      <div className="custom-controls">
        <div className="custom-controls2">
          {" "}
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
            }}
          >
            <Button
              className="custom-controls-btns"
              variant="text"
              style={{
                padding: 0,
                color: "white",
                fontSize: "70%",
                width: "25px",
              }}
              onClick={handleRewind}
            >
              <FaBackward />
            </Button>
            <Button
              className="custom-controls-btns"
              variant="text"
              style={{
                padding: 0,
                color: "white",
                fontSize: "70%",
                width: "25px",
              }}
              onClick={togglePlay}
            >
              {isPlaying ? <FaPause /> : <FaPlay />}
            </Button>
            <Button
              className="custom-controls-btns"
              variant="text"
              style={{
                padding: 0,
                color: "white",
                fontSize: "70%",
                width: "25px",
              }}
              onClick={handleFastForward}
            >
              <FaForward />
            </Button>
          </div>
          <span
            style={{
              fontSize: "12px",
              fontWeight: 600,
              minWidth: "max-content",
            }}
          >
            {formatVideoTime(currentTime)} / {formatVideoTime(duration)}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {/* <Button
              className="custom-controls-btns"
              variant="text"
              style={{
                padding: '0',
                width: '25px',
                color: 'white',
                fontSize: '80%',
              }}
              onClick={toggleMute}
            >
              {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </Button> */}
            <div
              className="volume-slider-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Button
                className="custom-controls-btns"
                variant="text"
                style={{
                  padding: "0",
                  width: "25px",
                  color: "white",
                  fontSize: "80%",
                }}
                onClick={toggleMute}
              >
                {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
              </Button>
              <div style={{ display: "flex" }}>
                <input
                  className={`volume-slider ${isHovered ? "hovered" : ""}`}
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={isMuted ? 0 : volume}
                  onChange={handleVolumeChange}
                  style={{ width: isSmallScreen ? "6vh" : "8vh" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{}}>
          <Button
            className="custom-controls-btns ms-auto"
            variant="text"
            style={{
              padding: 0,
              color: loopTranscript ? "rgb(0 117 255)" : "white",
              outline: "none",
              fontSize: "70%",
            }}
            onClick={handleLoop}
          >
            <ImLoop />
          </Button>
          <Button
            className="custom-controls-btns"
            variant="text"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              color: "white",
              fontSize: "70%",
            }}
            onClick={handleFullScreen}
          >
            <FaExpand />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
