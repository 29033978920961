import React, { useEffect, useState } from "react";
// import VoicePage1 from './VoicePage1';
import { Button } from "react-bootstrap";
// import { IoFilterSharp } from 'react-icons/io5';
// import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';
import { IoArrowBack } from "react-icons/io5";

import { Table } from "react-bootstrap";
// import Form from 'react-bootstrap/Form';
// import { HiInformationCircle } from 'react-icons/hi2';
import ReactGA from "react-ga";
import "../spectralDRM.css";
// import { VoiceChat } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

export default function ManageVoice({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
  drmVoiceId,
  setDrmVoiceId,
  voiceData,
  setVoiceData,
  setUser,
  setSelectedDigitalTwinComponent,
  editClicked,
  setEditClicked,
  selectedRowData,
  setSelectedRowData,
}) {
  const navigate = useNavigate();

  // const [activeTab, setActiveTab] = useState('textToSpeech');
  // const [activeTab2, setActiveTab2] = useState('settings');
  // const [voicesArray, setVoicesArray] = useState([]);

  const apiURL = process.env.REACT_APP_API_URL;

  const [isRevokeClicked, setIsRevokeClicked] = useState(false);

  // const InformationCircleTooltip = ({ id, text }) => (
  //   <OverlayTrigger
  //     placement="top"
  //     overlay={
  //       <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
  //         {text}
  //       </Tooltip>
  //     }
  //   >
  //     <span
  //       style={{
  //         fontSize: '16px',
  //         marginTop: '12px',
  //         marginLeft: '6px',
  //         color: 'gray',
  //       }}
  //     >
  //       <HiInformationCircle />
  //     </span>
  //   </OverlayTrigger>
  // );

  // google analytics
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/voice_lab");
    ReactGA.set({ page: "/voice_lab" });
  }, []);

  const manageDrmVoice = async () => {
    setEditClicked(false);
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
      VoiceId: drmVoiceId,
    };
    try {
      const response = await fetch(`${apiURL}/web/manage_drm_voice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setVoiceData(data.AccessList);
          // console.log(voiceData);
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateAccess = async () => {
    setSelectedDigitalTwinComponent("edit-voice");
    navigate("/drm/digital-twin/edit-voice");
  };

  const RevokeAccess = async () => {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
      VoiceId: drmVoiceId,
      AccessId: selectedRowData.AccessId,
      AccessStatus: 3,
      OrgId: selectedRowData.OrganizationId,
      StartDate: new Date(selectedRowData.CreatedOn)
        .toISOString()
        .split("T")[0],
      EndDate: new Date(selectedRowData.ExpiringOn).toISOString().split("T")[0],
    };
    try {
      const response = await fetch(`${apiURL}/web/update_drm_voice_access`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // setSelectedDigitalTwinComponent('manage-voice');
          // setVoiceData(data.AccessList);
          // console.log(voiceData);
          manageDrmVoice();
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (selectedRowData && isRevokeClicked) {
      RevokeAccess(selectedRowData);
      setIsRevokeClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowData, isRevokeClicked]);

  const handleRevokeClick = (data) => {
    setSelectedRowData(data);
    console.log(selectedRowData);
    setIsRevokeClicked(true);
    // if (selectedRowData) {
    //   RevokeAccess(selectedRowData);
    // }

    // setSelectedDigitalTwinComponent('edit-voice');
  };

  const handleEditClicked = (data) => {
    setSelectedRowData(data);
    setEditClicked(true);
    setSelectedDigitalTwinComponent("edit-voice");
    navigate("/drm/digital-twin/edit-voice");
  };

  useEffect(() => {
    document.title = "Voice - Spectral Studio";
    manageDrmVoice();
    // console.log(drmVoiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function cancelChanges() {
    // setUser({});
    setSelectedDigitalTwinComponent("voice");
    navigate("/drm/digital-twin/voice");
  }

  return (
    <div className="digital-twin-container">
      <header>
        <h2 className="">Manage Voice</h2>
      </header>
      <div
        style={{ position: "relative", overflowY: "auto" }}
        className="dt-voice-content"
      >
        <span>
          <span style={{ position: "absolute", left: 20, top: 10 }}>
            <Button
              style={{ fontSize: "20px", fontWeight: 500 }}
              variant="text"
              onClick={(e) => cancelChanges()}
            >
              <IoArrowBack />
              <span className="ms-1" style={{ fontSize: "14px" }}>
                Back
              </span>
            </Button>
          </span>
        </span>

        <div style={{ marginTop: "2rem" }}>
          <div className="tableDiv2">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  background: "#fff",
                }}
                className=""
              >
                Active
              </span>
              <Button
                style={{ backgroundColor: "black", border: "none" }}
                onClick={(e) => {
                  updateAccess(e);
                }}
              >
                Add New +
              </Button>
            </div>

            <Table striped bordered className="mt-2 fixed-size-table">
              <thead>
                <tr>
                  <th className="fixed-column">No.</th>
                  <th className="fixed-column">Organization</th>
                  <th className="fixed-column">Created</th>
                  <th className="fixed-column">Last Used</th>
                  <th className="fixed-column">Expires</th>
                  <th className="fixed-column1">Action</th>
                </tr>
              </thead>
              <tbody>
                {voiceData
                  .filter((data) => data.AccessStatus === 1)
                  .map((data, index) => (
                    <tr>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {data.OrganizationName}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {new Date(data.CreatedOn)
                          .toISOString()
                          .replace(/[a-zA-Z]/g, " ")}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {new Date(data.LastUsedOn)
                          .toISOString()
                          .replace(/[a-zA-Z]/g, " ")}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {new Date(data.ExpiringOn)
                          .toISOString()
                          .replace(/[a-zA-Z]/g, " ")}
                      </td>
                      <td
                        className="fixed-column1"
                        style={{ gap: "1rem", verticalAlign: "middle" }}
                      >
                        <Button
                          style={{
                            marginRight: ".5rem",
                            backgroundColor: "green",
                            border: "none",
                          }}
                          size="sm"
                          onClick={(e) => handleEditClicked(data)}
                        >
                          Edit
                        </Button>
                        <Button
                          style={{ marginRight: ".5rem", border: "none" }}
                          size="sm"
                        >
                          View
                        </Button>
                        <Button
                          style={{ backgroundColor: "red", border: "none" }}
                          size="sm"
                          onClick={(e) => handleRevokeClick(data)}
                        >
                          Revoke
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="tableDiv2">
            <span
              style={{
                fontSize: "18px",
                fontWeight: "500",
                background: "#fff",
              }}
              className=""
            >
              Past
            </span>
            <Table striped bordered className="mt-2 fixed-size-table">
              <thead>
                <tr>
                  <th className="fixed-column">No.</th>
                  <th className="fixed-column">Organization</th>
                  <th className="fixed-column">Created</th>
                  <th className="fixed-column">Last Used</th>
                  <th className="fixed-column">Expires</th>
                  <th className="fixed-column1">Action</th>
                </tr>
              </thead>
              <tbody>
                {voiceData
                  .filter(
                    (data) => data.AccessStatus === 2 || data.AccessStatus === 3
                  )
                  .map((data, index) => (
                    <tr>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {data.OrganizationName}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {new Date(data.CreatedOn)
                          .toISOString()
                          .replace(/[a-zA-Z]/g, " ")}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {new Date(data.LastUsedOn)
                          .toISOString()
                          .replace(/[a-zA-Z]/g, " ")}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="fixed-column"
                      >
                        {new Date(data.ExpiringOn)
                          .toISOString()
                          .replace(/[a-zA-Z]/g, " ")}
                      </td>
                      <td
                        className="fixed-column1"
                        style={{ verticalAlign: "middle" }}
                      >
                        <Button
                          style={{ border: "none" }}
                          size="sm"
                          onClick={(e) => handleEditClicked(data)}
                        >
                          Renew
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
