import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
// import ReactGA from 'react-ga';

export default function SelectSpeakerVoiceModal({
  selectVoice,
  setSelectVoice,
  
}) {
  const [, setNameList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const voiceList = JSON.parse(localStorage.getItem('voiceList'));
  useEffect(() => {
    setNameList(voiceList);
    setSelectVoice({});
    console.log(voiceList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener(
      'play',
      (event) => {
        const audios = [...document.getElementsByTagName('audio')];
        audios.forEach((audio) => audio !== event.target && audio.pause());
      },
      true
    );
  }, []);

  const filteredNameList = voiceList.filter((list) =>
    list.Name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div>
      <Modal.Header>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="🔍 Search"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <Button
            variant="secondary"
            onClick={(e) => {
              setSearchInput('');
            }}
          >
            Clear
          </Button>
        </InputGroup>
      </Modal.Header>
      <Modal.Body
        className="voicesModal"
        style={{ height: '55vh', overflowY: 'auto' }}
      >
        {filteredNameList &&
          filteredNameList.map((list, index) => (
            <div key={list.Id}>
              <ul style={{ listStyle: 'none' }}>
                <li
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Form.Check
                    type="radio"
                    id={`radio-${list.Id}`}
                    label={list.Name}
                    checked={selectVoice.Name === list.Name}
                    onChange={() => setSelectVoice(list)}
                  />
                  <span>
                    <audio
                      key={list.Id}
                      preload="none"
                      src={`${list.SampleUrl.trim()}?random=${Math.random()}`}
                      controls
                      disablePictureInPicture
                      controlsList="nodownload noplaybackrate"
                      className=" me-5"
                      style={{ height: '40px' }}
                    />
                  </span>
                </li>
              </ul>
            </div>
          ))}
      </Modal.Body>
    </div>
  );
}
