import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { RiEditBoxLine } from 'react-icons/ri';
import { RiDeleteBin6Line } from 'react-icons/ri';
import './voicelib.css';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillSound } from 'react-icons/ai';
import { FaVolumeMute } from 'react-icons/fa';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';

export default function VoicePage1({
  searchText,
  voicesArray,
  getVoices,
  type1,
  age,
  category,
  gender,
  accent,
  type,
  userInfo,
  setUserInfo,
  setVName,
  setDescription,
  setLabels,
  setVId,
  setSampleUrls,
  setIsEditing,
  setVType,
  setNewSliderValues,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);

  const navigate = useNavigate();

  const playOrPauseAudio = (url) => {
    if (currentAudio && currentAudio.src === url) {
      // If the same audio is currently playing, pause it and reset its currentTime
      if (!currentAudio.paused) {
        currentAudio.pause();
        setIsPlaying(false);
        currentAudio.currentTime = 0;
        return;
      }
    }

    // If a different audio is playing, pause it first
    if (currentAudio && !currentAudio.paused) {
      currentAudio.pause();
    }

    // Create a new audio element and play it
    const audio = new Audio(url);
    audio.play();
    setIsPlaying(true);
    setCurrentAudio(audio);
  };

  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('userInfo')]);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      className: 'custom-success-toast',
    });
  };
  function handleAdd() {
    if (type1 === userInfo.LimitVoices) {
      notify('* Maximum limit reached.');
    } else {
      setVType(null);
      setIsEditing(false);
      navigate('/voice-library/addvoice');
      ReactGA.event({
        category: 'Modal',
        action: 'Open',
        label: 'voice_library/add_voice',
      });
      // handleShow();
    }
  }
  async function getVoice(id) {
    const reqBody = {
      VoiceId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setVType(data.VoiceInfo.Type);
          setVName(data.VoiceInfo.Name);
          setDescription(data.VoiceInfo.Description);
          const dataArray = JSON.parse(
            data.VoiceInfo.Labels.replace(/'/g, '"')
          );

          const dataObjectsArray = dataArray.map((item) => {
            const [key, value] = item.split(':').map((part) => part.trim());
            return { key, value: isNaN(value) ? value : Number(value) };
          });
          setLabels(dataObjectsArray);
          setSampleUrls(data.VoiceInfo.VoiceSamples);
          setVId(data.VoiceInfo.Id);
          if (data.VoiceInfo.SoundsLikeParams && data.VoiceInfo.Type === 3) {
            setNewSliderValues(data.VoiceInfo.SoundsLikeParams);
          }
          // handleShow();
          navigate('/voice-library/addvoice');
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleEdit = (id) => {
    setVType(null);
    setIsEditing(true);
    getVoice(id);

    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'voice_library/edit_voice',
    });
  };

  const tagColors = ['#fbf8c5', '#dce1e7', '#f4eaff', '#e6ebff', '#ffcc9959'];

  // delete voice api call function

  function deleteVoice() {
    const req = {
      VoiceId: deleteId,
    };

    fetch(`${apiURL}/dub/delete_voice`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          notifySuccess('Voice Clone successfully deleted!');
          getVoices();
          fetchUserInfo();
          handleDeleteClose();
        } else {
          notify('An error occurred. Please contact support.');
          console.error('Error:', data.Error);
        }
      })
      .catch((error) => {
        notify('An error occurred. Please contact support.');
        console.error('API call failed:', error);
      });
  }

  const filteredVoices = voicesArray.filter((voice) => {
    const lowerCaseSearchText = searchText.toLowerCase();
    const lowerCaseName = voice.Name.toLowerCase();
    const lowerCaseDescription = voice.Description
      ? voice.Description.toLowerCase()
      : '';
    const lowerCaseLabels = voice.Labels
      ? JSON.parse(voice.Labels).join(' ').toLowerCase()
      : '';

    const isMatchingSearchText =
      lowerCaseName.includes(lowerCaseSearchText) ||
      lowerCaseDescription.includes(lowerCaseSearchText) ||
      lowerCaseLabels.includes(lowerCaseSearchText);

    // Additional filtering based on age, category, gender, and accent
    const labelsArray = voice.Labels ? JSON.parse(voice.Labels) : [];
    const isMatchingAge = age
      ? labelsArray.some((label) => {
          const [key, value] = label.split(':').map((part) => part.trim());
          return key === 'age' && value.toLowerCase() === age.toLowerCase();
        })
      : true;

    const isMatchingCategory = category
      ? labelsArray.some((label) => {
          const [key, value] = label.split(':').map((part) => part.trim());
          return (
            key === 'category' && value.toLowerCase() === category.toLowerCase()
          );
        })
      : true;

    const isMatchingGender = gender
      ? labelsArray.some((label) => {
          const [key, value] = label.split(':').map((part) => part.trim());
          return (
            key === 'gender' && value.toLowerCase() === gender.toLowerCase()
          );
        })
      : true;

    const isMatchingAccent = accent
      ? labelsArray.some((label) => {
          const [key, value] = label.split(':').map((part) => part.trim());
          return (
            key === 'accent' && value.toLowerCase() === accent.toLowerCase()
          );
        })
      : true;

    // Filter based on type value
    let isMatchingType = true; // Default to true for displaying all types
    if (type === 'Prebuilt' && voice.Type !== 0) {
      isMatchingType = false;
    } else if (type === 'Custom' && voice.Type !== 1) {
      isMatchingType = false;
    } else if (type === 'Imported' && voice.Type !== 2) {
      isMatchingType = false;
    } else if (type === 'SoundsLike' && voice.Type !== 3) {
      isMatchingType = false;
    }

    return (
      isMatchingSearchText &&
      isMatchingAge &&
      isMatchingCategory &&
      isMatchingGender &&
      isMatchingAccent &&
      isMatchingType
    );
  });

  const maxContentHeight = filteredVoices.reduce((maxHeight, voice) => {
    // Calculate content height for each instance (considering labels, description, and footer)
    const labelsHeight = voice.Labels
      ? JSON.parse(voice.Labels).length * 28
      : 0; // Assuming 28px per label
    const descriptionHeight = voice.Description ? 60 : 0; // Assuming 60px for description
    const footerHeight = 40; // Assuming 50px for the footer
    const contentHeight = labelsHeight + descriptionHeight + footerHeight;

    // Update maxHeight to the maximum content height
    return Math.max(maxHeight, contentHeight);
  }, 0);

  // Set a fixed height for the card bodies
  const fixedCardHeight = maxContentHeight;

  //setting the length of description.
  const maxLength = 150;

  return (
    <div>
      {filteredVoices.length === 0 && searchText !== '' ? (
        <p
          style={{
            marginTop: '20px',
            fontSize: '16px',
            fontWeight: '500',
            color: 'red',
            textAlign: 'center',
          }}
        >
          No voices found with the given search text.
        </p>
      ) : null}
      {/* --------------------------------------------------- */}
      <div className="ms-5 mt-5 voices">
        {searchText === '' &&
        JSON.parse(localStorage.getItem('userInfo')).VoiceCreationAccess ===
          true ? (
          <Card
            style={{ width: '30%', minHeight: `${fixedCardHeight}px` }}
            className="mb-2 addVoiceCard"
          >
            <Card.Header
              style={{ borderBottom: 0, backgroundColor: 'transparent' }}
            ></Card.Header>
            <Card.Body
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <div style={{ height: '100px' }}>
                {' '}
                <Button
                  variant="text"
                  style={{ fontSize: '60px', color: 'gray' }}
                  onClick={(e) => handleAdd()}
                >
                  <IoIosAddCircleOutline />
                </Button>
              </div>
              <div>
                <span style={{ fontSize: '14px' }}>
                  Add Generative or Cloned Voice{' '}
                </span>
                {userInfo !== null ? (
                  <p style={{ fontSize: '14px' }}>
                    {userInfo.UsageVoices} / {userInfo.LimitVoices}{' '}
                  </p>
                ) : null}
              </div>
            </Card.Body>
            <Card.Footer
              style={{ borderTop: 0, backgroundColor: 'transparent' }}
            ></Card.Footer>
          </Card>
        ) : null}

        {filteredVoices.map((voice) => (
          <Card
            key={voice.Id}
            style={{
              width: '30%',
              position: 'relative',
              minHeight: `${fixedCardHeight}px`,
            }}
            className="mb-2 voicesCard"
          >
            <Card.Body>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'space-between',
                }}
              >
                <h4
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    margin: 0,
                  }}
                >
                  {voice.Name}
                </h4>

                <div
                  style={{
                    display: 'flex',
                    marginTop: '4px',
                    gap: '5px',
                    fontSize: '12px',
                    color: 'grey',
                    backgroundColor: 'transparent',
                    paddingTop: 0,
                    paddingBottom: 0,
                    fontWeight: 500,
                  }}
                >
                  <button
                    style={{
                      fontSize: '12px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      border: '1px solid lightgrey',
                      color: 'grey',
                      backgroundColor: 'transparent',
                      borderRadius: '10px',
                      paddingTop: 0,
                      paddingBottom: 0,
                      fontWeight: 500,
                    }}
                    disabled
                  >
                    {voice.ProviderId ===
                      '0CA8D14C-F78B-4F07-BA8F-2E913D1011E4' ||
                    voice.ProviderId === null
                      ? 'CxL'
                      : voice.ProviderId ===
                        '1CA8D14C-F78B-4F07-BA8F-2E913D1011E4'
                      ? 'CxR'
                      : null}
                  </button>
                  <button
                    style={{
                      fontSize: '12px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      border: '1px solid lightgrey',
                      color: 'grey',
                      backgroundColor: 'transparent',
                      borderRadius: '10px',
                      paddingTop: 0,
                      paddingBottom: 0,
                      fontWeight: 500,
                    }}
                    disabled
                  >
                    {voice.Type === 1
                      ? 'CUSTOM'
                      : voice.Type === 2
                      ? 'IMPORTED'
                      : voice.Type === 3
                      ? 'SoundsLike'
                      : 'PREBUILT'}
                  </button>
                </div>
              </div>

              {/*description div here -------------------------------------------- */}

              <div style={{ height: '60px' }}>
                <p className="mt-2" style={{ fontSize: '14px', color: 'grey' }}>
                  {voice.Description
                    ? voice.Description.length > maxLength
                      ? `${voice.Description.slice(0, maxLength)}...`
                      : voice.Description
                    : 'No description provided.'}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '5px',
                  height: 28,
                }}
              >
                {voice.Labels ? (
                  JSON.parse(voice.Labels).map((tag, index) => {
                    // Extracting the key before colon
                    const key = tag.includes(':')
                      ? tag.split(':')[0].trim()
                      : '';

                    // List of keys to filter out
                    const keysToFilter = [];

                    // Check if the key is in the list of keys to filter
                    if (!keysToFilter.includes(key)) {
                      return (
                        <span
                          key={index}
                          style={{
                            backgroundColor:
                              tagColors[index % tagColors.length],
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            margin: '2px',
                            borderRadius: '10px',
                            display: 'inline-block',
                            fontSize: '12px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            width: 'max-content', // Adjust the width of each label as needed
                            height: '24px',
                          }}
                        >
                          {/* Displaying only the value after the colon */}
                          {tag.includes(':') ? tag.split(':')[1].trim() : tag}
                        </span>
                      );
                    } else {
                      // Return null for labels to be filtered out
                      return null;
                    }
                  })
                ) : (
                  <span style={{ fontSize: '14px', color: 'grey' }}>
                    No labels available
                  </span>
                )}
              </div>
            </Card.Body>
            {voice.Type === 1 || voice.Type === 2 || voice.Type === 3 ? (
              <Card.Footer
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 0,
                }}
              >
                <Button
                  variant="text"
                  style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                  onClick={() => playOrPauseAudio(voice.SampleUrl)}
                >
                  {isPlaying && currentAudio.src === voice.SampleUrl ? (
                    <FaVolumeMute />
                  ) : (
                    <AiFillSound />
                  )}
                  <span>Sample</span>
                </Button>
                <Button
                  variant="text"
                  style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                  onClick={(e) => handleEdit(voice.Id)}
                >
                  <RiEditBoxLine />
                  <span>Edit</span>{' '}
                </Button>
                <Button
                  variant="text"
                  style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                  onClick={(e) => {
                    handleDeleteShow();
                    setDeleteId(voice.Id);
                  }}
                >
                  <RiDeleteBin6Line />
                  <span> Remove</span>
                </Button>
              </Card.Footer>
            ) : (
              <Card.Footer
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 0,
                }}
              >
                <Button
                  variant="text"
                  style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                  onClick={() => playOrPauseAudio(voice.SampleUrl)}
                >
                  {isPlaying && currentAudio.src === voice.SampleUrl ? (
                    <FaVolumeMute />
                  ) : (
                    <AiFillSound />
                  )}
                  <span>Sample</span>
                </Button>
              </Card.Footer>
            )}
          </Card>
        ))}

        <Modal show={showDelete} onHide={handleDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Voice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the voice clone?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="danger" onClick={(e) => deleteVoice()}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
}
