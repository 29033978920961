import React from 'react'

export default function Player() {
  return (
    <div style={{height:'60vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
      {/* <div style={{display:'flex',justifyContent:'center',marginTop:'30px'}}>
        <button className='pointer-btn'><FaMousePointer/></button>
        <button className='pointer-btn'><IoHandRightOutline/></button>
        <button className='pointer-btn'><ImCrop/></button>
        <PercentageDropdown/>
      </div> */}
      {/* <div className='vid-player'> */}
        <video  style={{width:'90%'}} controls></video>
      {/* </div> */}
    </div>
  )
}


