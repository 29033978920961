import React, { useEffect, useState } from "react";
import VoicePage1 from "./VoicePage1";

import Form from "react-bootstrap/Form";
import ReactGA from "react-ga";

export default function VoiceLibrary({
  setSearchText,
  searchText,
  userId,
  drmVoiceId,
  setDrmVoiceId,
  userInfo,
  setUserInfo,
  selectedDigitalTwinComponent,
  setSelectedDigitalTwinComponent,
}) {
  const [voicesArray, setVoicesArray] = useState([]);
  // const [voices1Array, setVoices1Array] = useState([]);

  const apiURL = process.env.REACT_APP_API_URL;
  const [type1, setType1] = useState(0);
  const [filterOn] = useState(false);
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [accent, setAccent] = useState("");
  const [type, setType] = useState("Custom");
  const [category, setCategory] = useState("");
  const ageArray = ["Age", "Young", "Middle Aged", "Old"];
  const genderArray = ["Gender", "Male", "Female", "Neutral"];
  const accentArray = [
    "Accent",
    "British",
    "American",
    "African",
    "Australian",
    "Indian",
  ];
  const categoryArray = ["Category", "Professional", "Voice Design"];
  const typeArray = ["All", "Prebuilt", "Custom"];

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory === "Category" ? "" : selectedCategory);
  };
  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setType(selectedType);
  };

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/voice_library");
    ReactGA.set({ page: "/voice_library" });
  }, []);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem("userInfo", JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  const handleGenderChange = (event) => {
    const selectedGender = event.target.value;

    setGender(selectedGender === "Gender" ? "" : selectedGender);
  };

  const handleAgeChange = (event) => {
    const selectedAge = event.target.value;
    setAge(selectedAge === "Age" ? "" : selectedAge);
  };

  const handleAccentChange = (event) => {
    const selectedAccent = event.target.value;
    setAccent(selectedAccent === "Accent" ? "" : selectedAccent);
  };

  const getVoices = async () => {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // Filter elements where Type is 1
          const typeOneVoices = data.VoiceList.filter(
            (voice) => voice.Type === 1
          );

          // Count the number of elements with Type 1
          const numberOfTypeOneVoices = typeOneVoices.length;

          setType1(numberOfTypeOneVoices);
          setVoicesArray(data.VoiceList);
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getDrmVoices = async () => {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
      TopN: 4,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_drm_voices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setVoicesArray(data.VoiceList);
          console.log(voicesArray);
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    document.title = "Voice - Spectral Studio";
    fetchUserInfo();
    getDrmVoices();
    setSearchText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("userInfo")]);

  return (
    <div className="voiceLibrary mt-3">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <h2 className="mt-2 ms-5">Digital Twin</h2>
          {/* <p className="ms-5" style={{ color: "grey" }}>
            Your creative voice toolkit. Discover prebuilt voices or Clone your
            own voice or a voice you have a permission and rights to. Only you
            have access to the voices you create.
          </p> */}
        </div>
        {/* <Button
          className="ms-5"
          style={{
            backgroundColor: "transparent",
            color: "black",
            height: "60px",
            border: "1px solid black",
            marginTop: "10px",
          }}
          onClick={(e) => {
            setFilterOn(!filterOn);
          }}
        >
          <span style={{ fontSize: "20px" }}>
            <IoFilterSharp />
          </span>
          <p style={{ fontSize: "12px" }}>filters</p>
        </Button> */}
      </div>
      {filterOn === true ? (
        <div
          className="ms-5 mt-2"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            width: "60vw",
          }}
        >
          <Form.Select size="sm" onChange={handleTypeChange} value={type}>
            {typeArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select
            size="sm"
            onChange={handleCategoryChange}
            value={category}
          >
            {categoryArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select size="sm" onChange={handleGenderChange} value={gender}>
            {genderArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select size="sm" onChange={handleAgeChange} value={age}>
            {ageArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
          <Form.Select size="sm" onChange={handleAccentChange} value={accent}>
            {accentArray.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </Form.Select>
        </div>
      ) : null}
      <div>
        <VoicePage1
          voicesArray={voicesArray}
          getVoices={getVoices}
          type1={type1}
          searchText={searchText}
          age={age}
          drmVoiceId={drmVoiceId}
          setDrmVoiceId={setDrmVoiceId}
          gender={gender}
          category={category}
          accent={accent}
          type={type}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          selectedDigitalTwinComponent={selectedDigitalTwinComponent}
          setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
        />
      </div>
    </div>
  );
}
