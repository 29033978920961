import React from 'react';
import { Button } from 'react-bootstrap';
import { RiSettingsFill } from 'react-icons/ri';
import { FaPlusSquare } from 'react-icons/fa';
import {
  BsFileMusicFill
} from 'react-icons/bs';
import { MdSubtitles } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import logo from '../multimedia/favicon.png';

export default function LeftNavBar({
  selectedComponent,
  setSelectedComponent,
}) {
  const navigate = useNavigate();
  let ids = ['settings', 'media', 'audio', 'subtitles'];
  for (let i = 0; i < ids.length; i++) {
    let id = ids[i];
    if (selectedComponent === id) {
      let bg = document.getElementById(id);
      if (bg) {
        bg.style.color = 'grey';
      }
    } else {
      let bg = document.getElementById(id);
      if (bg) {
        bg.style.color = '#c9cbd4';
      }
    }
  }
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '2%',
        alignItems: 'center',
      }}
    >
      <Button
        variant="text"
        onClick={(e) => {
          document.title = 'Home - Spectral Studio';
          navigate('/home');
        }}
      >
        <img src={logo} alt="logo" style={{ height: '50px', wifth: '50px' }} />
      </Button>
      <Button variant="text" onClick={(e) => setSelectedComponent('settings')}>
        <span>
          <RiSettingsFill className="icon" id="settings" />
        </span>
        <span style={{ color: 'grey', fontWeight: 500 }}>Settings</span>
      </Button>
      <Button variant="text" onClick={(e) => setSelectedComponent('media')}>
        <span>
          <FaPlusSquare className="icon" id="media" />
        </span>
        <span style={{ color: 'grey', fontWeight: 500 }}>Media</span>
      </Button>
      <Button variant="text" onClick={(e) => setSelectedComponent('audio')}>
        <span>
          <BsFileMusicFill className="icon" id="audio" />
        </span>
        <span style={{ color: 'grey', fontWeight: 500 }}>Audio</span>
      </Button>
      <Button variant="text" onClick={(e) => setSelectedComponent('subtitles')}>
        <span>
          <MdSubtitles className="icon" id="subtitles" />
        </span>
        <span style={{ color: 'grey', fontWeight: 500 }}>Subtitles</span>
      </Button>
    </div>
    // <Navbar expand="lg" >
    //     <Nav className="leftNav flex-column">
    //         <Nav.Item>
    //             <Nav.Link className="link">
    //                 <Button variant='text' className='navBtn'><GiHamburgerMenu className="icon" /></Button>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link className="link"  onClick={() => setSelectedComponent('settings')}>
    //                 <Button variant='text' className='navBtn' ><RiSettingsFill className="icon" /></Button>
    //                 <p>Settings</p>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link onClick={() => setSelectedComponent('media')}>
    //                 <Button variant='text' className='navBtn'> <FaPlusSquare className="icon" /></Button>
    //                 <p>Media</p>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link onClick={() => setSelectedComponent('audio')}>
    //                 <Button variant='text' className='navBtn'> <BsFileMusicFill className="icon" /></Button>
    //                 <p>Audio</p>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link onClick={() => setSelectedComponent('subtitles')}>
    //                 <Button variant='text' className='navBtn' >  <MdSubtitles className="icon" /></Button>
    //                 <p>Subtitles</p>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link onClick={() => setSelectedComponent('text')}>
    //                 <Button variant='text' className='navBtn' ><BsFileFontFill className="icon" /></Button>
    //                 <p>Text</p>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link onClick={() => setSelectedComponent('elements')}>
    //                 <Button variant='text' className='navBtn' > <FaFile className="icon ele" /></Button>
    //                 <p>Elements</p>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link onClick={() => setSelectedComponent('templates')}>
    //                 <Button variant='text' className='navBtn' > <HiTemplate className="icon" /></Button>
    //                 <p>Templates</p>
    //             </Nav.Link>
    //         </Nav.Item>

    //         <Nav.Item>
    //             <Nav.Link onClick={() => setSelectedComponent('record')}>
    //                 <Button variant='text' className='navBtn' ><BsRecordBtnFill className="icon" /></Button>
    //                 <p>Record</p>
    //             </Nav.Link>
    //         </Nav.Item>
    //     </Nav>
    // </Navbar>
  );
}
