import React, { useRef, useState, useEffect } from "react";
import { FiUploadCloud } from "react-icons/fi";
import "./media.css";


export default function AddAudio({
  setDurationInSeconds,
  selectedAudioFile,
  setSelectedAudioFile,
}) {
  const fileInputRef = useRef(null);
  const [audioFiles, setAudioFiles] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const contextMenuRef = useRef(null);

  // function calculateFileDuration(file) {
  //   const [minutes, seconds] = file.duration.split(":").map(Number);
  //   let val = minutes * 60 + seconds;
  //   return val;
    // return new Promise((resolve, reject) => {
    //   const audioElement = document.createElement('audio');

    //   audioElement.onloadedmetadata = () => {
    //     const fileDuration = Math.floor(audioElement.duration);
    //     console.log('file duration is (singlefile)',fileDuration);
    //     resolve(fileDuration);
    //   };

    //   audioElement.onerror = (error) => {
    //     reject(error);
    //   };

    //   audioElement.src = URL.createObjectURL(file);
    // });
  // }

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files);

      setAudioFiles((prevFiles) => [...prevFiles, ...newFiles]);
      calculateAudioDurations(newFiles);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const calculateAudioDurations = async (files) => {
    const updatedFiles = [...audioFiles];
    const updatedPromises = [];

    for (const file of files) {
      const duration = await new Promise((resolve, reject) => {
        const audioElement = document.createElement("audio");
        audioElement.onloadedmetadata = () => {
          const formattedDuration = formatDuration(audioElement.duration);
          // console.log(formattedDuration, 'in audio duration');
          resolve(formattedDuration);
        };
        audioElement.onerror = (error) => {
          reject(error);
        };
        audioElement.src = URL.createObjectURL(file);
      });
      updatedPromises.push({ name: file.name, duration: duration }); // Include file name
    }

    try {
      const durations = await Promise.all(updatedPromises);

      durations.forEach((durationObj, index) => {
        const updatedIndex = audioFiles.length + index;
        updatedFiles[updatedIndex] = {
          ...updatedFiles[updatedIndex],
          duration: durationObj.duration,
          name: durationObj.name, // Add this line to include the name
        };
      });

      // console.log('updated files are', updatedFiles);
      setAudioFiles(updatedFiles);
    } catch (error) {
      console.error("Error calculating audio durations:", error);
    }
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };
  function durationToSeconds(duration) {
    const [minutes, seconds] = duration.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  function handleSelect(reqFile) {
    const selectedFile = audioFiles.find((file) => file.name === reqFile.name);
    // console.log(durationToSeconds(selectedFile.duration))
    if (selectedFile) {
      setSelectedAudioFile({
        name: selectedFile.name,
        duration: durationToSeconds(selectedFile.duration),
      });
    }
  }

  const handleContextMenu = (event, file) => {
    event.preventDefault();
    setContextMenu({
      file: file,
      top: event.clientY,
      left: event.clientX,
    });
  };
  const handleDeleteClick = () => {
    // console.log('Delete clicked for:', contextMenu.file);
    setContextMenu(null);
  };
  const handleClickOutsideContextMenu = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target) &&
      event.target.className !== "dotsButton"
    ) {
      setContextMenu(null);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutsideContextMenu);

    return () => {
      window.removeEventListener("click", handleClickOutsideContextMenu);
    };
  }, []);
  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <h3 className="mt-4">Add Audio</h3>
      <button
        style={{ width: "75%", height: "150px" }}
        className="uploadAudio"
        onClick={handleUploadButtonClick}
      >
        <p style={{ fontSize: "16px", color: "gray" }}>
          <FiUploadCloud />
        </p>
        <p style={{ fontWeight: 500, fontSize: "20px", color: "GrayText" }}>
          Upload a File
        </p>
        <p style={{ color: "grey" }}>Click to browse</p>
      </button>
      <input
        type="file"
        accept=".mp3"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple // Allow multiple file selection
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {audioFiles.map((file, index) => (
          <div
            key={index}
            className="videoInfo"
            onClick={(event) => handleSelect(file)}
          >
            <div
              className="dotsButton"
              onClick={(event) => handleContextMenu(event, file)}
            >
              ...
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "5px",
                right: "5px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "#fff",
                fontSize: "12px",
                padding: "2px 4px",
              }}
            >
              {file.duration}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "#fff",
                fontSize: "8px",
                padding: "10px 0",
                textAlign: "center",
                borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
            >
              {file.name}
            </div>
          </div>
        ))}
      </div>
      {contextMenu && (
        <div
          className="contextMenu"
          style={{
            position: "absolute",
            top: contextMenu.top,
            left: contextMenu.left,
            backgroundColor: "#ededed",
            borderRadius: "3px",
            height: "40px",
            width: "90px",
            display: "flex", // Added flex display
            justifyContent: "center", // Center horizontally
            alignItems: "center",
            paddingRight: "25px",
            paddingTop: "10px",
            fontWeight: 500,
            cursor: "pointer",
          }}
          ref={contextMenuRef}
        >
          <ul style={{ listStyleType: "none " }}>
            <li onClick={handleDeleteClick}>delete</li>
            {/* Add more context menu options as needed */}
          </ul>
        </div>
      )}
    </div>
  );
}
