import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import { CgPassword } from "react-icons/cg";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./profile.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";

export default function Profile({ userInfo, setUserInfo }) {
  const [show, setShow] = useState(false);
  const [showMin, setShowMin] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showMinMessage, setShowMinMessage] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [pwdError, setPwdError] = useState(false);
  const [newPwdError, setNewPwdError] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;
  const handleClose = () => {
    setShow(false);
    setNewPwdError(false);
  };
  const handleShow = () => {
    setShow(true);
    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "profile/change_password",
    });
  };
  const handleDelShow = () => setShowDel(true);
  const handleDelClose = () => {
    setShowDel(false);
    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "delete_account",
    });
  };
  const handleMinClose = () => setShowMin(false);
  const handleMinShow = () => {
    setShowMin(true);
    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "profile/request_minutes",
    });
  };
  const handleCloseMinMessage = () => setShowMinMessage(false);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/profile");
    ReactGA.set({ page: "/profile" });
  }, []);

  const notifySuccess = () => {
    toast.success(
      "Your account deletion request has been successfully sent to the team.",
      {
        className: "custom-success-toast",
      }
    );
  };
  const notifyPwdSuccess = () => {
    toast.success("Password has been changed successfully.", {
      className: "custom-success-toast",
    });
  };

  function reqMinutes() {
    const request = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/request_more_credits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          handleMinClose();
          setShowMinMessage(true);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  function handleAccounDelete() {
    const request = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/delete_account_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          notifySuccess();
          handleDelClose();
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  function reqPWdChange() {
    if (oldPassword !== "" && newPassword !== "") {
      const request = {
        UserId: localStorage.getItem("userSessionId"),
        OldPassword: oldPassword,
        NewPassword: newPassword,
      };
      fetch(`${apiURL}/web/password_change`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the data from the API
          if (data.IsValid) {
            handleClose();
            notifyPwdSuccess();
          } else {
            setPwdError(true);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
        });
    }
  }

  useEffect(() => {
    document.title = "Profile - Spectral Studio";
    fetchUserInfo();
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("userInfo")]);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem("userInfo", JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const monthAbbreviation = months[monthIndex];
    const day = date.getDate().toString().padStart(2, "0");
    return `${day} ${monthAbbreviation} ${year}`;
  };

  return (
    <div className="profile">
      <h2 className="mt-2 ms-5">Profile</h2>
      {/* <div className='syncPage'> */}
      <div className="transcendPage2 mt-4 ms-5 me-5" style={{ height: "75vh" }}>
        <h5 className=" mb-4 text-center">Account settings</h5>
        <div
          className="profileScroll"
          style={{
            height: "90%",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          <Row
            style={{
              paddingLeft: "2%",
              paddingBottom: "2%",
              paddingRight: "2%",
            }}
          >
            {/*column 1 ----------------------------------------------------------------------------------------------------------------------------  */}

            <Col md={6} style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  height: "17vh",
                  width: "100%",
                  border: "1px solid rgb(234 236 240)",
                  padding: "4%",
                  borderRadius: "12px",
                }}
              >
                <Form.Label
                  sm="2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Credits</span>
                  <span>
                    {userInfo.UsageCredits} / {userInfo.LimitCredits} min used
                  </span>
                </Form.Label>
                <ProgressBar
                  now={userInfo.UsageCredits}
                  max={userInfo.LimitCredits}
                  style={{ height: "10px" }}
                />
                <Button
                  className="mt-3"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(11 11 207)",
                    border: "none",
                  }}
                  onClick={(e) => {
                    handleMinShow();
                  }}
                >
                  Request more minutes
                </Button>
              </div>
              {/* <div  style={{height:'11vh',width:'100%',border:'1px solid rgb(234 236 240)',padding:'4%',marginTop:'2vh',borderRadius:'12px'}}>
        <Form.Label sm="2" >
            Shorts Beta       
        </Form.Label>  
        <ProgressBar now={0} style={{height:'10px'}}/>
        </div> */}
              {/* changethe below divs height to 30vh later */}

              {/* <div style={{height:'14vh',width:'100%',border:'1px solid rgb(234 236 240)',padding:'4%',display:'flex',flexDirection:'column',marginTop:'2vh',borderRadius:'12px',justifyContent:'space-evenly'}}> */}
              <div
                style={{
                  height: "11vh",
                  width: "100%",
                  border: "none",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "2vh",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    height: "11vh",
                    backgroundColor: "#f9fafb",
                    padding: "2%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "12px",
                  }}
                >
                  <div>
                    <span className="ms-1" style={{ fontWeight: 600 }}>
                      {userInfo.SubscriptionName}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {userInfo.UserStatus === 2 ? (
                      <span
                        style={{
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          margin: "2px",
                          borderRadius: "10px",
                          backgroundColor: "rgb(236 253 243)",
                          color: "green",
                          width: "max-content",
                        }}
                      >
                        Active
                      </span>
                    ) : userInfo.UserStatus === 0 ? (
                      <span
                        style={{
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          margin: "2px",
                          borderRadius: "10px",
                          backgroundColor: "rgb(236 253 243)",
                          color: "black",
                          width: "max-content",
                        }}
                      >
                        New
                      </span>
                    ) : userInfo.UserStatus === 3 ? (
                      <span
                        style={{
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          margin: "2px",
                          borderRadius: "10px",
                          backgroundColor: "#fae1e3",
                          color: "red",
                          width: "max-content",
                        }}
                      >
                        Inactive
                      </span>
                    ) : userInfo.UserStatus === 1 ? (
                      <span
                        style={{
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          margin: "2px",
                          borderRadius: "10px",
                          backgroundColor: "rgb(236 253 243)",
                          color: "black",
                          width: "max-content",
                        }}
                      >
                        Verified
                      </span>
                    ) : (
                      <span
                        style={{
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          margin: "2px",
                          borderRadius: "10px",
                          backgroundColor: "#fae1e3",
                          color: "red",
                          width: "max-content",
                        }}
                      >
                        Deleted
                      </span>
                    )}
                    <span style={{ color: "grey", fontSize: "14px" }}>
                      {formatDate(userInfo.StartDate)} -{" "}
                      {formatDate(userInfo.EndDate)}
                    </span>
                  </div>
                </div>
                {/* <span  style={{color:'grey',fontSize:'14px'}}>Your subscription will automatically renew on {formatDate(userInfo.EndDate)} at $140 per month.</span>
        <div  style={{display:'flex',flexDirection:'row',gap:'10px',width:'100%',marginTop:'2%'}}>
        <Button style={{width:'100%'}} variant='outline-secondary' >Manage billing</Button>
                            <Button style={{backgroundColor:'rgb(11 11 207)',border:'1px solid rgb(11 11 207)',width:'100%'}}>Upgrade plan</Button>
        </div>
        <Button  style={{color:'grey',width:'max-content',marginTop:'2%'}} variant='text'>Cancel subscription</Button> */}
              </div>
            </Col>

            {/* column 2 ------------------------------------------------------------------------------------------------------------------------------------------------- */}

            <Col md={6} style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  height: "30vh",
                  width: "100%",
                  border: "1px solid rgb(234 236 240)",
                  padding: "4%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  justifyContent: "space-between",
                }}
              >
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    readOnly
                    value={userInfo.Email}
                    type="email"
                    placeholder="name@example.com"
                  />
                </Form.Group>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Email updates on new features and news"
                />
                <Button
                  onClick={handleShow}
                  variant="text"
                  style={{
                    width: "max-content",
                    color: "gray",
                    fontWeight: 600,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>
                      <CgPassword />
                    </span>
                    <span>Change password</span>
                  </div>
                </Button>
                <Button
                  variant="text"
                  style={{
                    width: "max-content",
                    color: "gray",
                    fontWeight: 600,
                  }}
                  onClick={(e) => {
                    handleDelShow();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>
                      <RiDeleteBin6Line />
                    </span>
                    <span>Delete my acccout</span>
                  </div>
                </Button>
              </div>
              {/* <div style={{height:'10vh',backgroundColor:'rgb(245 245 255)',borderRadius:'12px',width:'100%',marginTop:'2vh',padding:'2%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <div style={{width:'30%',display:'flex',flexDirection:'column'}}>
                             <span style={{fontWeight:600}}>Start using our API</span>
                             <span style={{fontSize:'14px',color:'grey'}}>Upgrade to business or enterprise plan to connect</span>
                        </div>
                        <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
                            <Button variant='outline-primary' >Read documentation</Button>
                            <Button style={{backgroundColor:'rgb(11 11 207)',border:'1px solid rgb(11 11 207)'}}>Upgrade plan</Button>
                        </div>
                        </div>  */}
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
      {/*  Modal for password change ---------------------------------------------------------------------------------------------------->*/}
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Account settings</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "4%", paddingRight: "4%" }}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            value={userInfo.Email}
            readOnly
            type="email"
            placeholder="name@example.com"
          />
          <Form.Label className="mt-3">Old Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Old Password"
            onChange={(e) => {
              setPwdError(false);
              setOldPassword(e.target.value);
            }}
          />
          {pwdError ? (
            <p style={{ color: "red", textAlign: "center", fontSize: "14px" }}>
              Invalid password
            </p>
          ) : null}
          <Form.Label className="mt-3">New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="New Password"
            onChange={(e) => setNewPwdError(false)}
            onBlur={(e) => {
              const password = e.target.value;
              const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/; // Regex pattern
              const isValidPassword = regex.test(password);
              if (isValidPassword) {
                setNewPassword(password);
              } else {
                setNewPwdError(true);
                // console.log("Password must contain at least 1 capital letter, 1 special character, and 1 number, and be at least 8 characters long.");
              }
            }}
          />
          {newPwdError ? (
            <p className="mt-1" style={{ color: "red", fontSize: "14px" }}>
              Password must contain at least 1 capital letter, 1 special
              character, and 1 number, and be at least 8 characters long.
            </p>
          ) : null}

          <div className="mt-4 mb-3">
            <Row>
              <Col>
                <Button
                  variant="secondary"
                  block
                  onClick={handleClose}
                  style={{ width: "100%" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  block
                  onClick={(e) => reqPWdChange()}
                  style={{ width: "100%" }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      {/*  Modal for request more minutes ---------------------------------------------------------------------------------------------------->*/}
      <Modal show={showMin} onHide={handleMinClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Request for more minutes</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "4%", paddingRight: "4%" }}>
          <p>Are you sure you want to request for more minutes?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" block onClick={handleMinClose}>
            No
          </Button>

          <Button variant="primary" block onClick={(e) => reqMinutes()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Modal for request more minutes confirmation ---------------------------------------------------------------------------------------------------->*/}
      <Modal
        show={showMinMessage}
        onHide={handleCloseMinMessage}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation of minutes request</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "4%", paddingRight: "4%" }}>
          <p>Your request has been submitted.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" block onClick={handleCloseMinMessage}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Modal for account delete request ---------------------------------------------------------------------------------------------------->*/}
      <Modal show={showDel} onHide={handleDelClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete account</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "4%", paddingRight: "4%" }}>
          <p>Are you sure you want to delete your account?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" block onClick={handleDelClose}>
            Cancel
          </Button>

          <Button variant="primary" block onClick={(e) => handleAccounDelete()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
