import React, { useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import { FaVideo } from "react-icons/fa";
export default function Video({
  selectedVideoFile,
  setSelectedVideoFile,
  initialMarkings,
  setInitialMarkings,
  xPosition,
  setXPosition,
  reqMarking,
  setReqMarking,
  positionSettings,
  min,
  setMin,
  zoomLevel,
  setZoomLevel,
  lock,
  setLock,
}) {
  const [prevScrollLeft] = useState(0);

  const videoFileContainerRef = useRef(null);
  const markingsDiv = document.getElementById("markerScale");
  if (markingsDiv) {
    const scaleWidth = markingsDiv.clientWidth;
    var markingInterval = scaleWidth / initialMarkings;
  }
  if (selectedVideoFile.duration > initialMarkings) {
    const videoContainer = document.getElementById("video");
    const addVidBtn = document.getElementById("addVidBtn");
    videoContainer.style.height = "75px";
    addVidBtn.style.height = "75px";
  }
  useEffect(() => {
    const container = videoFileContainerRef.current;

    const handleVideoFileContainerScroll = () => {
      setLock(true);
      if (container && initialMarkings >= 25) {
        const currentScrollLeft = container.scrollLeft;
        const scrollDirection =
          currentScrollLeft > prevScrollLeft ? "right" : "left";
        const vidELement = document.getElementById("videoFile");
        // const scrollDistance = currentScrollLeft - prevScrollLeft;

        // Log scrollDirection and scrollDistance
        console.log("Scroll Direction:", scrollDirection);
        console.log("Scroll Distance:", container.scrollLeft);
        // const totalWidth = vidELement.offsetWidth;
        // const rightWidth =
        //   totalWidth - Math.round(markingInterval * initialMarkings);
        // const rMarkings = Math.round(rightWidth / markingInterval);

        // Do something based on the scrollDirection
        if (scrollDirection === "left") {
        } else if (scrollDirection === "right") {
          console.log(
            "Scrolled to the right",
            container.scrollLeft,
            "zoom level is :",
            zoomLevel,
            "width of the video is ",
            vidELement.offsetWidth
          );
        }
      }
    };

    if (container) {
      container.addEventListener("scroll", handleVideoFileContainerScroll);

      return () => {
        container.removeEventListener("scroll", handleVideoFileContainerScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevScrollLeft, initialMarkings, positionSettings]);

  function handleDragStop(e, d) {
    const markingsDiv = document.getElementById("markerScale");
    if (markingsDiv) {
      const scaleWidth = markingsDiv.clientWidth;
      const markingInterval = scaleWidth / initialMarkings;
      const reqRead = d.x / markingInterval;
      setReqMarking(reqRead);
      console.log("d.x", d.x);
      console.log(reqRead * markingInterval);
      setXPosition(reqRead * markingInterval);
      //  setPosition({x:reqRead*markingInterval,y:d.y});
    }
  }
  useEffect(() => {
    console.log("req markings are ", reqMarking);
    console.log("selectedVideoFile is ", selectedVideoFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var condition = selectedVideoFile.duration > 0;
  return (
    <div className="videoFileContainer" id="video" ref={videoFileContainerRef}>
      {condition === true ? (
        <Rnd
          id="videoFile"
          bounds="parent"
          dragAxis="x"
          size={{
            width: `${markingInterval * selectedVideoFile.duration}px`,
            height: "60px",
          }}
          position={{ x: xPosition, y: 2 }}
          onDragStop={(e, d) => handleDragStop(e, d)}
          //  onResizeStop={(e)=>handleResizeStop()}
          style={{
            backgroundColor: "gray",
            width: `${markingInterval * selectedVideoFile.duration}px`,
            // outline: '2px solid black',
            borderRadius: "5px",
            textAlign: "center",
            border: "1px solid orange",
          }}
        >
          {selectedVideoFile.name}
        </Rnd>
      ) : (
        <div className="vidContainerMessage">
          <p
            style={{
              verticalAlign: "middle",
              color: "white",
              transform: "translate(0,50%)",
              fontSize: "20px",
            }}
          >
            video{" "}
            <span style={{ marginLeft: "10px" }}>
              <FaVideo />
            </span>
          </p>
        </div>
      )}
    </div>
  );
}
