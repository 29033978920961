import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./spectralTranslate.css";
import { FaUpload, FaGoogleDrive } from "react-icons/fa6";
import { GrClose } from "react-icons/gr";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "../../../multimedia/loading.gif";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaYoutube } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactGA from "react-ga";
import axios from "axios";
import FileValidation from "../../../utils/FileValidation";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function TranslateCreate({
  setSelectedTranslateComponent,
  projectId,
  setProjectId,
  projectData,
  setProjectData,
  pName,
  setPName,
  setUserInfo,
  projectName,
  setProjectName,
}) {
  const navigate = useNavigate();
  const [language, setLanguage] = useState([]);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const fileInputRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorFlag] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const apiURL = process.env.REACT_APP_API_URL;
  const [videoSourceUrl, setVideoSourceUrl] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isYoutube, setISYoutube] = useState(false);
  const [isGDrive, setIsGDrive] = useState(false);
  const [urlVal, setUrlVal] = useState("");
  const [durationError, setDurationError] = useState(false);
  const [timeCost, setTimeCost] = useState(0);
  const userDetails = JSON.parse(localStorage.getItem("userInfo"));
  const [languageCount, setLanguageCount] = useState(0);
  const [originalLanguageId, setOriginalLanguageId] = useState(null);
  const [driveThumbnail, setDriveThumbnail] = useState("");
  const [driveTitle, setDriveTitle] = useState("");
  const [clickedOk, setClickedOk] = useState(false);
  const [wait, setWait] = useState(false);
  const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;

  // function to select the source or original language for the video
  function selectOriginalLanguage(value) {
    if (value === "Auto Detect") {
      setOriginalLanguageId(null);
    } else {
      const reqLanguage = language.find((lang) => lang.Name === value);
      if (reqLanguage) {
        setOriginalLanguageId(reqLanguage.Id);
      }
    }
  }

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/spectral-translate/create");
    ReactGA.set({ page: "/spectral-translate/create" });
  }, []);

  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("userInfo")]);

  function fetchUserInfo(id) {
    const reqBody = {
      UserId: id,
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem("userInfo", JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  function fetchLanguageName() {
    setLanguage(JSON.parse(localStorage.getItem("languagesList")));
  }

  useEffect(() => {
    fetchLanguageName();
  }, []);

  useEffect(() => {
    if (
      projectData.ProjectName !== "" &&
      projectData.ProjectId !== "" &&
      projectData.VideoUrl !== ""
    ) {
      setProjectName(projectData.ProjectName);
      setPName(projectData.ProjectName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (file !== null) {
      setVideoSourceUrl(URL.createObjectURL(file));
    }
  }, [file]);

  function uploadFile(selectedFile) {
    if (!FileValidation(selectedFile.name)) {
      return; // Exit the function if validation fails
    }
    const selectedLanguages = Object.keys(checkboxValues).filter(
      (language) => checkboxValues[language]
    );
    const formData = new FormData();
    formData.append("filename", selectedFile.name);
    formData.append("file", selectedFile);
    // console.log(formData);
    axios
      .post(`${apiURL}/util/file_upload_aws`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted); // Assuming setUploadProgress is a function to update the UI with the percentage
        },
      })
      .then((response) => {
        const res = response.data;
        if (res.IsValid) {
          const id = localStorage.getItem("userSessionId");
          if (res.Url && id) {
            createProject(res.Url, id, selectedLanguages);
          }
        } else {
          notify("Upload unsuccessful, please upload a valid file!");
          console.error("Failed to upload file");
        }
      })
      .catch((error) => {
        setSubmitted(false);
        console.error("Error occurred during upload:", error);
      });
  }

  const clearSearchInput = () => {
    setSearchInput("");
  };

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  const [checkboxValues, setCheckboxValues] = useState(
    language.reduce((acc, language) => {
      acc[language] = false;
      return acc;
    }, {})
  );
  const isAnyCheckboxChecked = Object.values(checkboxValues).some(
    (value) => value
  );
  const handleCheckboxChange = (language) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [language]: !prevValues[language],
    }));
  };

  useEffect(() => {
    const numSelectedLanguages = Object.values(checkboxValues).filter(
      (value) => value
    ).length;
    setLanguageCount(numSelectedLanguages);
  }, [checkboxValues]);

  const filteredLanguageList = language.filter((list) =>
    list.Name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    setFile(selectedFile);
    // Create a video element
    const video = document.createElement("video");

    // Set the source of the video element to the selected file
    video.src = URL.createObjectURL(selectedFile);

    // Once metadata is loaded, retrieve the duration
    video.onloadedmetadata = () => {
      // Duration is in seconds
      const durationInSeconds = video.duration;
      if (durationInSeconds > userDetails.LimitVideoLength * 60) {
        setDurationError(true);
        localStorage.setItem("duration", userDetails.LimitVideoLength * 60);
        calculateTimeCost(userDetails.LimitVideoLength * 60);
      } else {
        calculateTimeCost(durationInSeconds);
        localStorage.setItem("duration", video.duration);
      }
    };
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    setFile(selectedFile);
  };

  const handleDelete = () => {
    setFile(null);
    setUrl("");
    setErrorMessage(false);
    setTimeCost(0);
    setISYoutube(false);
    setIsGDrive(false);
    setUrlVal("");
    setDurationError(false);
    setClickedOk(false);
    setWait(false);
  };

  // function for create project api call
  function createProject(fileUrl, id, selectedLanguages) {
    if (
      projectData.ProjectId === "" &&
      projectData.ProjectName === "" &&
      projectData.VideoUrl === ""
    ) {
      if (
        timeCost * languageCount + userDetails.UsageCredits <=
        userDetails.LimitCredits
      ) {
        setSubmitted(true);
        const request = {
          Name: projectName,
          OrgId: null,
          UserId: id,
          Type: 1,
          SourceLangId: originalLanguageId,
          VideoUrl: fileUrl,
          DubbingLanguageList: selectedLanguages,
        };

        fetch(`${apiURL}/web/create_project`, {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(request),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.IsValid === true) {
              fetchUserInfo(id);
              setProjectId(data.ProjectId);
              projectProcess(
                fileUrl,
                selectedLanguages,
                localStorage.getItem("userSessionId"),
                data.ProjectId
              );
            } else {
              setSubmitted(false);
              console.error("Error:", data.Error);
            }
          })
          .catch((error) => {
            setSubmitted(false);
            console.error("API call failed:", error);
          });
      } else {
        setSubmitted(false);
        notify("Credits exceeding limit.");
      }
    } else {
      setProjectId(projectData.ProjectId);
      projectProcess(
        projectData.VideoUrl,
        selectedLanguages,
        localStorage.getItem("userSessionId"),
        projectData.ProjectId
      );
    }
  }

  // function to call process api
  function projectProcess(fileUrl, selectedLanguages, id, pId) {
    let requestBody = {
      VideoUrl: fileUrl,
      DubbingLanguageList: selectedLanguages,
      UserId: id,
      ProjectId: pId,
    };

    fetch(`${apiURL}/dub/process`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          setPName(projectName);
          setProjectId(data.ProjectId);
          setSelectedTranslateComponent("translate2");
          navigate(`/translate/${data.ProjectId.toLowerCase()}/result`);
        } else {
          setSubmitted(false);
          console.error("Error:", data.Error);
        }
      })
      .catch((error) => {
        setSubmitted(false);
        console.error("API call failed:", error);
      });
  }

  // function that is called on click of submit button
  function handleClick(e) {
    if (
      isAnyCheckboxChecked &&
      (file !== null || url.trim() !== "" || projectData.VideoUrl !== "") &&
      projectName !== ""
    ) {
      e.currentTarget.disabled = true;
      setSubmitted(!submitted);
      if (file !== null) {
        uploadFile(file);
      } else if (url.trim() !== "" && isYoutube) {
        const selectedLanguages = Object.keys(checkboxValues).filter(
          (language) => checkboxValues[language]
        );
        const id = localStorage.getItem("userSessionId");
        let reqUrl = embeddedToNormalUrl(url);
        createProject(reqUrl, id, selectedLanguages);
      } else if (url.trim() !== "" && isGDrive) {
        const selectedLanguages = Object.keys(checkboxValues).filter(
          (language) => checkboxValues[language]
        );
        const id = localStorage.getItem("userSessionId");
        createProject(url, id, selectedLanguages);
      } else if (projectData.VideoUrl !== "") {
        const selectedLanguages = Object.keys(checkboxValues).filter(
          (language) => checkboxValues[language]
        );
        createProject(
          projectData.VideoUrl,
          projectData.ProjectId,
          selectedLanguages
        );
      } else if (url.trim() !== "" && localStorage.getItem("userSessionId")) {
        const selectedLanguages = Object.keys(checkboxValues).filter(
          (language) => checkboxValues[language]
        );
        const id = localStorage.getItem("userSessionId");

        let requestBody = {
          VideoUrl: url,
          DubbingLanguageList: selectedLanguages,
          UserId: id,
        };

        fetch("https://api.spectralstudios.ai/dub/process", {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.IsValid === true) {
              setProjectId(data.ProjectId);
              setSelectedTranslateComponent("translate2");
              navigate(`/translate/${data.ProjectId.toLowerCase()}/result`);
            } else {
              setSubmitted(false);
              console.error("Error:", data.Error);
            }
          })
          .catch((error) => {
            setSubmitted(false);
            console.error("API call failed:", error);
          });
      }
    } else {
      let errorMessage = "";

      if (!isAnyCheckboxChecked) {
        notify("Please select at least one target language.");
      }
      if (projectName === "") {
        notify("Please Enter a Project Name.");
      }
      if (file === null && projectData.VideoUrl === "" && url.trim() === "") {
        if (errorMessage !== "") {
          errorMessage += "\n";
        }
        notify(
          "Please upload a file or enter a YouTube URL or Google drive URL."
        );
      }

      if (errorMessage !== "") {
        notify(errorMessage);
      }
    }
  }

  function embedYouTubeUrl(value) {
    // Regular expression for validating YouTube URL
    const youtubeRegex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    if (youtubeRegex.test(value)) {
      // Extract video ID from the URL
      const videoId = value.match(youtubeRegex)[1];
      // Construct the embedded URL
      const embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
      setUrl(embeddedUrl);
      setISYoutube(true);
      setIsGDrive(false);
    }
  }

  function embeddedToNormalUrl(embeddedUrl) {
    const videoId = embeddedUrl.match(/embed\/([^&?]*)/)[1];
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  // function that calls api that handles url validation.
  function handleUrl(value) {
    if (value !== "") {
      setClickedOk(true);
      setWait(true);
      const req = {
        UserId: localStorage.getItem("userSessionId"),
        VideoUrl: value,
      };

      fetch(`${apiURL}/web/video_info`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(req),
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            data.IsValid === true &&
            (data.VideoSource === 0 || data.VideoSource === 1)
          ) {
            if (data.VideoSource === 0) {
              embedYouTubeUrl(value);
              setWait(false);

              if (data.Duration > userDetails.LimitVideoLength * 60) {
                localStorage.setItem(
                  "duration",
                  userDetails.LimitVideoLength * 60
                );
                calculateTimeCost(userDetails.LimitVideoLength * 60);
                setDurationError(true);
              } else {
                localStorage.setItem("duration", data.Duration);
                calculateTimeCost(data.Duration);
              }
            } else if (data.VideoSource === 1) {
              setISYoutube(false);
              setIsGDrive(true);
              setUrl(value);
              setWait(false);

              setDriveThumbnail(data.ThumbnailUrl);
              setDriveTitle(data.VideoName);
              if (data.Duration > userDetails.LimitVideoLength * 60) {
                localStorage.setItem(
                  "duration",
                  userDetails.LimitVideoLength * 60
                );
                calculateTimeCost(userDetails.LimitVideoLength * 60);
                setDurationError(true);
              } else {
                calculateTimeCost(data.Duration);
                localStorage.setItem("duration", data.Duration);
              }
            }
          } else {
            notify(
              "There seems to be some issue with the video link, please try another valid link"
            );
            setWait(false);
          }
        })
        .catch((error) => {
          // console.error('API call failed:', error);
        });
    }
  }

  function handleUrlClear() {
    setUrlVal("");
    setClickedOk(false);
  }

  // function to calculate the timecost credits for the video
  function calculateTimeCost(duration) {
    const timeInterval = 30;
    const maxDuration = userDetails.LimitVideoLength * 60;

    if (duration <= 0) {
      setTimeCost(0);
    } else if (duration < maxDuration) {
      if (duration <= timeInterval) {
        const timeCostValue = 0.5;
        setTimeCost(timeCostValue);
      } else {
        const intervals = Math.ceil(duration / timeInterval);
        const timeCostValue = intervals * 0.5;
        setTimeCost(timeCostValue);
      }
    } else {
      const timeCostValue = Math.floor(maxDuration / timeInterval) * 0.5;
      setTimeCost(timeCostValue);
    }
  }

  return (
    // <div className='translatePage'>
    <div style={{ padding: "1%" }}>
      <Row>
        <Col md={8} sm={12}>
          <div
            className="translateFileUploadContainer"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <div
              className="mb-3"
              controlId="formPlaintext"
              style={{
                position: "absolute",
                top: 20,
                left: 25,
                width: "90%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span className="me-3 ms-2">Project Name :</span>
              <div style={{ position: "relative", width: "75%" }}>
                <Form.Control
                  type="text"
                  placeholder="Enter Project Name"
                  style={{ width: "80%" }}
                  defaultValue={projectName}
                  onBlur={(e) => {
                    setProjectName(e.target.value);
                  }}
                />
              </div>
            </div>
            {file !== null ||
            url.trim() !== "" ||
            projectData.VideoUrl !== "" ? (
              <Button
                variant="text"
                className="delete-button"
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  fontWeight: 700,
                }}
                onClick={handleDelete}
              >
                <GrClose />
              </Button>
            ) : null}
            {file === null &&
            projectData.VideoUrl === "" &&
            url.trim() === "" ? (
              <div style={{ width: "80%" }}>
                <Button
                  variant="text"
                  style={{ fontSize: "50px", marginBottom: "10px" }}
                  onClick={handleUploadClick}
                >
                  <FaUpload />
                </Button>
                <p style={{ color: "black" }}>
                  Drop your video here, or click to browse
                </p>

                <p style={{ color: "black" }}>
                  Paste video URL ( We currently support links from{" "}
                  <FaYoutube /> YouTube and <FaGoogleDrive /> Google Drive. )
                </p>
                <InputGroup size="sm" className="mb-3 ">
                  <InputGroup.Text
                    id="inputGroup-sizing-sm"
                    style={{ color: "black", borderColor: "grey" }}
                  >
                    URL
                  </InputGroup.Text>
                  <Form.Control
                    style={{ borderColor: "grey" }}
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={(e) => setUrlVal(e.target.value)}
                    onPaste={(e) => handleUrl(e.clipboardData.getData("text"))}
                    value={urlVal}
                  />
                  {clickedOk === false ? (
                    <Button
                      variant="secondary"
                      onClick={(e) => handleUrl(urlVal)}
                    >
                      OK
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        handleUrlClear();
                      }}
                    >
                      X
                    </Button>
                  )}
                </InputGroup>
                {wait === true ? (
                  <span className="ms-2">
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={loading}
                      alt="loading"
                    />
                  </span>
                ) : null}
                {errorFlag === 1 || errorFlag === 2 ? (
                  <p style={{ color: "red" }}>{errorMessage}</p>
                ) : null}
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  accept=".mp4"
                  onChange={handleFileChange}
                />
              </div>
            ) : (
              <div style={{ position: "absolute" }}>
                {/* Note: styling for video className sourceVideo is in spectralTranscend.css */}

                {file !== null ? (
                  isFirefox ? (
                    <video controls className="sourceVideo">
                      <source
                        src={URL.createObjectURL(file)}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <video
                      type="video/mp4"
                      className="sourceVideo"
                      src={videoSourceUrl}
                      controls
                    />
                  )
                ) : projectData.VideoUrl !== "" ? (
                  <video
                    src={projectData.VideoUrl}
                    className="sourceVideo"
                    controls
                  />
                ) : url !== "" && isYoutube ? (
                  <iframe
                    className="youtubeVideo"
                    src={url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                ) : url !== "" && isYoutube === false ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "30vw",
                      minHeight: "max-content",
                      border: "1px solid #dee2e6",
                      justifyContent: "space-between",
                      padding: "4%",
                      borderRadius: "8px",
                    }}
                  >
                    {driveThumbnail !== "" &&
                    driveThumbnail !== null &&
                    driveThumbnail !== undefined ? (
                      <img
                        src={driveThumbnail}
                        alt=""
                        style={{
                          height: "60px",
                          width: "120px",
                          borderRadius: "6px",
                        }}
                      />
                    ) : null}
                    {driveTitle !== "" &&
                    driveTitle !== null &&
                    driveTitle !== undefined ? (
                      <p style={{ fontWeight: 600 }}>{driveTitle}</p>
                    ) : null}
                    <Button
                      className="mb-2"
                      variant="text"
                      style={{ padding: 0, fontSize: "20px" }}
                      onClick={handleDelete}
                    >
                      <RiDeleteBin6Line />
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
            {durationError ? (
              <div
                style={{
                  textAlign: "center",
                  position: "absolute",
                  top: "65vh",
                }}
              >
                <p style={{ color: "red" }}>
                  Video length is longer than {userDetails.LimitVideoLength}{" "}
                  mins so it will be trimmed automatically.
                </p>
              </div>
            ) : null}
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="translateLanguageContainer">
            <div>
              <p className="mt-2">Original language :</p>
              <Form.Group
                controlId="originalLanguageSelect"
                className="mb-3 mt-3"
              >
                <Form.Select
                  style={{ color: "black", borderColor: "grey" }}
                  value={language.Name}
                  onChange={(e) => {
                    selectOriginalLanguage(e.target.value);
                  }}
                >
                  <option value="Auto Detect">Auto Detect</option>
                  {language
                    .filter((language) => language.SourceProvider !== null)
                    .map((language) => (
                      <option key={language.Id} value={language.Name}>
                        {language.Name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <p style={{ color: "black" }}>Select Target Language(s) :</p>
              <div style={{ position: "relative" }}>
                <Form.Control
                  size="sm"
                  className="mt-3 mb-3"
                  type="text"
                  placeholder="Search Languages"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  style={{ borderColor: "grey" }}
                />
                {searchInput && (
                  <Button
                    variant="light"
                    size="sm"
                    onClick={clearSearchInput}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: "4%",
                      transform: "translateY(-50%)",
                      padding: "0 5px",
                      cursor: "pointer",
                      border: "none",
                      background: "none",
                      fontSize: "1rem",
                    }}
                  >
                    <IoClose />
                    {/* &times; */}
                  </Button>
                )}
                <div className="languageList">
                  {filteredLanguageList
                    .filter((language) => language.TargetProvider !== null)
                    .map((language, index) => (
                      <Form key={index}>
                        <div className="mb-2 ">
                          <Form.Check
                            size="sm"
                            style={{ color: "black" }}
                            type="checkbox"
                            id={`checkbox-${index}`}
                            checked={checkboxValues[language.Name]}
                            label={language.Name}
                            onChange={() => {
                              handleCheckboxChange(language.Name);
                            }}
                          />
                        </div>
                      </Form>
                    ))}
                </div>
              </div>
            </div>
            <div
              className="mt-auto"
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid rgb(222 226 230)",
                paddingTop: "20px",
              }}
            >
              <span style={{ marginRight: "2%" }}>Credit usage</span>
              <span style={{ marginLeft: "2%" }}>
                {languageCount === 0 ? timeCost : timeCost * languageCount} min
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12}>
          <div className="submitBtnContainer">
            {submitted === false ? (
              <button className="submitBtn" onClick={(e) => handleClick(e)}>
                Submit
              </button>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <button
                  className="submitBtnDisabled"
                  onClick={(e) => handleClick(e)}
                  disabled
                >
                  Uploading
                </button>
                <span>
                  {uploadPercentage < 100 && url === "" ? (
                    <div
                      style={{
                        width: "60px",
                        height: "60px",
                        marginTop: "20px",
                      }}
                    >
                      <CircularProgressbar
                        styles={buildStyles({
                          textSize: "30px",
                          trailColor: "grey",
                        })}
                        value={uploadPercentage}
                        text={`${uploadPercentage}%`}
                      />
                    </div>
                  ) : null}
                  {uploadPercentage > 99 || url !== "" ? (
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        marginTop: "10px",
                      }}
                      src={loading}
                      alt="loading"
                    />
                  ) : null}
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
}
