import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiEditBoxLine } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./voicelib.css";
import Modal from "react-bootstrap/Modal";
import AddVoice from "./AddVoice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillSound } from "react-icons/ai";
import { FaVolumeMute } from "react-icons/fa";
import ReactGA from "react-ga";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

export default function VoicePage1({
  searchText,
  voicesArray,
  voices1Array,
  getVoices,
  drmVoiceId,
  setDrmVoiceId,
  type1,
  age,
  selectedDigitalTwinComponent,
  setSelectedDigitalTwinComponent,
  category,
  gender,
  accent,
  type,
  userInfo,
  setUserInfo,
}) {
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const [voice, setVoice] = useState(null);
  const [samples, setSamples] = useState([]);
  const [sampleCount, setSampleCount] = useState(0);
  const [labels, setLabels] = useState([]);
  const [vName, setVName] = useState("");
  const [description, setDescription] = useState("");
  const [flag, setFlag] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sampleUrls, setSampleUrls] = useState();
  const [vId, setVId] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [tabKey, setTabKey] = useState("");
  const [existingVoiceId, setExistingVoiceId] = useState("");
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);

  function close() {
    if (flag === false) {
      setDescription("");
      setSamples([]);
      setVName("");
      setLabels([]);
      setVId("");
      setSampleUrls([]);
      handleClose();
      setExistingVoiceId("");
      setIsPolicyChecked(false);
    }
  }

  const playOrPauseAudio = (url) => {
    if (currentAudio && currentAudio.src === url) {
      // If the same audio is currently playing, pause it and reset its currentTime
      if (!currentAudio.paused) {
        currentAudio.pause();
        setIsPlaying(false);
        currentAudio.currentTime = 0;
        return;
      }
    }

    // If a different audio is playing, pause it first
    if (currentAudio && !currentAudio.paused) {
      currentAudio.pause();
    }

    // Create a new audio element and play it
    const audio = new Audio(url);
    audio.play();
    setIsPlaying(true);
    setCurrentAudio(audio);
  };

  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("userInfo")]);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem("userInfo", JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      className: "custom-success-toast",
    });
  };
  function handleAdd() {
    if (type1 === userInfo.LimitVoices) {
      notify("* Maximum limit reached.");
    } else {
      setIsEditing(false);
      ReactGA.event({
        category: "Modal",
        action: "Open",
        label: "voice_library/add_voice",
      });
      handleShow();
    }
  }
  async function getVoice(id) {
    const reqBody = {
      VoiceId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setVName(data.VoiceInfo.Name);
          setDescription(data.VoiceInfo.Description);
          const dataArray = JSON.parse(
            data.VoiceInfo.Labels.replace(/'/g, '"')
          );

          const dataObjectsArray = dataArray.map((item) => {
            const [key, value] = item.split(":").map((part) => part.trim());
            return { key, value: isNaN(value) ? value : Number(value) };
          });
          setLabels(dataObjectsArray);
          setSampleUrls(data.VoiceInfo.VoiceSamples);
          setVId(data.VoiceInfo.Id);
          handleShow();
        }
      } else {
        throw new Error("Failed to fetch project data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleEdit = (id) => {
    setIsEditing(true);
    getVoice(id);

    ReactGA.event({
      category: "Modal",
      action: "Open",
      label: "voice_library/edit_voice",
    });
  };

  const tagColors = ["#fbf8c5", "#dce1e7", "#f4eaff", "#e6ebff", "#ffcc9959"];

  function uploadFiles(samples) {
    setFlag(true);
    const formData = new FormData();
    samples.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    // console.log(formData);
    axios
      .post(`${apiURL}/util/multifile_upload_aws`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted); // Assuming setUploadProgress is a function to update the UI with the percentage
        },
      })
      .then((response) => {
        const res = response.data;
        if (res.IsValid) {
          const fileObjects = res.Urls.map((url) => {
            const fileName = url.split("/").pop(); // Extracting name from URL
            // Remove timestamp and anything before the first underscore
            const originalFileName = fileName.split("_").slice(1).join("_");
            return {
              Id: null,
              VoiceId: null,
              Name: originalFileName,
              Url: url,
              Duration: 0,
              Status: 0,
            };
          });
          // console.log("file objects are",fileObjects);
          if (isEditing === true && sampleUrls.length > 0) {
            // console.log("in is edit block");
            // Concatenate fileObjects array with sampleUrls state array
            const updatedFileObjects = [...sampleUrls, ...fileObjects];
            // console.log("Updated file objects are", updatedFileObjects);
            updateVoice(updatedFileObjects);
          } else {
            updateVoice(fileObjects);
          }
        } else {
          notify("Failed to upload files");
          console.error("Failed to upload files");
        }
      })
      .catch((error) => {
        setFlag(false);
        console.error("Error occurred during upload:", error);
      });
  }

  // handleSave function
  function handleSave() {
    if (isChecked && vName !== "") {
      uploadFiles(samples);
    }
  }

  function updateVoice(sampleObjs) {
    const req = {
      Id: isEditing ? vId : null,
      Name: vName,
      Type: 1,
      CreatedBy: localStorage.getItem("userSessionId"),
      ModifiedBy: localStorage.getItem("userSessionId"),
      Labels: JSON.stringify(labels.map((obj) => `${obj.key}: ${obj.value}`)),
      Description: description,
      VoiceSamples: sampleObjs,
    };

    if (sampleObjs.length > 0 && localStorage.getItem("userSessionId")) {
      fetch(`${apiURL}/web/update_voice`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(req),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.IsValid === true) {
            fetchUserInfo();
            setIsChecked(false);
            voiceClone(data.VoiceId);
          } else {
            notify("An error occurred. Please contact support.");
            setFlag(false);
            console.error("Error:", data.Error);
          }
        })
        .catch((error) => {
          notify("An error occurred. Please contact support.");
          setFlag(false);
          console.error("API call failed:", error);
        });
    }
  }

  // voice clone api call function
  function voiceClone(voiceId) {
    const request = {
      Id: voiceId,
    };

    fetch(`${apiURL}/dub/update_voice_clone`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          setDescription("");
          setSamples([]);
          setVName("");
          setLabels([]);
          setVId("");
          setSampleUrls([]);

          if (isEditing) {
            notifySuccess("Voice Clone successfully updated!");
          } else {
            notifySuccess("Voice Clone successfully created!");
          }
          setFlag(false);
          getVoices();
          close();
        } else {
          notify("An error occurred. Please contact support.");
          setFlag(false);
          console.error("Error:", data.Error);
        }
      })
      .catch((error) => {
        notify("An error occurred. Please contact support.");
        setFlag(false);
        console.error("API call failed:", error);
      });
  }

  // delete voice api call function

  function deleteVoice() {
    const req = {
      VoiceId: deleteId,
    };

    fetch(`${apiURL}/dub/delete_voice`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          notifySuccess("Voice Clone successfully deleted!");
          getVoices();
          fetchUserInfo();
          handleDeleteClose();
        } else {
          notify("An error occurred. Please contact support.");
          console.error("Error:", data.Error);
        }
      })
      .catch((error) => {
        notify("An error occurred. Please contact support.");
        console.error("API call failed:", error);
      });
  }

  const filteredVoices = voicesArray.filter((voice) => {
    const lowerCaseSearchText = searchText.toLowerCase();
    const lowerCaseName = voice.Name.toLowerCase();
    const lowerCaseDescription = voice.Description
      ? voice.Description.toLowerCase()
      : "";
    const lowerCaseLabels = voice.Labels
      ? JSON.parse(voice.Labels).join(" ").toLowerCase()
      : "";

    const isMatchingSearchText =
      lowerCaseName.includes(lowerCaseSearchText) ||
      lowerCaseDescription.includes(lowerCaseSearchText) ||
      lowerCaseLabels.includes(lowerCaseSearchText);

    // Additional filtering based on age, category, gender, and accent
    const labelsArray = voice.Labels ? JSON.parse(voice.Labels) : [];
    const isMatchingAge = age
      ? labelsArray.some((label) => {
          const [key, value] = label.split(":").map((part) => part.trim());
          return key === "age" && value.toLowerCase() === age.toLowerCase();
        })
      : true;

    const isMatchingCategory = category
      ? labelsArray.some((label) => {
          const [key, value] = label.split(":").map((part) => part.trim());
          return (
            key === "category" && value.toLowerCase() === category.toLowerCase()
          );
        })
      : true;

    const isMatchingGender = gender
      ? labelsArray.some((label) => {
          const [key, value] = label.split(":").map((part) => part.trim());
          return (
            key === "gender" && value.toLowerCase() === gender.toLowerCase()
          );
        })
      : true;

    const isMatchingAccent = accent
      ? labelsArray.some((label) => {
          const [key, value] = label.split(":").map((part) => part.trim());
          return (
            key === "accent" && value.toLowerCase() === accent.toLowerCase()
          );
        })
      : true;

    // Filter based on type value
    let isMatchingType = true; // Default to true for displaying all types
    if (type === "Prebuilt" && voice.Type !== 0) {
      isMatchingType = false;
    } else if (type === "Custom" && voice.Type !== 1) {
      isMatchingType = false;
    }

    return (
      isMatchingSearchText &&
      isMatchingAge &&
      isMatchingCategory &&
      isMatchingGender &&
      isMatchingAccent &&
      isMatchingType
    );
  });

  const maxContentHeight = filteredVoices.reduce((maxHeight, voice) => {
    // Calculate content height for each instance (considering labels, description, and footer)
    const labelsHeight = voice.Labels
      ? JSON.parse(voice.Labels).length * 28
      : 0; // Assuming 28px per label
    const descriptionHeight = voice.Description ? 60 : 0; // Assuming 60px for description
    const footerHeight = 40; // Assuming 50px for the footer
    const contentHeight = labelsHeight + descriptionHeight + footerHeight;

    // Update maxHeight to the maximum content height
    return Math.max(maxHeight, contentHeight);
  }, 0);

  // Set a fixed height for the card bodies
  const fixedCardHeight = maxContentHeight;

  function constructSentence(labels) {
    const order = ["age", "accent", "gender"];
    const sentence = order
      .map((key) => {
        const label = labels.find((tag) => tag.includes(`${key}:`));
        if (label) {
          const value = label.split(":")[1].trim();
          // Capitalize the first letter for 'age' and 'accent'
          return key === "age" || key === "accent"
            ? value.charAt(0).toUpperCase() + value.slice(1)
            : value;
        }
        return null;
      })
      .filter((value) => value !== null)
      .join(" ");

    return sentence ? (
      <span>{sentence}.</span>
    ) : (
      <span>No labels available</span>
    );
  }

  const manageVoiceClick = async (Id) => {
    setSelectedDigitalTwinComponent("manage-voice");
    navigate("/drm/digital-twin/manage-voice");

    setDrmVoiceId(Id);
  };

  // function call for calling add_existing_voice api

  function addExistingVoice() {
    const request = {
      VoiceId: existingVoiceId,
      CreatedBy: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/dub/add_existing_voice`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          notifySuccess("Voice added successfully!");
          setExistingVoiceId("");
          fetchUserInfo();
          getVoices();
          close();
        } else {
          notify("An error occurred. Please contact support.");
          setExistingVoiceId("");
          console.error("Error:", data.Error);
        }
      })
      .catch((error) => {
        notify("An error occurred. Please contact support.");
        setExistingVoiceId("");
        console.error("API call failed:", error);
      });
  }

  return (
    <div>
      {filteredVoices.length === 0 && searchText !== "" ? (
        <p
          style={{
            marginTop: "20px",
            fontSize: "16px",
            fontWeight: "500",
            color: "red",
            textAlign: "center",
          }}
        >
          No voices found with the given search text.
        </p>
      ) : null}
      {/* --------------------------------------------------- */}
      <div className="ms-5 mt-5 voices">
        {searchText === "" &&
        JSON.parse(localStorage.getItem("userInfo")).VoiceCreationAccess ===
          true ? (
          <Card
            style={{ width: "30%", minHeight: `${fixedCardHeight}px` }}
            className="mb-2 addVoiceCard"
          >
            <Card.Header
              style={{ borderBottom: 0, backgroundColor: "transparent" }}
            ></Card.Header>
            <Card.Body
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div style={{ height: "100px" }}>
                {" "}
                <Button
                  variant="text"
                  style={{ fontSize: "60px", color: "gray" }}
                  onClick={(e) => handleAdd()}
                >
                  <IoIosAddCircleOutline />
                </Button>
              </div>
              <div>
                <span style={{ fontSize: "14px" }}>
                  Add Generative or Cloned Voice{" "}
                </span>
                {userInfo !== null ? (
                  <p style={{ fontSize: "14px" }}>
                    {userInfo.UsageVoices} / {userInfo.LimitVoices}{" "}
                  </p>
                ) : null}
              </div>
            </Card.Body>
            <Card.Footer
              style={{ borderTop: 0, backgroundColor: "transparent" }}
            ></Card.Footer>
          </Card>
        ) : null}

        {voicesArray.map((voice) => (
          <Card
            key={voice.Id}
            style={{
              width: "30%",
              position: "relative",
              minHeight: `${fixedCardHeight}px`,
            }}
            className="mb-2 voicesCard"
          >
            <Card.Body>
              <h4>{voice.Name}</h4>
              {voice.Labels
                ? constructSentence(JSON.parse(voice.Labels))
                : null}
              <div></div>

              {/*description div here -------------------------------------------- */}

              <div style={{ height: "60px" }}>
                <p className="mt-2" style={{ fontSize: "14px", color: "grey" }}>
                  {voice.Description
                    ? voice.Description
                    : " No description provided."}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "5px",
                  height: 28,
                }}
              >
                {voice.Labels ? (
                  JSON.parse(voice.Labels).map((tag, index) => {
                    // Extracting the key before colon
                    const key = tag.includes(":")
                      ? tag.split(":")[0].trim()
                      : "";

                    // List of keys to filter out
                    const keysToFilter = ["age", "accent", "gender"];

                    // Check if the key is in the list of keys to filter
                    if (!keysToFilter.includes(key)) {
                      return (
                        <span
                          key={index}
                          style={{
                            backgroundColor:
                              tagColors[index % tagColors.length],
                            paddingRight: "8px",
                            paddingLeft: "8px",
                            margin: "2px",
                            borderRadius: "10px",
                            display: "inline-block",
                            fontSize: "12px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            width: "max-content", // Adjust the width of each label as needed
                            height: "24px",
                          }}
                        >
                          {/* Displaying only the value after the colon */}
                          {tag.includes(":") ? tag.split(":")[1].trim() : tag}
                        </span>
                      );
                    } else {
                      // Return null for labels to be filtered out
                      return null;
                    }
                  })
                ) : (
                  <span style={{ fontSize: "14px", color: "grey" }}>
                    No labels available
                  </span>
                )}
              </div>
            </Card.Body>
            {voice.Type === 1 ? (
              <Card.Footer
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 0,
                }}
              >
                <Button
                  variant="text"
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                  onClick={(e) => {
                    manageVoiceClick(voice.Id);
                  }}
                >
                  <span>Manage</span>
                </Button>
                <Button
                  variant="text"
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                  onClick={(e) => handleEdit(voice.Id)}
                >
                  <RiEditBoxLine />
                  <span>Edit</span>{" "}
                </Button>
                <Button
                  variant="text"
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                  onClick={(e) => {
                    handleDeleteShow();
                    setDeleteId(voice.Id);
                  }}
                >
                  <RiDeleteBin6Line />
                  <span> Remove</span>
                </Button>
              </Card.Footer>
            ) : (
              <Card.Footer
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 0,
                }}
              >
                <Button
                  variant="text"
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                  onClick={() => playOrPauseAudio(voice.SampleUrl)}
                >
                  {isPlaying && currentAudio.src === voice.SampleUrl ? (
                    <FaVolumeMute />
                  ) : (
                    <AiFillSound />
                  )}
                  <span>Sample</span>
                </Button>
              </Card.Footer>
            )}
          </Card>
        ))}

        <Modal show={show} onHide={close} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit Voice" : "Add Voice"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isEditing === false ? (
              <Tabs
                defaultActiveKey="new-voice"
                id="add-voice"
                className="mb-3"
                onSelect={(k) => {
                  if (k === "existing-voice") {
                    setTabKey(k);
                  } else {
                    setTabKey("");
                  }
                }}
              >
                <Tab eventKey="new-voice" title="New Voice">
                  <AddVoice
                    setVoice={setVoice}
                    voice={voice}
                    labels={labels}
                    setLabels={setLabels}
                    samples={samples}
                    flag={flag}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    isEditing={isEditing}
                    setSamples={setSamples}
                    vName={vName}
                    setVName={setVName}
                    description={description}
                    setDescription={setDescription}
                    uploadPercentage={uploadPercentage}
                    setSampleUrls={setSampleUrls}
                    sampleUrls={sampleUrls}
                    sampleCount={sampleCount}
                    setSampleCount={setSampleCount}
                  />
                </Tab>
                <Tab eventKey="existing-voice" title="Existing Voice">
                  <Form.Label style={{ fontWeight: 500 }}>
                    Existing Voice Id
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onBlur={(e) => setExistingVoiceId(e.target.value)}
                  />
                  <Form.Check
                    className="mt-5 mb-3"
                    type="checkbox"
                    onChange={(e) => setIsPolicyChecked(!isPolicyChecked)}
                    checked={isPolicyChecked}
                    label={
                      <>
                        <span style={{ color: "grey" }}>
                          I hereby confirm that I have all necessary rights or
                          consents to upload and clone these voice samples and
                          that I will not use the platform-generated Content for
                          any illegal, fraudulent, or harmful purpose. I
                          reaffirm my obligation to abide by Spectral Studio
                        </span>
                        <span style={{ color: "grey" }}>'s </span>
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() =>
                            window.open(
                              "https://spectralstudios.ai/terms/",
                              "_blank"
                            )
                          }
                        >
                          Terms of Service
                        </span>
                        <span style={{ color: "gray" }}> and </span>
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() =>
                            window.open(
                              "https://spectralstudios.ai/privacy/",
                              "_blank"
                            )
                          }
                        >
                          Privacy Policy
                        </span>
                      </>
                    }
                  />
                </Tab>
              </Tabs>
            ) : (
              <AddVoice
                setVoice={setVoice}
                voice={voice}
                labels={labels}
                setLabels={setLabels}
                samples={samples}
                flag={flag}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                isEditing={isEditing}
                setSamples={setSamples}
                vName={vName}
                setVName={setVName}
                description={description}
                setDescription={setDescription}
                uploadPercentage={uploadPercentage}
                setSampleUrls={setSampleUrls}
                sampleUrls={sampleUrls}
                sampleCount={sampleCount}
                setSampleCount={setSampleCount}
              />
            )}
          </Modal.Body>
          {tabKey !== "existing-voice" ? (
            <Modal.Footer>
              {flag === false ? (
                <Button variant="light" onClick={close}>
                  Close
                </Button>
              ) : (
                <Button variant="light" onClick={close} disabled>
                  Close
                </Button>
              )}
              {flag === false &&
              isChecked &&
              vName !== "" &&
              sampleCount > 0 ? (
                <Button variant="secondary" onClick={handleSave}>
                  {isEditing ? "Edit Voice" : "Add Voice"}
                </Button>
              ) : (
                <Button variant="secondary" onClick={handleSave} disabled>
                  {isEditing ? "Edit Voice" : "Add Voice"}
                </Button>
              )}
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              <Button variant="light" onClick={close}>
                Close
              </Button>
              {isPolicyChecked && existingVoiceId !== "" ? (
                <Button variant="secondary" onClick={(e) => addExistingVoice()}>
                  Add Voice
                </Button>
              ) : (
                <Button variant="secondary" onClick={handleSave} disabled>
                  Add Voice
                </Button>
              )}
            </Modal.Footer>
          )}
        </Modal>
        <Modal show={showDelete} onHide={handleDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Voice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the voice clone?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="danger" onClick={(e) => deleteVoice()}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
}
